import React, { memo, useState } from 'react';
import { useAppSelector } from '../../commons/hooks/redux';
import { selectCreateFlow } from '../../pages/CreateFlow/CreateFlowRedux/selector';

interface StepperProps {
    steps: React.ReactNode[];
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    clickedStep: null | number;
}

const Stepper: React.FC<StepperProps> = ({ steps, currentStep, setCurrentStep, clickedStep }) => {
    const { onBoardedUser } = useAppSelector(selectCreateFlow);
    const handleStepper = () => {
        // if (clickedStep !== null) {
        //     if (clickedStep === 0) {
        //         setCurrentStep(0);
        //         return steps[0];
        //     } else if (clickedStep === 1) {
        //         // when all the pages like potions are active
        //         // setCurrentStep(onBoardedUser ? 4 : 2);
        //         // return steps[onBoardedUser ? 4 : 2];
        //         setCurrentStep(2);
        //         return steps[2];
        //     } else {
        //         // setCurrentStep(5);
        //         // return steps[5];
        //         setCurrentStep(3);
        //         return steps[3];
        //     }
        // } else {
        //     return steps[currentStep];
        // }
        if (clickedStep !== null) {
            const targetStep = Math.min(clickedStep, steps.length - 1); // Ensure it's within bounds
            setCurrentStep(targetStep);
            return steps[targetStep];
        } else {
            return steps[currentStep];
        }
    };

    return <div className="p-0 m-0">{handleStepper()}</div>;
};

export default memo(Stepper);
