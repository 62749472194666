import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import {
    useGetBrandVoiceQuery,
    useSaveBrandVoiceMutation,
    useSaveScrapingURLMutation,
} from '../../BrandMagicRedux/brandMagic.api';
import { Button, Spinner } from 'react-bootstrap';
import { brandVoiceLabels, brandVoicePlaceHolders } from '../../../../constants/brandMagic';
import AutoResizeTextarea from './AutoResizeableTextArea';
import BrandMagicScrapingURL from './BrandMagicScrapingURL';
import { motion, AnimatePresence } from 'framer-motion';
export interface BrandVoiceState {
    brandName: string;
    keyProductService: string;
    keyMessages: string;
    tone: string;
    scrapedTextContent: string;
    anythingElse: string;
}
type BrandVoiceProps = {
    handleNext: () => void;
    setURLSaved: React.Dispatch<React.SetStateAction<boolean>>;
    handlePrev: () => void;
    error: string;
    successMessage: string;
    brandVoice: BrandVoiceState;
    setBrandVoice: React.Dispatch<React.SetStateAction<BrandVoiceState>>;
};
const BrandVoice: React.FC<BrandVoiceProps> = ({
    handleNext,
    setURLSaved,
    handlePrev,
    error,
    successMessage,
    brandVoice,
    setBrandVoice,
}) => {
    const { data, isLoading: isFetchingData, refetch } = useGetBrandVoiceQuery();
    const [saveBrandVoice, { isLoading }] = useSaveBrandVoiceMutation();
    const [saveScrapingURL, { isLoading: isSavingURL }] = useSaveScrapingURLMutation();
    const [isEditing, setIsEditing] = useState(false);
    const [prevBrandVoice, setPrevBrandVoice] = useState({}); // Maintain previous brandVoice state
    // const [brandVoice, setBrandVoice] = useState({
    //     brandName: '',
    //     keyProductService: '',
    //     keyMessages: '',
    //     tone: '',
    //     // weDo: '',
    //     // weDoNot: '',
    // });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState('');
    useEffect(() => {
        if (data) {
            setBrandVoice({
                brandName: data.brandName || '',
                keyProductService: data.keyProductService || '',
                keyMessages: data.keyMessages || '',
                tone: data.tone || '',
                scrapedTextContent: data.scrapedTextContent || '',
                anythingElse: data.anythingElse || '',
            });
        }
    }, [data, setBrandVoice]);
    useEffect(() => {
        // Initialize the state when data is available
        if (data && !isEditing) {
            const initialBrandVoice = {
                brandName: data.brandName || '',
                keyProductService: data.keyProductService || '',
                keyMessages: data.keyMessages || '',
                tone: data.tone || '',
                scrapedTextContent: data.scrapedTextContent || '',
                anythingElse: data.anythingElse || '',
                // weDo: data.weDo || '',
                // weDoNot: data.weDoNot || '',
            };
            setBrandVoice(initialBrandVoice);
            setPrevBrandVoice(initialBrandVoice); // Initialize previous state
        }
    }, [data]);

    const handleClick = (event: any) => {
        event?.preventDefault();
        setIsEditing(true);
    };

    const handleInputChange = (event: any) => {
        setIsEditing(true);
        const { id, value } = event.target;
        setBrandVoice((prevBrandVoice) => ({
            ...prevBrandVoice,
            [id]: value,
        }));
    };

    const handleBlur = async () => {
        if (JSON.stringify(brandVoice) !== JSON.stringify(prevBrandVoice)) {
            await saveBrandVoice(brandVoice)
                .unwrap()
                .then((response: any) => {
                    // console.log(response, 'BRAND VOICE SAVED SUCCESSFULLY');
                    // Update the previous state
                    setPrevBrandVoice(brandVoice);
                })
                .catch((error) => {
                    console.log(error, 'BRAND VOICE SAVE FAILED');
                });
        }
        setIsEditing(false);
    };
    // const debounceDelay = 2000; //
    // let debounceTimer: NodeJS.Timeout | null = null; // Declare
    // const handleBlur = async () => {
    //   // Compare current brandVoice with the previous state
    //   if (JSON.stringify(brandVoice) !== JSON.stringify(prevBrandVoice)) {
    //     if (debounceTimer !== null) {
    //       clearTimeout(debounceTimer); // Clear any existing timer
    //     }
    //     debounceTimer = setTimeout(async () => {
    //       await saveBrandVoice(brandVoice)
    //         .unwrap()
    //         .then((response: any) => {
    //           console.log(response, "BRAND VOICE SAVED SUCCESSFULLY");
    //           // Update the previous state
    //           setPrevBrandVoice(brandVoice);
    //         })
    //         .catch((error) => {
    //           console.log(error, "BRAND VOICE SAVE FAILED");
    //         });
    //     }, debounceDelay);
    //   }
    // };

    // const handleNextClick = async () => {
    //     setFormSubmitted(true);
    //     setIsEditing(false);
    //     handleNext();
    //     await saveBrandVoice(brandVoice)
    //         .unwrap()
    //         .then((response: any) => {
    //             console.log(response, 'BRAND VOICE SAVED SUCCEFULLY');
    //         })
    //         .catch((error) => {
    //             console.log(error, 'BRAND VOICE SAVE FAILED');
    //         });
    // };
    // const handleBack = () => {
    //     setURLSaved(false);
    // };
    const handleNextClick = async () => {
        setFormSubmitted(true);
        if (checkFormValidity()) {
            setIsEditing(false);

            handleNext();
            await saveBrandVoice(brandVoice)
                .unwrap()
                .then((response: any) => {
                    console.log(response, 'BRAND VOICE SAVED SUCCEFULLY');
                })
                .catch((error) => {
                    console.log(error, 'BRAND VOICE SAVE FAILED');
                });
        } else {
            setFormError(
                'This information is necessary for Pixii to understand your brand and curate on-brand ads for you.',
            );
        }
    };
    const checkFormValidity = () => {
        const { scrapedTextContent, anythingElse, ...rest } = brandVoice;
        return Object.values(rest).every((value) => value.trim() !== '');
    };
    return (
        <>
            <div className="brandMagic_content">
                {/* // <Alert variant="primary" dismissible>
                    //     <p style={{ fontSize: '0.85rem' }}>
                    //         I've successfully analyzed your website and learned about your brand's
                    //         voice! Alternatively, you can manually type in the information. These
                    //         details will help generate ads for our agent. Feel free to update the
                    //         data whenever you like from <strong>Brand Profile</strong> !
                    //     </p>
                    //     <p className="mt-2 fst-italic" style={{ fontSize: '0.85rem' }}>
                    //         <strong>Note:</strong> Every field is required.
                    //     </p>
                    // </Alert> */}
                <AnimatePresence>
                    {error && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.2 }}
                        >
                            <Alert variant="danger" dismissible>
                                <p style={{ fontSize: '0.85rem' }}>{error}</p>
                                <p className="mt-2 fst-italic" style={{ fontSize: '0.85rem' }}>
                                    <strong>Note:</strong> Every field is required.
                                </p>
                            </Alert>
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {successMessage && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.2 }}
                        >
                            <Alert variant="primary" dismissible>
                                <p style={{ fontSize: '0.85rem' }}>{successMessage}</p>

                                <p style={{ fontSize: '0.85rem' }}>{error}</p>
                                <p className="mt-2 fst-italic" style={{ fontSize: '0.85rem' }}>
                                    <strong>Note:</strong> Every field is required.
                                </p>
                            </Alert>
                        </motion.div>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {formError && (
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.2 }}
                        >
                            <Alert variant="danger" dismissible>
                                <Alert.Heading>Please fill in all fields.</Alert.Heading>
                                <p>{formError}</p>
                            </Alert>
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className="mb-3">
                    <h1 className="fw-bold text-primary mb-3">Brand Voice</h1>
                </div>

                {/* Loading state when the webscraping is going on */}
                {isSavingURL ? (
                    <div className="w-100 d-flex justify-content-center flex-column align-items-center mt-3">
                        <Spinner animation="border" style={{ color: '#967AA7' }} />
                        <span className="fst-italic text-primary h4 mt-1">
                            Reading your website...
                        </span>
                    </div>
                ) : (
                    <>
                        <div className="content_potions">
                            <div className="potion-alt">
                                {isFetchingData ? (
                                    <div className="d-flex justify-content-center mt-3">
                                        <Spinner animation="border" />
                                    </div>
                                ) : (
                                    Object.entries(brandVoice).map(([field, value]) => {
                                        return (
                                            <>
                                                {field === 'scrapedTextContent' ||
                                                field === 'anythingElse' ? (
                                                    <></>
                                                ) : (
                                                    <Form.Group
                                                        key={field}
                                                        className="form mb-4 mt-2"
                                                        onClick={handleClick}
                                                    >
                                                        <Form.Label className="h4 text-primary fw-bold m-0">
                                                            {brandVoiceLabels[field]}
                                                        </Form.Label>
                                                        <div className="whitespace"></div>
                                                        <AutoResizeTextarea
                                                            value={value}
                                                            className={`${field}`}
                                                            id={field}
                                                            onChange={handleInputChange}
                                                            onBlur={handleBlur}
                                                            rows={1}
                                                            readOnly={!isEditing}
                                                            placeholder={
                                                                brandVoicePlaceHolders[field]
                                                            }
                                                            required={true}
                                                            formSubmitted={formSubmitted}
                                                        />
                                                    </Form.Group>
                                                )}
                                            </>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 brandMagic_buttons">
                <Button onClick={handlePrev} variant="secondary" size="lg">
                    Back
                </Button>
                <Button variant="primary" size="lg" onClick={handleNextClick}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default BrandVoice;
