/* eslint-disable react-hooks/exhaustive-deps */
/*  react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Auth } from 'aws-amplify';
import config from '../../config';

import { Button } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { displayErrorToast } from '../../utils/ToastUtils';

import {
    saveProjectTemplate,
    updateApprenticeViewState,
    updateIsFreshStart,
} from './EditorRedux/editorSlice';
import { useAppDispatch } from '../../commons/hooks/redux';
import { useCreateAndPersistProject } from '../../utils/projectUtils';
import { calculateJSONHash } from '../../utils/TemplateUtils';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const PolotnoQuickSaveButton = observer(({ store }) => {
    const apprenticeTemplate = useSelector((state) => state.templateFlow.apprenticeTemplate);
    const [saving, setSaving] = React.useState(false);
    const [isSavedNewProject, setIsSavedNewProject] = React.useState(0);
    const [timeInterval, setTimeInterval] = React.useState(1000);
    const dispatch = useAppDispatch();
    const { createAndPersistProject, isLoading } = useCreateAndPersistProject();

    const id = useSelector((state) => state.editFlow.project.id);
    const isBlankCanvasNewproject = useSelector((state) => state.editFlow.isBlankCanvasNewproject);
    const projectCreationGoingOn = useSelector((state) => state.editFlow.projectCreationGoingOn);

    const saveJsonToCloud = async (data) => {
        const awsRes = await Auth.currentSession();

        const configAPI = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${awsRes.getAccessToken().getJwtToken()}`,
            },
            body: JSON.stringify({ ...data, jsonUpdate: true }),
        };
        await fetch(`${config.apiBaseUrl}project`, configAPI);
    };
    let projectTemplate;

    const handleSaveAsProjectClick = async () => {
        const projectJSON = store.toJSON();
        let projectName = '';
        projectName = window.prompt('Enter Project name:');
        if (projectName === null) return;

        if (projectName == '') {
            displayErrorToast('Please Enter a new project name ');
            return;
        }
        projectTemplate = {
            id: id,
            json: projectJSON,
            name: projectName.replace(/\s+/g, '_'),
            jsonUpdate: apprenticeTemplate === null ? true : false,
            updateImage: true,
        };
        if (id === null) {
            delete projectTemplate.id;
            projectTemplate.jsonUpdate = false;
            projectTemplate.updateImage = false;
            projectTemplate.isImportedOrCreated = true;
        }

        await createAndPersistProject(projectTemplate, store, false);

        // id === null && dispatch(saveProjectTemplate(''));
        // dispatch(updateApprenticeViewState(false));

        // updateIsFreshStart
        dispatch(updateIsFreshStart(false));
    };

    const localStorageKey = 'projectData';
    const getDataFromLocalStorage = () => {
        const projectData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
        return {
            isPresent: Object.keys(projectData).length > 0,
            localStoredData: projectData,
        };
    };

    const saveDataToLocalStorage = (hash, id, imageUrl) => {
        return localStorage.setItem(
            localStorageKey,
            JSON.stringify({
                hash,
                id,
                imageUrl,
            }),
        );
    };
    const updateTimeInterval = (newTimeInterval) => {
        setTimeInterval(newTimeInterval);
    };

    useEffect(() => {
        const HandleAutoSaving = async () => {
            // console.log('isBlankCanvasNewproject', isBlankCanvasNewproject);
            // if (apprenticeTemplate != null) return;
            if (isBlankCanvasNewproject) return;
            const projectJSON = store.toJSON();

            const toSave_An_OldProject = id !== null && isSavedNewProject > 1;

            if (toSave_An_OldProject) {
                if (timeInterval !== 5000) updateTimeInterval(5000);
                const newHash = await calculateJSONHash(projectJSON);
                const { isPresent, localStoredData } = getDataFromLocalStorage();

                const nothingInLocalStorage = !isPresent;
                const isNewProjectOnEditor = nothingInLocalStorage || id !== localStoredData.id;
                const isHashChanged =
                    isPresent && !isNewProjectOnEditor && newHash !== localStoredData?.hash;

                if (projectCreationGoingOn === 1) return;
                if (!isHashChanged && !isNewProjectOnEditor) return;

                saveJsonToCloud({
                    id: id,
                    json: projectJSON,
                    updateImage: nothingInLocalStorage || isNewProjectOnEditor || !isHashChanged,
                    // ignoring the image update if just the hash is changed !!
                });
                saveDataToLocalStorage(newHash, id);
                updateTimeInterval(5000);
            }
        };

        const timeoutId = setTimeout(() => {
            setIsSavedNewProject(isSavedNewProject + 1);
            HandleAutoSaving();
        }, timeInterval);
        return () => clearTimeout(timeoutId);
    }, [id, isSavedNewProject, isBlankCanvasNewproject]);

    const isMobile = window.innerWidth <= 768;
    return (
        <>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Save</Tooltip>}
            >
                <Button
                    icon="floppy-disk"
                    intent="primary"
                    loading={isLoading || saving}
                    onClick={handleSaveAsProjectClick}
                    className="d-flex align-item-center justify-content-center me-2"
                >
                    {/* {isMobile ? '' : 'Save As'} */}
                </Button>
            </OverlayTrigger>
        </>
    );
});
