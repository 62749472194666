// eslint-disable-next-line @typescript-eslint/naming-convention
export enum HTTP_METHODS {
    POST = 'POST',
    PUT = 'PUT',
    GET = 'GET',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export const CONTENT_TYPE_APPLICATION_JSON = 'application/json';
