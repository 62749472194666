export const renderFormattedText = (text: string): React.ReactNode[] => {
    const lines = text.split(/\n/);
    const formattedText: React.ReactNode[] = [];
    let currentParagraph: React.ReactNode[] = [];
    lines.forEach((line, index) => {
        if (line.trim() === '') {
            if (currentParagraph.length > 0) {
                formattedText.push(<div key={index}>{currentParagraph}</div>);
                currentParagraph = [];
            }
        } else {
            const boldWithColonMatches = line.match(/(\d+)\.\s*\*\*(.*?)\*\*:\s*(.*)/);
            const boldWithNameMatches = line.match(/\*\*(.*?)\*\*:/); // Added this line for **textname**:
            if (boldWithColonMatches) {
                currentParagraph.push(
                    <p className="mt-2" key={index}>
                        {boldWithColonMatches[1]}. <strong>{boldWithColonMatches[2]}</strong>:{' '}
                        {boldWithColonMatches[3]}
                    </p>,
                );
            } else if (boldWithNameMatches) {
                // Added this block for **textname**:
                const textContent = line.substring(boldWithNameMatches[0].length); // Extract text content after the bold name
                currentParagraph.push(
                    <p className="mt-2" key={index}>
                        <strong>{boldWithNameMatches[1]}</strong>: {textContent}
                    </p>,
                );
            } else if (line.startsWith('**SpecialText**:')) {
                const specialText = line.substring(15); // Extracting text after "**SpecialText**:"
                currentParagraph.push(
                    <p className="mt-2 special-text" key={index}>
                        {specialText}
                    </p>,
                );
            } else {
                const boldTextRegex = /\*\*(.*?)\*\*/g;
                let lastIndex = 0;
                let match;
                while ((match = boldTextRegex.exec(line)) !== null) {
                    const startIndex = match.index;
                    const endIndex = match.index + match[0].length;
                    currentParagraph.push(
                        <p className="mt-2" key={startIndex}>
                            {line.substring(lastIndex, startIndex)}
                            <strong>{match[1]}</strong>
                        </p>,
                    );
                    lastIndex = endIndex;
                }
                currentParagraph.push(
                    <p className="mt-2" key={lastIndex}>
                        {line.substring(lastIndex)}
                    </p>,
                );
            }
        }
    });
    // Add the last paragraph if it's not empty
    if (currentParagraph.length > 0) {
        formattedText.push(<div key={lines.length}>{currentParagraph}</div>);
    }
    return formattedText;
};

export const highlightKeywords = (text: string) => {
    const mentionPattern = /@\[([^\]]+)\]\(([^\)]+)\)/g;
    const processedText = text.replace(mentionPattern, (_, keyword, displayText) => displayText);
    const keywords = ['create', 'resize', 'variants', 'brainstorm', 'upload', 'greenscreen'];
    const pattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');
    return processedText.split(pattern).map((part: string, index: number) => {
        if (part.match(mentionPattern)) {
            return part;
        } else if (keywords.includes(part.toLowerCase())) {
            return <strong key={index}>{part}</strong>;
        } else {
            return part;
        }
    });
};

import React from 'react';

interface EditingModeMessageProps {
    show: boolean;
}

export const EditingModeMessage: React.FC<EditingModeMessageProps> = ({ show }) => {
    if (!show) return null;

    return (
        <div className="d-flex align-items-center justify-content-center mt-3">
            <p className="text-primary h4">You're working in editor</p>
        </div>
    );
};
