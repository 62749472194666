import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Position, Menu, HTMLSelect, Slider } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import * as unit from 'polotno/utils/unit';
import { t } from 'polotno/utils/l10n';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { useDownloadCountMutation } from './EditorRedux/editor.api';
import { displayErrorToast } from '../../utils/ToastUtils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export const PolotnoDownloadButton = observer(({ store }) => {
    const [saving, setSaving] = React.useState(false);
    const [quality, setQuality] = React.useState(1);
    const [fps, setFPS] = React.useState(10);
    const [type, setType] = React.useState('png');
    const [downloadCount] = useDownloadCountMutation();
    const projectData = useSelector((state) => state.editFlow.project);
    const isMobile = window.innerWidth <= 768;
    const downloadCountFunction = async (pages, contentType) => {
        try {
            const payload = {
                projectId: projectData.id,
                pageNumber: pages,
                downloadContentType: contentType,
            };
            await downloadCount(payload)?.unwrap();
        } catch (error) {
            console.log(error, 'error while downloading');
        }
    };

    // const handleDownloadImages = async (type) => {
    //   const zip = new JSZip();
    //   try {
    //     for (const [index, page] of store.pages.entries()) {
    //       const fileExtension = type === 'png' ? 'png' : 'jpeg';
    //       let url;
    //       if (type === 'png') {
    //         url = await store.toDataURL({ pageId: page.id });
    //       } else if (type === 'jpeg') {
    //         url = await store.toDataURL({ pageId: page.id, mimeType: 'image/jpeg' });
    //       }
    //       zip.file(`page-${index + 1}.${fileExtension}`, url.split(',')[1], { base64: true });
    //     }

    //     const zipBlob = await zip.generateAsync({ type: "blob" });
    //     saveAs(zipBlob, "pixii.zip");

    //     // setTimeout(() => {
    //     //   downloadCountFunction(store.pages.length, type);
    //     // }, 1000);
    //   } catch (error) {
    //     console.error("An error occurred while downloading:", error);
    //   }
    // };
    const handleDownloadImages = async (quality, type) => {
        const zip = new JSZip();

        try {
            const promises = store.pages.map(async (page, index) => {
                const url = await store.toDataURL({
                    pageId: page.id,
                    pixelRatio: quality,
                    mimeType: `image/${type}`,
                });
                const projectPageName = projectData.name || 'page';
                zip.file(`${projectPageName}-${index + 1}.${type}`, url.split(',')[1], {
                    base64: true,
                });
            });

            await Promise.all(promises);

            const zipBlob = await zip.generateAsync({ type: 'blob' });

            return new Promise((resolve) => {
                const projectName = projectData.name || 'pixii';
                saveAs(zipBlob, `${projectName}.zip`);
                resolve(true);
            });
        } catch (error) {
            window.alert('An error occurred while downloading');
            console.error('An error occurred while downloading:', error);
        }
    };

    const getName = () => {
        const texts = [];
        store.pages.forEach((p) => {
            p.children.forEach((c) => {
                if (c.type === 'text') {
                    texts.push(c.text);
                }
            });
        });
        const allWords = texts.join(' ').split(' ');
        const words = allWords.slice(0, 6);
        return words.join(' ').replace(/\s/g, '-').toLowerCase() || 'pixii';
    };
    return (
        <Popover2
            content={
                <Menu>
                    <li className="bp4-menu-header">
                        <h6 className="bp4-heading">File type</h6>
                    </li>
                    <HTMLSelect
                        fill
                        onChange={(e) => {
                            setType(e.target.value);
                            setQuality(1);
                        }}
                        value={type}
                    >
                        <option value="jpeg">JPEG</option>
                        <option value="png">PNG</option>
                        <option value="pdf">PDF</option>
                        <option value="html">HTML</option>
                        <option value="gif">GIF</option>
                        <option value="svg">SVG</option>
                    </HTMLSelect>

                    {type !== 'html' && type !== 'svg' && (
                        <>
                            <li className="bp4-menu-header">
                                <h6 className="bp4-heading">Size</h6>
                            </li>
                            <div style={{ padding: '10px' }}>
                                <Slider
                                    value={quality}
                                    labelRenderer={false}
                                    // labelStepSize={0.4}
                                    onChange={(quality) => {
                                        setQuality(quality);
                                    }}
                                    stepSize={0.2}
                                    min={0.2}
                                    max={3}
                                    showTrackFill={false}
                                />
                                {type === 'pdf' && (
                                    <div>
                                        {unit.pxToUnitRounded({
                                            px: store.width,
                                            dpi: store.dpi / quality,
                                            precious: 0,
                                            unit: 'mm',
                                        })}{' '}
                                        x{' '}
                                        {unit.pxToUnitRounded({
                                            px: store.height,
                                            dpi: store.dpi / quality,
                                            precious: 0,
                                            unit: 'mm',
                                        })}{' '}
                                        mm
                                    </div>
                                )}
                                {type !== 'pdf' && (
                                    <div>
                                        {Math.round(store.width * quality)} x{' '}
                                        {Math.round(store.height * quality)} px
                                    </div>
                                )}
                                {type === 'gif' && (
                                    <>
                                        <li className="bp4-menu-header">
                                            <h6 className="bp4-heading">FPS</h6>
                                        </li>
                                        <div style={{ padding: '10px' }}>
                                            <Slider
                                                value={fps}
                                                // labelRenderer={false}
                                                labelStepSize={5}
                                                onChange={(fps) => {
                                                    setFPS(fps);
                                                }}
                                                stepSize={1}
                                                min={5}
                                                max={30}
                                                showTrackFill={false}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {type === 'html' && (
                        <>
                            {/* <div style={{ padding: '10px', maxWidth: '180px', opacity: 0.8 }}>
                HTML export is very experimental. If you have issues with it,
                please report to Anton on discord.
              </div> */}
                        </>
                    )}
                    <Button
                        style={{ marginTop: '10px', marginBottom: '5px' }}
                        fill
                        intent="primary"
                        loading={saving}
                        className="d-flex align-item-center justify-content-center ms-2"
                        onClick={async () => {
                            if (type === 'pdf') {
                                setSaving(true);
                                await store
                                    .saveAsPDF({
                                        fileName: getName() + '.pdf',
                                        dpi: store.dpi / quality,
                                        pixelRatio: 2 * quality,
                                    })
                                    .then(() => downloadCountFunction(store.pages.length, type));
                                setSaving(false);
                            } else if (type === 'html') {
                                setSaving(true);
                                await store
                                    .saveAsHTML({
                                        fileName: getName() + '.html',
                                    })
                                    .then(() => downloadCountFunction(store.pages.length, type));
                                setSaving(false);
                            } else if (type === 'svg') {
                                setSaving(true);
                                await store
                                    .saveAsSVG({
                                        fileName: getName() + '.svg',
                                    })
                                    .then(() => downloadCountFunction(store.pages.length, type));
                                setSaving(false);
                            } else if (type === 'gif') {
                                setSaving(true);
                                await store
                                    .saveAsGIF({
                                        fileName: getName() + '.gif',
                                        pixelRatio: quality,
                                        fps,
                                    })
                                    .then(() => downloadCountFunction(store.pages.length, type));
                                setSaving(false);
                            } else {
                                setSaving(true);
                                const noOfPages = await store.pages.length;
                                if (noOfPages > 1) {
                                    await handleDownloadImages(quality, type).then((res) => {
                                        if (res) {
                                            downloadCountFunction(store.pages.length, type);
                                            setSaving(false);
                                        } else {
                                            setSaving(false);
                                        }
                                    });
                                } else {
                                    store.saveAsImage({
                                        fileName: projectData.name || 'pixii',
                                        pixelRatio: quality,
                                        mimeType: `image/${type}`,
                                    });
                                    setSaving(false);
                                }
                            }
                        }}
                    >
                        Download {type.toUpperCase()}
                    </Button>

                    {/* <MenuItem
            icon="media"
            text={t('toolbar.saveAsImage')}
            onClick={async () => {
              store.pages.forEach((page, index) => {
                // do not add index if we have just one page
                const indexString =
                  store.pages.length > 1 ? '-' + (index + 1) : '';
                store.saveAsImage({
                  pageId: page.id,
                  fileName: getName() + indexString + '.png',
                });
              });
            }}
          />
          <MenuItem
            icon="document"
            text={t('toolbar.saveAsPDF')}
            onClick={async () => {
              setSaving(true);
              await store.saveAsPDF({
                fileName: getName() + '.pdf',
              });
              setSaving(false);
            }}
          /> */}
                </Menu>
            }
            position={Position.BOTTOM_RIGHT}
        >
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>Download</Tooltip>}
            >
                <Button
                    icon="import"
                    // text={t('toolbar.download')}
                    intent="primary"
                    disabled={saving}
                    onClick={() => {
                        setQuality(1);
                    }}
                >
                    {/* {isMobile ? '' : 'Download'} */}
                </Button>
            </OverlayTrigger>
        </Popover2>
    );
});
