import { baseApiSlice } from '../../../commons/base-api.config';
import { HTTP_METHODS } from '../../../constants/http';
import {
    API_BRAND_TEMPALTES,
    API_DOWNLOAD_COUNT,
    API_GET_CANVAS,
    API_PROJECT,
    API_PROJECT_CURRENT_PROJECT,
    API_PROJECT_DUPLICATE,
    API_SAVE_CANVAS,
    API_UPLOAD_ASSET,
} from '../../../constants/api';
import { IGetUploadedAssets } from '../../BrandMagic/BrandMagicRedux/BrandMagicTypes';

export const editorApiSlice = baseApiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            // get generated results
            getProjects: builder.query<any, void>({
                query(currentPage) {
                    return {
                        url: `${API_PROJECT}?page=${
                            currentPage !== undefined ? currentPage : 1
                        }&size=15`,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['allProjects'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getProjectById: builder.query<any, string | null>({
                query(id) {
                    //Check if id is null or not
                    if (id === null || id === 'null') {
                        return '';
                    }
                    return {
                        url: `${API_PROJECT}/${id}`,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['activeProject'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getCurrentProject: builder.query<any, void>({
                query() {
                    return {
                        url: API_PROJECT_CURRENT_PROJECT,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['activeProject'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            // get generated results
            getCanvasData: builder.query<any, void>({
                query() {
                    return {
                        url: API_GET_CANVAS,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            saveCanvas: builder.mutation({
                query: (data) => {
                    return {
                        url: API_SAVE_CANVAS,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            // upload asset
            getUploadedAssets: builder.query<any, any>({
                query(page) {
                    return {
                        url: `${API_UPLOAD_ASSET}?page=${page !== undefined ? page : 1}&size=15`,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['uploadedAssets'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            uploadAsset: builder.mutation({
                query: (data) => {
                    // console.log(data, "data");
                    const formData = new FormData();
                    formData.append('asset', data?.image as File);
                    return {
                        url: API_UPLOAD_ASSET,
                        method: HTTP_METHODS.POST,
                        body: formData,
                    };
                },
                invalidatesTags: ['uploadedAssets'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteAsset: builder.mutation({
                query: (id) => {
                    return {
                        url: `${API_UPLOAD_ASSET}/${id}`,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                invalidatesTags: [],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            // project slices
            createProject: builder.mutation({
                query: (data) => {
                    return {
                        url: API_PROJECT,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                // invalidatesTags: ['allProjects', 'activeProject'],
                invalidatesTags: ['allProjects', 'activeProject'],
                transformResponse: (data) => data,
                transformErrorResponse: (response) => response,
            }),
            deleteProject: builder.mutation({
                query: (id) => {
                    return {
                        url: `${API_PROJECT}/${id}`,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                // invalidatesTags: ['allProjects'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            duplicateProject: builder.mutation({
                query: ({ id, data }) => {
                    return {
                        url: `${API_PROJECT_DUPLICATE}/${id}`,
                        method: HTTP_METHODS.PUT,
                        body: data,
                    };
                },
                invalidatesTags: ['allProjects'],
                transformResponse: (data) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            renameProject: builder.mutation({
                query: ({ id, data }) => {
                    return {
                        url: `${API_PROJECT}/${id}`,
                        method: HTTP_METHODS.PUT,
                        body: data,
                    };
                },
                invalidatesTags: ['allProjects'],
                transformResponse: (data) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            downloadCount: builder.mutation({
                query: (data) => {
                    return {
                        url: API_DOWNLOAD_COUNT,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (data) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getDefaultTemplates: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_TEMPALTES,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
        };
    },
});

export const {
    //project
    useGetProjectsQuery,
    useGetProjectByIdQuery,
    useLazyGetProjectByIdQuery,
    useGetCurrentProjectQuery,
    useCreateProjectMutation,
    useDeleteProjectMutation,
    useDuplicateProjectMutation,
    useRenameProjectMutation,
    useSaveCanvasMutation,
    useGetCanvasDataQuery,
    useGetDefaultTemplatesQuery,
    // asset
    useGetUploadedAssetsQuery,
    useUploadAssetMutation,
    useDownloadCountMutation,
    useDeleteAssetMutation,
} = editorApiSlice;
