import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-toastify/ReactToastify.min.css';
import { BrowserRouter } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';

Amplify.configure(awsConfig);
const root = ReactDOM.createRoot(document.getElementById('root')!);
// const appVersion = process.env.REACT_APP_VERSION;

root.render(
    <>
        <React.StrictMode>
            <AmplifyProvider>
                <Authenticator.Provider>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <BrowserRouter>
                                <CacheBuster
                                    currentVersion={'1.0.1'}
                                    isEnabled={false} //If false, the library is disabled.
                                    isVerboseMode={false} //If true, the library writes verbose logs to console.
                                    loadingComponent={<>Loading...</>} //If not pass, nothing appears at the time of new version check.
                                    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
                                >
                                    <App />
                                </CacheBuster>
                            </BrowserRouter>
                        </PersistGate>
                    </Provider>
                </Authenticator.Provider>
            </AmplifyProvider>
        </React.StrictMode>
    </>,
);
