import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../commons/types/redux';

export const selectEditFlow = (state: RootState) => state.editFlow;
export const selectTemplateFlow = (state: RootState) => state.templateFlow;
// Selector function to get all project details from the state
export const selectProjectDetails = (state: RootState) => state.editFlow.projectDetails;
//Selector function to get the save to cloud status
export const selectSaveToCloud = (state: RootState) => state.editFlow.saveToCloud;
// Create a selector using createSelector to memoize the result
export const selectAllProjectDetails = createSelector(
    [selectProjectDetails],
    (projectDetails) => projectDetails,
);
