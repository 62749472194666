import { Form, Spinner } from 'react-bootstrap';
import { useUploadAssetMutation } from './EditorRedux/editor.api';
import CustomImagePallett from './CustomImagePallett';
import { memo } from 'react';

const CustomUpload = ({ store }) => {
    const [uploadAsset, { isLoading }] = useUploadAssetMutation();

    // handle file input change
    const handleFileInputChange = async (event) => {
        const fileInput = event.target;
        const fileList = fileInput.files;
        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            // console.log(file, "file");
            await uploadAsset({ image: file }).then(async () => {
                try {
                    await refetch();
                } catch (error) {}
            });
        }
    };

    return (
        <>
            <div className="m-2 polotnoEditor">
                <h2 className="text-dark fw-semibold mb-3">Assets</h2>
            </div>
            <Form.Group className="form-group">
                <label htmlFor="fileInput" className="custom-file-inputs photography">
                    <div className="custom-file-input">
                        <i
                            className="ph ph-cloud-arrow-up text-black"
                            style={{ fontSize: '36px' }}
                        ></i>
                        <p>Click to upload image</p>
                        {/* <span>or browse</span> */}
                    </div>
                </label>
                <input
                    id="fileInput"
                    type="file"
                    className="file-input"
                    onChange={handleFileInputChange}
                />
            </Form.Group>

            {isLoading ? (
                <div className="SpinnerDiv">
                    <Spinner
                        animation="border"
                        style={{ marginTop: '40px' }}
                        className="custom-spinner"
                    />
                </div>
            ) : (
                <div className="customUpload">
                    <CustomImagePallett store={store} />
                </div>
            )}
        </>
    );
};

export default memo(CustomUpload);
