import React from 'react';
import { Image } from 'react-bootstrap';
import pixii from '../../assets/images/pixii_secondary.png';

interface TwoWayDoorLoaderProps {
    show: boolean;
}

const TwoWayDoorLoader: React.FC<TwoWayDoorLoaderProps> = ({ show }) => {
    if (!show) return null;

    return (
        <div className="apprentice_response d-flex justify-content-start align-items-center">
            <Image
                src={pixii}
                roundedCircle
                width="32"
                height="32"
                className="pixii_img bg-secondary p-1"
                loading="lazy"
            />
            <div className="d-flex justify-content-start align-items-center ms-3">
                <div className="twoway-loader">Reading your edits...</div>
            </div>
        </div>
    );
};

export default TwoWayDoorLoader;
