import { Fragment, useEffect, useState } from 'react';
import { Dropdown, InputGroup, Spinner } from 'react-bootstrap';
import {
    useCreateTextGenerationMutation,
    useGetGeneratedTextsQuery,
} from '../CreateFlow/CreateFlowRedux/createFlow.api';
import { IData } from '../CreateFlow/CreateFlowRedux/createFlowTypes';
import useClipboard from '../../commons/hooks/useClipboard';
import { useAppDispatch } from '../../commons/hooks/redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { unstable_registerNextDomDrop } from 'polotno/config';

const GeneratedText = ({
    store,
    sendTextData,
    setSendTextData,
    setInputValue,
    handleUserReuseSelect,
}: any) => {
    const [copied, copyToClipboard] = useClipboard();
    const [page, setCurrentPage] = useState(1);
    const [newlyFetchedData, setNewlyFetchedData] = useState<any>([]);
    const handleCopyClick = (text: string) => {
        copyToClipboard(text);
    };
    const [texts, setTextsData] = useState<any>([]);
    const { data, isLoading, refetch } = useGetGeneratedTextsQuery(
        page === 0 ? { page: 1, size: 7 } : { page, size: 7 },
    );
    const dispatch = useAppDispatch();
    const [resuseLoading, setReuseLoading] = useState(false);
    const [createTextGeneration, { isLoading: isCreateTextLoading }] =
        useCreateTextGenerationMutation();
    // for generated image dynamic height
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 400);
        };

        handleResize(); // Set initial height on component mount
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //end of generated image height
    // refetch data if current page changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {
                console.error('Refetch error:', error);
            } finally {
                setReuseLoading(false);
                setSendTextData(false);
            }
        };

        // Refetch data if the page changes
        fetchData();
    }, [page, refetch]);

    useEffect(() => {
        // Append the newly fetched data to the existing projects when the page changes
        if (newlyFetchedData !== undefined && (page === 1 || page === 0)) {
            setTextsData(newlyFetchedData?.records);
        } else if (newlyFetchedData !== undefined && page > 1) {
            setTextsData((prevTexts: any) => [...prevTexts, ...newlyFetchedData?.records]);
        }
    }, [newlyFetchedData]);

    useEffect(() => {
        setNewlyFetchedData(data);
    }, [data, page]);

    const setPage = () => {
        setCurrentPage((prev) => {
            switch (prev) {
                case 0:
                    return 1;
                case 1:
                    return 0;
                default:
                    return 1;
            }
        });
    };
    useEffect(() => {
        sendTextData && setPage();
    }, [sendTextData]);

    const handleReuseSubmit = async (data: IData) => {
        const { prompt, command, customerSegments, potions, brandVoice } = data;
        // setReuseLoading(true);
        // try {
        //     const res = await createTextGeneration({
        //         prompt,
        //         command,
        //         customerSegments: customerSegments.length !== 0 ? customerSegments : [],
        //         potions: potions.length !== 0 ? potions : [],
        //         brandVoice,
        //         noOfOptions: 0,
        //     }).unwrap();
        //     setPage();
        //     // setInputValue("");
        //     // dispatch(updateTextInputValue(""));
        // } catch (errorStatus) {
        //     if (errorStatus === 402) {
        //         dispatch(setCreditExpired(true));
        //     } else {
        //         displayErrorToast('There was an error');
        //     }
        // } finally {
        //     setReuseLoading(false);
        // }
        handleUserReuseSelect({
            prompt,
            command,
            customerSegments,
            potions,
            brandVoice,
        });
        // setInputValue(prompt);
    };
    const onSelect = (pos: any, text: string) => {
        const x = pos?.x;
        const y = pos?.y;

        store.activePage?.addElement({
            type: 'text',
            fontFamily: 'Roboto',
            text: text,
            x,
            y,
        });
    };
    if (isLoading) {
        return (
            <div className="results">
                {/* LOADER FOR TEXT */}
                <div className="skeleton-loader">
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                </div>
            </div>
        );
    }
    if (resuseLoading) {
        return (
            <div className="SpinnerDiv">
                <Spinner
                    animation="border"
                    style={{ marginTop: '40px' }}
                    className="custom-spinner"
                />
            </div>
        );
    }
    const fetchMoreData = () => {
        setCurrentPage((prev) => (prev === 0 ? 2 : prev + 1));
    };

    return (
        <div className="userPrompt_results">
            <InfiniteScroll
                dataLength={texts?.length || 1}
                next={fetchMoreData}
                hasMore={data?.hasNext}
                loader={
                    <div
                        className="w-100 d-flex justify-content-center align-item-center"
                        style={{ padding: '24px', height: '180px' }}
                    >
                        <Spinner
                            as="span"
                            className="mr-1 pe-none"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            style={{
                                width: '56px',
                                height: '56px',
                            }}
                        />
                    </div>
                }
                height={windowHeight}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >
                {texts?.map((gData: IData, index: number) => {
                    return (
                        <div className="results">
                            {gData?.results?.map((gText: any, index: number) => {
                                return (
                                    <div className="results-text p-3 mt-4 mb-4 me-2" key={index}>
                                        <div
                                            className="result"
                                            draggable
                                            onDragStart={() => {
                                                unstable_registerNextDomDrop(({ x, y }) => {
                                                    onSelect({ x, y }, gText?.outputs?.[0]);
                                                });
                                            }}
                                            onDragEnd={() => {
                                                unstable_registerNextDomDrop(null);
                                            }}
                                            onClick={() => {
                                                store.activePage?.addElement({
                                                    type: 'text',
                                                    fontFamily: 'Roboto',
                                                    text: gText?.outputs?.[0],
                                                });
                                            }}
                                        >
                                            <Fragment key={index}>
                                                <>
                                                    <span className="result_tagline fw-semibold mb-1 h4">
                                                        {`${
                                                            gText?.segment
                                                                ? `@${gText?.segment}`
                                                                : ''
                                                        }`}
                                                    </span>
                                                    <p className="text-dark fw-normal align-justify h3">
                                                        {gText?.outputs?.[0]}
                                                    </p>
                                                </>
                                            </Fragment>
                                        </div>
                                        <div className="result_options">
                                            <InputGroup className="custom-dropdown">
                                                <Dropdown autoClose="outside" align="end">
                                                    <Dropdown.Toggle
                                                        variant="outline-primary"
                                                        className="dropdown__toggle iconbtn"
                                                        id="dropdown-autoclose-outside"
                                                    >
                                                        <i className="ph-fill ph-dots-three-outline "></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown__toggle--menu">
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                handleCopyClick(gText?.outputs?.[0])
                                                            }
                                                        >
                                                            <i className="ph ph-copy lg"></i>
                                                            {!copied ? (
                                                                <strong>Copy</strong>
                                                            ) : (
                                                                'Copied'
                                                            )}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => handleReuseSubmit(gData)}
                                                        >
                                                            <i className="ph ph-repeat lg"></i>{' '}
                                                            Re-Use
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item>
                                                            <i className="ph ph-bookmark-simple "></i>{' '}
                                                            Add to Potions
                                                        </Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </InputGroup>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default GeneratedText;
