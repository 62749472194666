import {
    Navbar,
    Nav,
    InputGroup,
    Dropdown,
    DropdownButton,
    Button,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import Logo from '../../assets/images/textLogo.svg';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../commons/hooks/redux';
import { addFavourites } from '../CreateFlow/CreateFlowRedux/createFlowSlice';
import { useGetFavouritesResultsQuery } from '../CreateFlow/CreateFlowRedux/createFlow.api';
import { Link } from 'react-router-dom';
import {
    saveProjectDetails,
    saveProjectTemplate,
    setActiveUserEmailId,
    setActiveUserId,
} from '../Editor/EditorRedux/editorSlice';
import { useSelector } from 'react-redux';
import { selectCreateFlow } from '../CreateFlow/CreateFlowRedux/selector';
import { useState } from 'react';
import CustomModal from '../../components/AppElements/Modal';
import { imagePreviewDataURLFunc } from '../../constants/imageDataPreviewUrl';
import { useCreateAndPersistProject } from '../../utils/projectUtils';
import { setUserSubscriptionStats } from '../PaddlePayWall/PaddlePayWallRedux/paddlePayWallSlice';
const Header = ({
    store,
    editingMode,
    setEditingMode,
    isOpen,
    setIsOpen,
    closeApprentice,
    setCloseApprentice,
    showSidebar,
    setShowSidebar,
}: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { data } = useGetFavouritesResultsQuery();
    const { onBoardedUser, userData } = useAppSelector(selectCreateFlow);
    const { editorPersistData, project, activeUserId } = useSelector(
        (state: any) => state.editFlow,
    );
    const [loading, setLoader] = useState(false);
    const { createAndPersistProject, isLoading } = useCreateAndPersistProject();
    const location = useLocation();
    const handleEditor = () => {
        dispatch(addFavourites(data));
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const subscriptonStatus = useSelector((state: any) => state.paddleFlow.subscribedUser);
    const toggleEditor = () => {
        setIsOpen(false);
        setEditingMode(!editingMode);
        setCloseApprentice(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const toggleApprentice = () => setCloseApprentice(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    // Function to handle logout
    const handleLogout = async () => {
        // Redirect to the home page ("/") after logging out
        Auth.signOut().then(() => {
            const payload = {
                userId: activeUserId,
                projectName: project?.name,
                projectId: project?.id,
                projectJson: editorPersistData,
            };
            dispatch(saveProjectDetails(payload));
            localStorage.removeItem('polotnoJson');
            dispatch(setActiveUserId(''));
            dispatch(setActiveUserEmailId(''));
            navigate('/login', { replace: true });
        });
    };
    // Define a function to determine if a given path matches the current location
    const isActive = (path: string) => location.pathname === path;

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault(); // Prevent the default behavior of the link
        // Check the condition related to the URL
        const isEditorUrl = window.location.pathname === '/editor';
        if (isEditorUrl) {
            if (project.name !== 'Untitled' && project?.id === null) {
                // Show modal to confirm navigation
                setIsModalOpen(true);
            } else {
                // Save the project and navigate to the "/Apprentice" route
                saveProject();
            }
        } else {
            navigate('/');
        }
    };
    const saveProject = async () => {
        const projectJSON = store.toJSON();
        navigate('/');
        const previewImg = await imagePreviewDataURLFunc(projectJSON);
        const projectTemplate = {
            id: project?.id,
            json: projectJSON,
            imgDataURL: previewImg,
        };
        await createAndPersistProject(projectTemplate, store, false);
    };
    const handleNo = async () => {
        // console.log(project?.id, 'project?.id === null');
        closeModal();
        navigate('/');
    };
    const handleYes = async () => {
        let projectTemplate;
        const projectJSON = store.toJSON();
        let projectName = '';
        if (project?.id === null) {
            projectName = window.prompt('Enter Project name:') as string;
            // If the user clicks "Cancel" or provides an empty string, treat it as a cancellation
            if (projectName === null || projectName.trim() === '') {
                return; // Exit the function without further processing
            }
        }
        setLoader(true);
        const previewImg = await imagePreviewDataURLFunc(projectJSON);
        if (project?.id === null) {
            if (projectName) {
                projectTemplate = {
                    name: projectName,
                    json: projectJSON,
                    imgDataURL: previewImg,
                    isImportedOrCreated: true,
                };
            }
        } else {
            projectTemplate = {
                id: project?.id,
                json: projectJSON,
                imgDataURL: previewImg,
            };
        }
        try {
            await createAndPersistProject(projectTemplate, store);
            navigate('/');
        } catch (error) {
            // Handle specific error cases if needed
            console.error('Error creating and persisting project:', error);
        } finally {
            setLoader(false);
            closeModal();
        }
        project?.id === null && dispatch(saveProjectTemplate(''));
    };
    const isMobile = window.innerWidth <= 768;
    const toggleOffcanvas = () => setShowSidebar(!showSidebar);
    return (
        <>
            <Navbar
                className="custom_nav"
                bg="secondary"
                style={{ zIndex: subscriptonStatus ? 1 : 1100 }}
            >
                <div className="custom_nav-left">
                    <Navbar.Brand href="/" className="custom_nav-logo">
                        <img src={Logo} className="custom_logo-img" />
                    </Navbar.Brand>
                    {/* {editingMode && isMobile !== true && (
                        <div className="bg-white">
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 150, hide: 400 }}
                                overlay={<Tooltip>Open Sidebar</Tooltip>}
                            >
                                <Button
                                    style={{
                                        backgroundColor: '#e5e5e5',
                                    }}
                                    variant="secondary"
                                    className="iconbtn rounded-circle"
                                    onClick={toggleOffcanvas}
                                >
                                    <i className="ph ph-caret-right"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    )} */}

                    {isMobile ? (
                        <Button
                            // style={{
                            //     position: 'fixed',
                            //     top: 60,
                            //     left: showSidebar ? '78%' : '1%',
                            //     zIndex: 1050, // Ensure it's above other content
                            // }}
                            variant="primary"
                            className="iconbtn rounded-circle"
                            onClick={toggleOffcanvas}
                        >
                            {showSidebar ? (
                                <i className="ph ph-caret-left"></i>
                            ) : (
                                <i className="ph ph-caret-right"></i>
                            )}
                        </Button>
                    ) : (
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 150, hide: 400 }}
                            overlay={<Tooltip>{isOpen ? 'Close Sidebar' : 'Open Sidebar'}</Tooltip>}
                        >
                            <Button
                                style={{
                                    backgroundColor: '#e5e5e5',
                                }}
                                variant="secondary"
                                className="iconbtn rounded-circle"
                                onClick={() => {
                                    // Toggle isOpen only if editingMode is not true
                                    if (!editingMode) {
                                        setIsOpen(!isOpen);
                                    } else if (isMobile) {
                                        toggleOffcanvas();
                                    } else {
                                        toggleOffcanvas();
                                    }
                                }}
                            >
                                {isOpen ? (
                                    <i className="ph ph-caret-left"></i>
                                ) : (
                                    <i className="ph ph-caret-right"></i>
                                )}
                            </Button>
                        </OverlayTrigger>
                    )}
                    {onBoardedUser && (
                        <div className="nav__menu d-flex justify-content-between align-item-center flex-row">
                            {/* <Link
                                to="/"
                                onClick={(e) => handleLinkClick(e)}
                                className={`nav-link me-3 ${
                                    isActive('/') ? 'active' : ''
                                } text-capitalize h3  m-0 fw-medium`}
                            >
                                Apprentice
                            </Link>
                            <Link
                                to="/editor"
                                className={`nav-link me-3 ${
                                    isActive('/editor') ? 'active' : ''
                                } text-capitalize h3  m-0 fw-medium`}
                            >
                                Editor
                            </Link>
                            <Link
                                to="/brandProfile"
                                className={`nav-link me-3 ${
                                    isActive('/brandProfile') ? 'active' : ''
                                } text-capitalize h3  m-0 fw-medium`}
                            >
                                Brand Profile
                            </Link> */}
                        </div>
                    )}

                    {/* UNCOMMENT WHEN PADDLE IS INTEGRATED  */}
                    {/* {onBoardedUser && (
                        <div className="nav__menu d-flex justify-content-between align-item-center flex-row">
                            {subscriptonStatus ? (
                                <>
                                    <Link
                                        to="/"
                                        onClick={(e) => handleLinkClick(e)}
                                        className={`nav-link me-3 ${
                                            isActive('/') ? 'active' : ''
                                        } text-capitalize h3  m-0 fw-medium`}
                                    >
                                        Apprentice
                                    </Link>
                                    <Link
                                        to="/editor"
                                        className={`nav-link me-3 ${
                                            isActive('/editor') ? 'active' : ''
                                        } text-capitalize h3  m-0 fw-medium`}
                                    >
                                        Editor
                                    </Link>
                                </>
                            ) : null}
                            <Link
                                to="/brandProfile"
                                className={`nav-link me-3 ${
                                    isActive('/brandProfile') ? 'active' : ''
                                } text-capitalize h3  m-0 fw-medium`}
                            >
                                Brand Profile
                            </Link>
                        </div>
                    )} */}

                    {/* {!onBoardedUser && (
                        <div className="nav__menu d-flex justify-content-between align-item-center">
                            <Link
                                to="/brandProfile"
                                className={`nav-link me-3 mt-2 ${
                                    isActive('/brandProfile') ? 'active' : ''
                                } text-capitalize h3  fw-semibold text-primary`}
                            >
                                Brand Profile
                            </Link>
                        </div>
                    )} */}
                </div>

                <div className="custom_nav-profile d-flex justify-content-between gap-2 flex-row ">
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>Edit Mode</Tooltip>}
                    >
                        <Button
                            variant="outline-primary"
                            className={`iconbtn rounded-circle ${editingMode ? 'activeMode' : ''}`}
                            onClick={toggleEditor}
                            style={{
                                transition: 'all 0.3s ease-in-out',
                            }}
                        >
                            <i className="ph ph-tabs md"></i>
                        </Button>
                    </OverlayTrigger>

                    <InputGroup className="custom-dropdown">
                        <DropdownButton
                            align="end"
                            title={<i className="ph ph-user lg"></i>}
                            id="dropdown-menu-align-end"
                            className="dropdown__toggle userProfile"
                        >
                            <Dropdown.Header className="mt-2 mb-2">
                                <h3 className="fw-semibold ">{userData?.userName}</h3>
                                <p className="text-muted m-0 ">{userData?.email}</p>
                            </Dropdown.Header>
                            {onBoardedUser && (
                                <Dropdown.Item href="/brandProfile">
                                    <i className="ph ph-magic-wand sm"> </i>Brand Profile
                                </Dropdown.Item>
                            )}
                            {/*currently there is an issue with Authenticator.Provider in amplify package. 
          Update it once the package is updated by the team */}
                            {isMobile && (
                                <>
                                    <Dropdown.Item href="mailto:monte@pixii.ai,risa@pixii.ai">
                                        <i className="ph ph-envelope-simple"></i> Get help or Share
                                        feedback
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            window.location.href = '/faq';
                                            e.preventDefault();
                                        }}
                                    >
                                        <i className="ph ph-arrow-square-out"></i> Help & FAQ
                                    </Dropdown.Item>
                                </>
                            )}
                            <Dropdown.Item onClick={handleLogout}>
                                <i className="pxi-log-out-2 sm"></i> Sign out
                            </Dropdown.Item>
                        </DropdownButton>
                    </InputGroup>
                </div>
            </Navbar>
            {isModalOpen && (
                // <CustomModal
                //     title="Do you want to save the project?"
                //     show={isModalOpen}
                //     onHide={closeModal}
                //     isLoading={loading}
                // >
                //     <Modal.Footer style={{ border: 'none' }}>
                //         <Button
                //             variant="primary"
                //             size="sm"
                //             onClick={() => {
                //                 // closeModal();
                //                 handleYes();
                //             }}
                //             disabled={loading}
                //         >
                //             Yes
                //         </Button>
                //         <Button
                //             variant="outline-primary"
                //             size="sm"
                //             onClick={handleNo}
                //             disabled={loading}
                //         >
                //             No
                //         </Button>
                //     </Modal.Footer>
                // </CustomModal>
                <CustomModal
                    title="Do you want to save the project?"
                    show={isModalOpen}
                    onHide={closeModal}
                    isLoading={loading}
                    buttonText="Yes"
                    buttonFunction={handleYes}
                    secondButtonText="No"
                    secondButtonFunction={handleNo}
                />
            )}
        </>
    );
};
export default Header;
