import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';

import ShowSelectedImage from './ShowSelectedImage';
import ImagesForm from './ImagesForm';

import {
    useGetUploadedAssetsQuery,
    useUploadAssetMutation,
} from '../../../Editor/EditorRedux/editor.api';

import { productImageProps, ImageType } from '../Interfaces';

interface ProductPhotographyProps {
    productPhotographyData: productImageProps;
    show: boolean;
    handleShowModal: Dispatch<SetStateAction<boolean>>;
    setProductImageData: Dispatch<SetStateAction<productImageProps>>;
    handleUploadClick: () => void;
}

const ProductPhotography: React.FC<ProductPhotographyProps> = ({
    productPhotographyData,
    show,
    handleShowModal,
    setProductImageData,
    handleUploadClick,
}) => {
    const [page, setCurrentPage] = useState(1);

    const { data: previousAssets, refetch } = useGetUploadedAssetsQuery(page === 0 ? 1 : page);
    const [uploadAsset, { isLoading: uploadAssetLoading }] = useUploadAssetMutation();
    const [hasNext, setHasNext] = useState(true);

    const saveAssetImageFile = async (to_upload_image: File) => {
        await uploadAsset({ image: to_upload_image }).then((res: any) => {
            const _image_url = res?.data?.image;
            setProductImageData({
                imageData: {
                    imageType: ImageType.URL,
                    data: _image_url,
                    id: '',
                },
                isImageDataPresent: true,
            });
        });
        await refetch();
    };

    useEffect(() => {
        if (!previousAssets) return;

        setHasNext(previousAssets.hasNext);

        const combinedImages = Array.from(
            new Map(
                [
                    ...imageList,
                    ...previousAssets.records.map((image: any) => ({
                        ...image,
                        isSelected: false,
                    })),
                ].map((item) => [item.id, item]),
            ).values(),
        ).sort((a, b) => b.id - a.id);

        setImageList(combinedImages);
    }, [previousAssets]);

    const fetchMoreData = () => {
        if (!hasNext) return;
        setCurrentPage((prev) => prev + 1);
    };

    const handleClose = () => {
        handleShowModal(false);
        setProductImageToDefault();
    };
    const handleUploadClickInternal = async () => {
        if (productPhotographyData.imageData.data instanceof File) {
            await saveAssetImageFile(productPhotographyData.imageData.data);
        }
        handleUploadClick();
    };

    const setProductImageToDefault = () => {
        setProductImageData({
            imageData: {
                imageType: ImageType.NONE,
                data: null,
                id: '',
            },
            isImageDataPresent: false,
        });
    };
    const handleNo = () => {
        setProductImageToDefault();
    };

    const [imageList, setImageList] = useState<any>([]);

    const isImageSelected = Boolean(productPhotographyData.isImageDataPresent);

    return (
        <Modal show={show} centered backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Product Photography Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ShowSelectedImage // done
                    productPhotographyData={productPhotographyData}
                    handleNo={handleNo}
                    setProductImageData={setProductImageData}
                    handleUploadClick={handleUploadClickInternal}
                    uploadLoading={uploadAssetLoading}
                />
                <ImagesForm
                    setImageList={setImageList}
                    productPhotographyData={productPhotographyData}
                    setProductImageData={setProductImageData}
                    imageList={imageList}
                    fetchMoreData={fetchMoreData}
                    previousAssets={previousAssets}
                    show={!isImageSelected}
                />
            </Modal.Body>
        </Modal>
    );
};

export default ProductPhotography;
