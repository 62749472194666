import React, { useState, useRef } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';

interface DeleteModalProps {
    show: boolean;
    onHide: () => void;
    onDelete: () => void;
    loading: boolean;
}
const DeleteModal: React.FC<DeleteModalProps> = (props) => {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="d-flex align-item-center"
            size="sm"
        >
            <Modal.Header closeButton className="border-none pb-0">
                <p className="text-dark mt-2 mb-2 fw-normal">Are you sure you want to delete?</p>
            </Modal.Header>
            <Modal.Footer style={{ border: 'none' }}>
                <Button variant="outline-primary" size="sm" onClick={props.onHide}>
                    No
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={props.onDelete}
                    disabled={props.loading}
                >
                    {props.loading ? (
                        <Spinner
                            as="span"
                            className="mr-1 pe-none"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            style={{ width: '26px', height: '26px' }}
                        />
                    ) : (
                        'Yes'
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
