import React from 'react';
import { Spinner } from 'react-bootstrap';

interface LoadingSpinnerProps {
    show: boolean;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ show }) => {
    if (!show) return null;

    return (
        <div className="d-flex justify-content-center align-items-center">
            <Spinner
                animation="border"
                role="status"
                style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    color: '#F2E6F2',
                    marginBottom: '10px',
                }}
            >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};

export const FetchingChatsLoader: React.FC<LoadingSpinnerProps> = ({ show }) => {
    if (!show) return null;
    return (
        <div className="d-flex justify-content-center align-items-end h-100">
            <span>Fetching chats...</span>
        </div>
    );
};
