export enum BrandElementsFontsClass {
    HEADER = 'Header',
    SUB_HEADER = 'Sub-Header',
    BODY = 'Body',
    ACCENT = 'Accent',
}

export interface IGetUploadedAssets {
    id: number;
    assets: string;
}

export interface IUploadedAssetsWithUrl {
    id: number;
    url: string;
}

export interface IUploadedProductPhotoWithUrl {
    id: number;
    url: string;
}

export interface IBrandElementFont {
    fontFamily: string;
    url?: string;
}

export interface IGetBrandElements {
    id: number;
    fonts: {
        [BrandElementsFontsClass.HEADER]?: IBrandElementFont[];
        [BrandElementsFontsClass.SUB_HEADER]?: IBrandElementFont[];
        [BrandElementsFontsClass.BODY]?: IBrandElementFont[];
        [BrandElementsFontsClass.ACCENT]?: IBrandElementFont[];
    };
    colors: string[];
    logo: string | null;
}

export interface IRemoveBrandElementsFont {
    fontClass: string;
    fontFamily: string;
}

export interface IAddBrandElementsFont {
    fontClass: string;
    fontFamily: string;
    fontFile?: File;
}

export interface IAddColor {
    color: string;
}

export interface IRemoveColor {
    color: string;
}
