import { baseApiSlice } from '../../../commons/base-api.config';
import { API_ACTIVE_SESSION, API_CREATE_SESSION, API_UPDATE_SESSION } from '../../../constants/api';
import { HTTP_METHODS } from '../../../constants/http';
import { IGetActiveSessionResponse } from '../session.interface';

export const sessionApiSlice = baseApiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            getActiveSession: builder.query<IGetActiveSessionResponse, void>({
                query() {
                    return {
                        url: API_ACTIVE_SESSION,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['activeSession'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createNewSession: builder.mutation<IGetActiveSessionResponse, void>({
                query() {
                    return {
                        url: API_CREATE_SESSION,
                        method: HTTP_METHODS.GET,
                    };
                },

                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),

            renameSession: builder.mutation({
                query: ({ id, data }) => {
                    return {
                        url: `${API_UPDATE_SESSION}/${id}`,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                transformResponse: (data) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteSession: builder.mutation({
                query: ({ id }) => {
                    return {
                        url: `${API_UPDATE_SESSION}/${id}`,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                transformResponse: (data) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
        };
    },
});

export const {
    useGetActiveSessionQuery,
    useCreateNewSessionMutation,
    useRenameSessionMutation,
    useDeleteSessionMutation,
} = sessionApiSlice;
