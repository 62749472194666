import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { IChat, ChatTypeEnum } from './apprentice.interface'; // Ensure this path is correct
import { renderFormattedText, highlightKeywords } from './RenderText'; // Adjust the import paths as necessary
import userDefault from '../../assets/images/profilePicture.png';
import pixii from '../../assets/images/pixii_secondary.png';
import GeneratedAd from './GeneratedImage';

interface RenderMessageProps {
    show: boolean;
    tips: boolean;
    messages: IChat[];
    chatLoading: boolean;
    twoWayDoorLoading: boolean;
    editingMode: boolean;
    isMobile: boolean;
    loadingStep: number;
    loadingMessages: string[];
    handleOpenInEditor: (templateId: any, sessionId: string) => void;
}

const RenderMessage: React.FC<RenderMessageProps> = ({
    messages,
    show,
    tips,
    chatLoading,
    twoWayDoorLoading,
    editingMode,
    isMobile,
    loadingStep,
    loadingMessages,
    handleOpenInEditor,
}) => {
    if (!show) return null;
    return (
        <>
            {messages.map((message, index) => {
                const isLastChild = index === messages.length - 1;
                return (
                    <div key={index} className="pe-2">
                        <div>
                            {!message.system ? (
                                <div className="d-flex justify-content-end">
                                    {/* What user Asks */}
                                    <div className="user_responses  d-flex justify-content-end align-items-start">
                                        <div className="d-flex justify-content-end">
                                            {message.type === ChatTypeEnum.TEXT ? (
                                                <motion.div
                                                    key={message.id}
                                                    initial={{
                                                        opacity: 0,
                                                        x: 50,
                                                        scale: 0.8,
                                                    }}
                                                    animate={{
                                                        scale: 1,
                                                        opacity: 1,
                                                        x: 0,
                                                    }}
                                                    exit={{
                                                        scale: 0.8,
                                                        opacity: 0,
                                                        x: -50,
                                                    }}
                                                    transition={{
                                                        type: 'spring',
                                                        // stiffness: 50,
                                                        damping: 20,
                                                        duration: 0.5,
                                                    }}
                                                    className="user_response response"
                                                    style={{
                                                        maxWidth: editingMode
                                                            ? '30rem'
                                                            : isMobile
                                                            ? '18rem'
                                                            : '40rem',
                                                    }}
                                                >
                                                    {highlightKeywords(message.value)}
                                                </motion.div>
                                            ) : (
                                                <></>
                                            )}
                                            {!(
                                                message.type === ChatTypeEnum.TEXT ||
                                                message.type === ChatTypeEnum.POLOTONO_PREVIEW ||
                                                message.type === ChatTypeEnum.FROM_EDITOR ||
                                                (message.type === ChatTypeEnum.RESPONSE_TEXT &&
                                                    message.value &&
                                                    Object.keys(message.value).length > 0)
                                            ) && (
                                                <div className="response_img">
                                                    <p className="text-danger fw-normal">
                                                        Sorry, Our Servers Are Busy!!! Please Try
                                                        Again later!!
                                                    </p>
                                                </div>
                                            )}
                                            <Image
                                                src={userDefault}
                                                roundedCircle
                                                width="32"
                                                height="32"
                                                loading="lazy"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (message.type === ChatTypeEnum.POLOTONO_PREVIEW ||
                                  message.type === ChatTypeEnum.FROM_EDITOR) &&
                              message.value === '' ? null : ( // Do not render if type is POLOTONO_PREVIEW and value is empty
                                // response from pixii
                                <div>
                                    {' '}
                                    {message.type === ChatTypeEnum.FROM_EDITOR ? (
                                        <>
                                            <div className="d-flex align-items-center justify-content-center p-1 my-1">
                                                <p className="text-light h4">I read your edits</p>
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}{' '}
                                    <div className="apprentice_responses  d-flex justify-content-start align-items-start">
                                        <div className="d-flex justify-content-start gap-3">
                                            {(chatLoading ||
                                                twoWayDoorLoading ||
                                                message.type === ChatTypeEnum.TEXT ||
                                                ((message.type === ChatTypeEnum.POLOTONO_PREVIEW ||
                                                    message.type === ChatTypeEnum.FROM_EDITOR ||
                                                    message.type === ChatTypeEnum.RESPONSE_TEXT) &&
                                                    message.value &&
                                                    Object.keys(message.value).length > 0)) && (
                                                <Image
                                                    src={pixii}
                                                    roundedCircle
                                                    width="32"
                                                    height="32"
                                                    className="pixii_img bg-secondary"
                                                    loading="lazy"
                                                />
                                            )}

                                            {index === messages.length - 1 && chatLoading ? (
                                                <div className="apprentice_loader">
                                                    <AnimatePresence>
                                                        <motion.p
                                                            key={loadingStep}
                                                            initial={{
                                                                opacity: 0,
                                                            }}
                                                            animate={{
                                                                opacity: 1,
                                                            }}
                                                            exit={{
                                                                opacity: 0,
                                                            }}
                                                            transition={{
                                                                duration: 0.8,
                                                                ease: 'easeInOut',
                                                            }}
                                                            style={{
                                                                height: '2em',
                                                                width: '100%',
                                                            }}
                                                            className="text-primary d-flex align-items-center"
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginRight: '0.5em',
                                                                }}
                                                            >
                                                                <Spinner
                                                                    animation="border"
                                                                    role="status"
                                                                    style={{
                                                                        width: '1.5rem',
                                                                        height: '1.5rem',
                                                                        color: '#F2E6F2',
                                                                    }}
                                                                >
                                                                    <span className="visually-hidden">
                                                                        Loading...
                                                                    </span>
                                                                </Spinner>
                                                            </div>
                                                            <motion.span
                                                                style={{
                                                                    display: 'inline-block',
                                                                    fontSize: '0.875em',
                                                                    width: '100%',
                                                                    overflow: 'hidden',
                                                                    marginLeft: '0.2em',
                                                                }}
                                                            >
                                                                {loadingMessages[loadingStep]}
                                                            </motion.span>
                                                        </motion.p>
                                                    </AnimatePresence>
                                                </div>
                                            ) : (
                                                <>
                                                    {message.type === ChatTypeEnum.TEXT && (
                                                        <div>
                                                            <p
                                                                className="apprentice_response response"
                                                                style={{
                                                                    maxWidth: editingMode
                                                                        ? '30rem'
                                                                        : isMobile
                                                                        ? '18rem'
                                                                        : '40rem',
                                                                }}
                                                            >
                                                                {message.value}
                                                            </p>
                                                        </div>
                                                    )}

                                                    {message.type === ChatTypeEnum.IMAGE && (
                                                        <div className="response_img">
                                                            <Image
                                                                src={message.value}
                                                                loading="lazy"
                                                                className="generatedAd"
                                                            />
                                                        </div>
                                                    )}
                                                    {message.type === ChatTypeEnum.RESPONSE_TEXT &&
                                                        message.value &&
                                                        Object.keys(message.value).length > 0 && (
                                                            <motion.div
                                                                key={index}
                                                                initial={{
                                                                    opacity: 0,
                                                                    x: -50,
                                                                    scale: 0.8,
                                                                }}
                                                                animate={{
                                                                    scale: 1,
                                                                    opacity: 1,
                                                                    x: 0,
                                                                }}
                                                                exit={{
                                                                    scale: 0.8,
                                                                    opacity: 0,
                                                                    x: 50,
                                                                }}
                                                                transition={{
                                                                    type: 'spring',
                                                                    // stiffness: 260,
                                                                    damping: 20,
                                                                    duration: 0.5,
                                                                }}
                                                                className={
                                                                    isLastChild && (tips || show)
                                                                        ? 'mb-5 apprentice_response response'
                                                                        : 'mb-3 apprentice_response response'
                                                                }
                                                                style={{
                                                                    maxWidth: editingMode
                                                                        ? '30rem'
                                                                        : isMobile
                                                                        ? '18rem'
                                                                        : '40rem',
                                                                }}
                                                            >
                                                                <ul className="m-0 p-0">
                                                                    {message.value[0]
                                                                        .key_message && (
                                                                        <li>
                                                                            {renderFormattedText(
                                                                                message.value[0]
                                                                                    .key_message,
                                                                            )}
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </motion.div>
                                                        )}

                                                    {(message.type ===
                                                        ChatTypeEnum.POLOTONO_PREVIEW ||
                                                        message.type === ChatTypeEnum.FROM_EDITOR ||
                                                        (message.type ===
                                                            ChatTypeEnum.RESPONSE_TEXT &&
                                                            message.value &&
                                                            Object.keys(message.value).length >
                                                                0)) && (
                                                        <>
                                                            {(message.type ===
                                                                ChatTypeEnum.POLOTONO_PREVIEW ||
                                                                message.type ===
                                                                    ChatTypeEnum.FROM_EDITOR) && (
                                                                <GeneratedAd
                                                                    key={index}
                                                                    message={message}
                                                                    handleOpenInEditor={
                                                                        handleOpenInEditor
                                                                    }
                                                                    index={index}
                                                                    // setIsOpen={
                                                                    //     setIsOpen
                                                                    // }
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default RenderMessage;
