import React, { useState } from 'react';
import ImagePromptArea from './ImagePromptArea';
import GeneratedImage from './GeneratedImage';

interface ImageContainerProps {
    store: any;
}

export interface PrefillData {
    modelType: Model;
    pixiiPotion: string;
    prompt: string;
    potionTitle: string | null;
}

export type Model = 'VARIETY' | 'QUALITY FAST' | 'QUALITY SLOW';

export default function ImageContainer({ store }: ImageContainerProps) {
    const [sendData, setSendData] = useState(false);
    const [imagePrompt, setImagePrompt] = useState('');
    const [activePotion, setActivePotion] = useState<null | string>();
    const [selectedUserFavourite, setSelectedUserFavourite] = useState<null | string>();
    const [modelType, setModelType] = useState<Model>('QUALITY FAST');

    const handleSendData = () => {
        setSendData(true);
    };

    const handleUserFavouritesOrReuseSelect = (data: PrefillData, resuse?: boolean) => {
        const { modelType, pixiiPotion, prompt, potionTitle } = data;
        setImagePrompt(changePromptToPromptSelection(prompt));
        setModelType(modelType);
        setActivePotion(pixiiPotion);
        if (resuse) {
            setSelectedUserFavourite(null);
        } else {
            setSelectedUserFavourite(potionTitle);
        }
    };
    function changePromptToPromptSelection(str: string) {
        const potionRegx = /~[\w-]+/g;
        const potionTitles = str.match(potionRegx);
        let processedPotionTitle = str; // return same if no pixii potion
        if (potionTitles) {
            processedPotionTitle = processedPotionTitle.replace(
                /~[\w-]+/,
                `@[${potionTitles[0]}](0)`,
            );
            console.log('****', processedPotionTitle);
        }

        return processedPotionTitle;
    }

    return (
        <div className="generate">
            <div className="userPrompt">
                <ImagePromptArea
                    handleSendData={handleSendData}
                    setActivePotion={setActivePotion}
                    imagePrompt={imagePrompt}
                    setImagePrompt={setImagePrompt}
                    setSelectedUserFavourite={setSelectedUserFavourite}
                    activePotion={activePotion}
                    modelType={modelType}
                    selectedUserFavourite={selectedUserFavourite}
                    handleUserFavouritesOrReuseSelect={handleUserFavouritesOrReuseSelect}
                    setModelType={setModelType}
                />
                <GeneratedImage
                    store={store}
                    sendData={sendData}
                    setSendData={setSendData}
                    handleUserFavouritesOrReuseSelect={handleUserFavouritesOrReuseSelect}
                />
            </div>
        </div>
    );
}
