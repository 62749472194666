import { toast } from 'react-toastify';

export const displayErrorToast = (message: string) => {
    toast.dismiss(); // Clear any existing toast before showing a new one
    toast.error(message);
};
export const displaySuccessToast = (message: string) => {
    toast.dismiss(); // Clear any existing toast before showing a new one
    toast.success(message);
};
export const displayWarningToast = (message: string) => {
    toast.dismiss(); // Clear any existing toast before showing a new one
    toast.warning(message);
};
