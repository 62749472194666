import React, { Dispatch, SetStateAction } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
interface ShowUploadedFileProps {
    uploadedFile: File | null;
    handleRemoveDoc: Dispatch<SetStateAction<string | null>>;
}

export const ShowUploadedFile: React.FC<ShowUploadedFileProps> = ({
    uploadedFile,
    handleRemoveDoc,
}) => {
    if (!uploadedFile) return null;

    return (
        <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="button-tooltip-2">{uploadedFile.name}</Tooltip>}
            trigger="hover"
        >
            <div className=" position-relative ">
                <div
                    className="d-flex p-1 gap-1 flex-column justify-content-center align-items-center bg-white border rounded"
                    style={{ width: '3.4375rem', height: '3.4375rem' }}
                >
                    <i className="ph ph-file-pdf" style={{ fontSize: '32px' }}></i>
                    <button
                        className="iconbtn iconbtn-transparent position-absolute end-0"
                        onClick={() => handleRemoveDoc(null)}
                        style={{
                            zIndex: 2,
                            top: '-5px',
                            border: 'none',
                            background: 'transparent',
                            transform: 'rotate(135deg)',
                            color: '#b40d0d',
                        }} // Ensure button has no border and transparent background
                    >
                        <i className="ph-bold ph-prohibit-inset"></i>
                    </button>
                </div>
            </div>
        </OverlayTrigger>
    );
};
