import { Button, Modal, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

interface PaywallProps {
    show: boolean;
    onHide: () => void;
    paddle: any;
}
const Paywall: React.FC<PaywallProps> = (props) => {
    const currentUserId = useSelector((state: any) => state.editFlow.activeUserId);
    const currentUserEmailId = useSelector((state: any) => state.editFlow.activeUserMail);
    const openPaddleProCheckout = (event: any) => {
        event?.preventDefault();
        props.paddle?.Checkout?.open({
            customer: {
                email: currentUserEmailId,
            },
            settings: {
                displayMode: 'overlay',
                theme: 'light',
                locale: 'en',
            },
            customData: {
                crm_id: currentUserId,
                utm_source: 'google',
            },
            items: [
                {
                    priceId: 'pri_01hjqnr9bwep13gkj0h2w89612',
                    quantity: 1,
                },
            ],
            disableLogout: true,
        });
        props.onHide;
    };
    const openPaddleBetaCheckout = (event: any) => {
        event?.preventDefault();
        props.paddle?.Checkout?.open({
            customer: {
                email: currentUserEmailId,
            },
            settings: {
                displayMode: 'overlay',
                theme: 'light',
                locale: 'en',
            },
            customData: {
                crm_id: currentUserId,
                utm_source: 'google',
            },
            items: [
                {
                    priceId: 'pri_01hrwge2qrh7sg19mhn18whecv',
                    quantity: 1,
                },
            ],
            disableLogout: true,
        });
        props.onHide;
    };
    return (
        <Modal
            {...props}
            centered
            className="p-5"
            backdrop="static"
            keyboard={false}
            size="xl"
            dialogClassName="paywallmodal"
        >
            <Modal.Body className="border-0 p-4 pb-0 paywall">
                <Row className="paywall_row">
                    <Col className="paywall_col">
                        <div className="p-3">
                            <p className="fw-bold fs-5 m-0 text-primary">Beta Tester </p>
                            {/* Plan price */}
                            <div className="price">
                                <div className="price-offer mt-3">
                                    <div className="d-flex flex-row mb-2">
                                        <p className="fw-bold fs-3 m-0 p-0">US$30</p>
                                        <span className="text-light m-0 p-0 fs-6"> / month </span>
                                    </div>
                                    <span className="price-trial">
                                        {' '}
                                        <span className="underline me-1">7 day</span>free trial
                                    </span>
                                </div>

                                <div className="price-content">
                                    <p className="m-0 p-0 mt-2 mb-1 fw-bold content-includes text-dark">
                                        Includes:
                                    </p>
                                    <ul>
                                        <li>
                                            <i className="ph ph-check me-1"></i>50 ads
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Immediate revisions
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Al product
                                            photography
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Smart resize
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Variants
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Editor
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Stock photo library
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Direct line to the
                                            founder and growth lead
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Multiple AI models
                                            including GPT4
                                        </li>
                                    </ul>
                                </div>
                                <div className="pt-3  mt-3 mb-3 pb-0 d-flex flex-column justify-content-end">
                                    <Button
                                        variant="primary"
                                        className="mb-3"
                                        onClick={(e) => openPaddleBetaCheckout(e)}
                                    >
                                        {' '}
                                        Start Now
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="paywall_col">
                        <div className="p-3">
                            <p className="fw-bold fs-5 m-0 text-primary">Pro </p>
                            {/* Plan price */}
                            <div className="price">
                                <div className="price-offer mt-3">
                                    <div className="d-flex flex-row mb-2">
                                        <p className="fw-bold fs-3 m-0 p-0">US$500</p>
                                        <span className="text-light m-0 p-0 fs-6"> / month </span>
                                    </div>
                                    <span className="price-trial">
                                        {' '}
                                        <span className="underline me-1">7 day</span>free trial
                                    </span>
                                </div>
                                <div className="price-content">
                                    <p className="m-0 p-0 mt-2 mb-1 fw-bold content-includes text-dark">
                                        Includes:
                                    </p>
                                    <ul>
                                        <li>
                                            <i className="ph ph-check me-1"></i> 500 ads
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i> Immediate revisions
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i> AI product
                                            photography
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i> Smart Resize
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i> Variants{' '}
                                        </li>

                                        <li>
                                            <i className="ph ph-check me-1"></i> Editor
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i> Stock photo library
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i> Direct line to the
                                            founder and growth lead
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i> Multiple AI models
                                            including GPT4
                                        </li>
                                    </ul>
                                </div>
                                <div className="pt-3  mt-3 mb-3 pb-0 d-flex flex-column justify-content-end">
                                    <Button
                                        variant="primary"
                                        className="mb-3"
                                        onClick={(e) => openPaddleProCheckout(e)}
                                    >
                                        {' '}
                                        Start Now
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="paywall_col">
                        <div className="p-3">
                            <p className="fw-bold fs-5 m-0 text-primary">Enterprise</p>
                            <div className="price">
                                <div className="price-offer mt-3">
                                    <div className="d-flex flex-row mb-2">
                                        <p className="fw-bold fs-3 m-0 p-0"></p>
                                        <span className="text-light m-0 p-0 fs-6"> </span>
                                    </div>
                                    <span className="price-trial">
                                        {' '}
                                        <span className="underline me-1"></span>
                                    </span>
                                </div>
                                <div className="price-content">
                                    <p className="m-0 p-0 mt-2 mb-1 content-includes fw-bold text-dark">
                                        Includes everything in{' '}
                                        <strong className="text-primary">PRO</strong> plus:
                                    </p>
                                    <ul>
                                        <li>
                                            <i className="ph ph-check me-1"></i>As many ads as you
                                            need
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Multiple brands
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Training for your
                                            team on prompt engineering
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Calibrating Pixii to
                                            your brand documents
                                        </li>
                                        <li>
                                            <i className="ph ph-check me-1"></i>Concierge import of
                                            your product catalog or assets
                                        </li>
                                    </ul>
                                </div>
                                <div className="pt-3 mt-3 pb-0 d-flex flex-column justify-content-end">
                                    <Button
                                        variant="primary"
                                        className="mb-3"
                                        onClick={() => {
                                            window.location.href =
                                                'https://calendly.com/pixiiai/demo?month=2024-01';
                                        }}
                                    >
                                        {' '}
                                        Book a Demo
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between align-items-center border-0 pt-0">
                <p className="fst-italic   text-start text-light" style={{ fontSize: '0.85rem' }}>
                    *Subject to fair use.
                </p>
                <a
                    href="mailto:consult@pixii.ai"
                    className="text-light"
                    style={{ fontSize: '0.85rem', cursor: 'pointer' }}
                >
                    Help
                </a>
            </Modal.Footer>
        </Modal>
    );
};
export default Paywall;
