import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import {
    useDeleteImageFavouritesByIdMutation,
    useGetUserImageFavouritesQuery,
} from '../../BrandMagicRedux/brandMagic.api';
import { Button } from 'react-bootstrap';

const Image = () => {
    const navigate = useNavigate();
    // const [isEditing, setIsEditing] = useState(false); // Track editing state
    const { data } = useGetUserImageFavouritesQuery();
    const [deleteImagePotionsById] = useDeleteImageFavouritesByIdMutation();
    const [imagePotions, setImagePotions] = useState<any[]>([]);
    // const handleClick = () => {
    //     setIsEditing(true); // Enable editing when clicked
    // };
    const handleDelete = async (event: any, index: number, id: number) => {
        event?.preventDefault();
        await deleteImagePotionsById(id)
            .then((res) => {
                console.log(res, 'Potion Deletion Successful');

                setImagePotions([
                    ...imagePotions.slice(0, index),
                    ...imagePotions.slice(index + 1),
                ]);
                // Update the state to trigger a re-render
            })
            .catch((err) => console.log(err, 'Potion Deletion Failed'));
    };
    useEffect(() => {
        if (data) {
            setImagePotions(data);
        }
    }, [data]);
    return (
        <>
            <div className="border-top pt-3">
                <div className="content_header">
                    <div>
                        <h1 className="fw-bold text-primary mb-3">Favorite Prompts</h1>
                    </div>
                </div>
                <Form>
                    {imagePotions.length !== 0 ? (
                        imagePotions.map((potion: any, index: number) => (
                            <div className="image_potions" key={index}>
                                <Form
                                    className=" mb-3 mt-3 imagePotions pb-3 border-bottom border-light"
                                    key={index}
                                >
                                    <Form.Label className="h3 text-capitalize text-primary fw-bold m-0 potion-title">
                                        {potion.potionTitle}
                                    </Form.Label>
                                    <Form.Group className="form mt-3">
                                        <Form.Label className="h4 text-primary fw-bold m-0">
                                            {'Effect'}
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="potion-effect"
                                            rows={1}
                                            readOnly={true}
                                            value={potion.potionEffect}
                                        />
                                    </Form.Group>
                                    <Form.Group className="form mt-3">
                                        <Form.Label className="h4 text-primary fw-bold m-0">
                                            Pixii Potion
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="potion-effect"
                                            rows={1}
                                            readOnly={true}
                                            value={potion.pixiiPotion || 'No style'}
                                        />
                                    </Form.Group>
                                    <Form.Group className="form mt-3">
                                        <Form.Label className="h4 text-primary fw-bold m-0">
                                            Choice of Modal
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="potion-effect"
                                            rows={1}
                                            readOnly={true}
                                            value={potion.modelType}
                                        />
                                    </Form.Group>

                                    <Button
                                        variant="white"
                                        className="iconbtn"
                                        onClick={(event) => handleDelete(event, index, potion.id)}
                                    >
                                        <i className="pxi-trash lg"></i>
                                    </Button>
                                </Form>
                            </div>
                        ))
                    ) : (
                        <div className="text-dark ps-3 pe-3 pt-3 pb-3 mt-3 mb-2 border border-light rounded">
                            <p>Coming Soon...</p>
                        </div>
                    )}
                </Form>
            </div>
        </>
    );
};

export default Image;
