import { memo, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Container, Spinner, Modal, Button } from 'react-bootstrap';
import { useGetScrapingURLQuery } from '../../BrandMagicRedux/brandMagic.api';
import { displayErrorToast } from '../../../../utils/ToastUtils';
import Paywall from './Paywall';
import { useSelector } from 'react-redux';
import Start from '../../../../assets/images/start.png';
import { motion } from 'framer-motion';
import { selectCreateFlow } from '../../../CreateFlow/CreateFlowRedux/selector';
import { useAppSelector } from '../../../../commons/hooks/redux';
interface BrandVoiceState {
    brandName: string;
    keyProductService: string;
    keyMessages: string;
    tone: string;
    scrapedTextContent: string;
    anythingElse: string;
}

type BrandMagicScrapingURLProps = {
    setURLSaved: React.Dispatch<React.SetStateAction<boolean>>;
    saveScrapingURL: any;
    refetchBrandvoice: any;
    handleNext: () => void;
    paddle: any;
    isSavingURL: any;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    successMessage: string;
    setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
    brandVoice?: BrandVoiceState;
};
// const ConfirmationModal = ({ show, handleClose, handleConfirm }) => {
//     return (

//     );
// };
const BrandMagicScrapingURL: React.FC<BrandMagicScrapingURLProps> = ({
    setURLSaved,
    saveScrapingURL,
    refetchBrandvoice,
    handleNext,
    paddle,
    isSavingURL,
    error,
    setError,
    successMessage,
    setSuccessMessage,
    brandVoice,
}) => {
    const { onBoardedUser } = useAppSelector(selectCreateFlow);
    const { data, refetch } = useGetScrapingURLQuery();
    const [value, setValue] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isURLEntered, setIsURLEntered] = useState(false);
    // const [error, setError] = useState(''); // State for error message
    // const [successMessage, setSuccessMessage] = useState('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const subscriptonStatus = useSelector((state: any) => state.paddleFlow.subscribedUser);
    const [openPaywall, setOpenPaywall] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        refetch();
    }, []);

    const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIsEditing(true);
        const val = evt.target?.value;
        setValue(val);
        setError('');
        setSuccessMessage('');
    };

    // handle skip
    const handleSkip = () => {
        setURLSaved(true);
    };

    function ensureHttpInUrl(url: string) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return 'https://' + url;
        }
        return url;
    }
    const checkBrandVoice = async () => {
        const urlValue = isEditing ? value : data?.url;
        if (urlValue === '') {
            setError('Please enter a URL');
            setIsURLEntered(false);
            return;
        }
        if (brandVoice && Object.values(brandVoice).some((value) => value.trim() !== '')) {
            // Display confirmation modal if relevant brandVoice data exists
            setShowConfirmModal(true);
        } else {
            // Proceed to save URL if no relevant brandVoice data is present
            handleSaveURL();
        }
    };

    const handleSaveURL = async () => {
        setError('');
        setSuccessMessage('');
        setIsURLEntered(true);

        const urlValue = isEditing ? value : data?.url;
        if (urlValue === '') {
            setError('Please enter a URL');
            setIsURLEntered(false);
            setShowMessage(true);
            return;
        }
        // Define a regular expression for URL validation
        const urlRegex =
            /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
        if (!urlRegex.test(urlValue)) {
            setIsURLEntered(false);
            setError('Invalid URL');
            setShowMessage(true);
            return;
        }
        const correctedUrl = ensureHttpInUrl(urlValue);
        await saveScrapingURL({ url: correctedUrl?.trim() })
            .unwrap()
            .then(() => {
                refetchBrandvoice();
                setSuccessMessage(
                    'I read your website and learned about your brand. Please revise it as needed, now or later.',
                );
                setShowMessage(true);
                setURLSaved(true);
                setIsURLEntered(false);
            })
            .catch((error: any) => {
                setShowMessage(true);
                setIsURLEntered(false);
                if (error && error.status === 500) {
                    //Error 422 Invalid Data
                    setError(
                        'I could not read your website. Please tell me more about your brand below.',
                    );
                } else {
                    displayErrorToast(error?.data?.message);
                }
            })
            .finally(() => {
                handleNext();
            });
    };
    return (
        <>
            <Paywall
                show={!subscriptonStatus}
                onHide={() => setOpenPaywall(false)}
                paddle={paddle}
            />
            <Container>
                <motion.div
                    className="start"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                >
                    <motion.img
                        src={Start}
                        alt="start illustration"
                        className="start_illustration"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, delay: 0.4 }}
                    />
                    <h1 className="start_header mb-1">What's your website?</h1>
                    <p className="text-light fs-6 mb-2">
                        I'll try to read it and learn about your brand.
                    </p>
                    {isSavingURL ? (
                        <div className="w-100 d-flex justify-content-center flex-column align-items-center mt-3">
                            <Spinner animation="border" style={{ color: '#967AA7' }} />
                            <span className="fst-italic text-primary h4 mt-1">
                                Reading your website...
                            </span>
                        </div>
                    ) : (
                        <div className="w-100 d-flex flex-column align-items-center">
                            <Form className="w-100">
                                <div className="form mt-2">
                                    <Form.Label className="h4 text-primary fw-bold mb-0">
                                        Your URL
                                    </Form.Label>
                                    <Form.Control
                                        as="input"
                                        className="p-3"
                                        id="website_url"
                                        value={isEditing ? value : data?.url ?? ''}
                                        onChange={handleChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                checkBrandVoice();
                                            }
                                        }}
                                        placeholder="Your brand's URL "
                                        autoFocus
                                    />
                                </div>
                            </Form>
                            {error ? (
                                <div className=" d-flex justify-content-start w-100">
                                    <p
                                        className="text-danger fst-italic"
                                        style={{ fontSize: '0.85rem' }}
                                    >
                                        *{error}
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {onBoardedUser ? (
                                <div className="d-flex justify-content-end gap-3 align-items-center w-100 mt-3">
                                    {' '}
                                    <motion.button
                                        onClick={handleNext}
                                        className="btn btn-secondary"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        Skip
                                    </motion.button>
                                    <motion.button
                                        onClick={checkBrandVoice}
                                        disabled={isURLEntered}
                                        className="btn btn-primary"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        Try this <i className="ph ph-arrow-right lg"></i>
                                    </motion.button>
                                    {/* <ConfirmationModal
                                        show={showConfirmModal}
                                        handleClose={() => setShowConfirmModal(false)}
                                        handleConfirm={() => {
                                            setShowConfirmModal(false);
                                            handleSaveURL(); // Proceed with scraping after confirmation
                                        }}
                                    /> */}
                                    <Modal
                                        show={showConfirmModal}
                                        onHide={() => setShowConfirmModal(false)}
                                        centered
                                    >
                                        <Modal.Header
                                            closeButton
                                            className="border-none px-0 py-1 m-2"
                                        >
                                            {/* <Modal.Title>Confirm Action</Modal.Title> */}
                                        </Modal.Header>
                                        <Modal.Body className="text-center">
                                            Are you sure you want to read this website again? <br />
                                            This will <strong>overwrite</strong> any previous info
                                            inputted in the Brand Voice and Design Elements tabs
                                        </Modal.Body>
                                        <Modal.Footer className="border-none px-0 py-1 m-2">
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => {
                                                    setShowConfirmModal(false);
                                                    handleSaveURL();
                                                }}
                                            >
                                                Yes, read my URL
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    setShowConfirmModal(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            ) : (
                                <motion.button
                                    onClick={handleSaveURL}
                                    disabled={isURLEntered}
                                    className="btn btn-primary mt-3"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    Let's Get Started <i className="ph ph-arrow-right lg"></i>
                                </motion.button>
                            )}
                        </div>
                    )}
                </motion.div>
            </Container>
        </>
    );
};

export default memo(BrandMagicScrapingURL);
