import { baseApiSlice } from '../../../commons/base-api.config';
import { API_USER_SUBSCRIBTION_STATUS, API_VERIFY_TRANSACITON } from '../../../constants/api';
import { HTTP_METHODS } from '../../../constants/http';

export const paddlePayWallApiSlice = baseApiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            saveUserSubscription: builder.mutation({
                query: (data) => {
                    return {
                        url: API_VERIFY_TRANSACITON,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            updateUserPaymentStatus: builder.mutation({
                query: (data) => {
                    return {
                        url: API_USER_SUBSCRIBTION_STATUS,
                        method: HTTP_METHODS.PUT,
                        body: data,
                    };
                },
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
        };
    },
});

export const { useSaveUserSubscriptionMutation, useUpdateUserPaymentStatusMutation } =
    paddlePayWallApiSlice;
