import React, { useState, Dispatch, SetStateAction } from 'react';

import { ShowImage } from './ShowUploadedImage';
import { ShowUploadedFile } from './ShowUploadedPdfFile';
import FileUploadSelector from './FileUploadSelector';
import ProductPhotography from './productPhotoGraphy/productPhotography';
import VisionPhotography from './visionPhotoGraphy/visionPhotography';
import { ImageType, productImageProps } from './Interfaces';

interface AttachedFilesProps {
    uploadedFile: File | null;
    setUploadedFile: Dispatch<SetStateAction<File | null>>;
    productPhotographyData: productImageProps;
    visionPhotographyData: productImageProps;
    setProductPhotographyData: Dispatch<SetStateAction<productImageProps>>;
    setVisionPhotographyData: Dispatch<SetStateAction<productImageProps>>;
    handleAttachedFilesClick: () => void;
}

const AttachedFiles: React.FC<AttachedFilesProps> = ({
    uploadedFile,
    setUploadedFile,
    productPhotographyData,
    setProductPhotographyData,
    visionPhotographyData,
    setVisionPhotographyData,
    handleAttachedFilesClick,
}) => {
    const [showProductPhotgrahy, setShowProductPhotograhy] = useState<boolean>(false);
    const [showVisionPhotgrahy, setShowVisionPhotgrahy] = useState<boolean>(false);

    const handleRemoveImage = () => {
        setProductPhotographyData({
            isImageDataPresent: false,
            imageData: {
                imageType: ImageType.NONE,
                data: '',
                id: '',
            },
        });
        setVisionPhotographyData({
            isImageDataPresent: false,
            imageData: {
                imageType: ImageType.NONE,
                data: '',
                id: '',
            },
        });
    };
    const handleRemoveDoc = () => {
        setUploadedFile(null);
    };

    const handleImageIconClick = async () => {
        setShowProductPhotograhy(true);
    };
    const handleVisionIconClick = async () => {
        setShowVisionPhotgrahy(true);
    };

    const handleUploadClick = () => {
        setShowProductPhotograhy(false);
        setShowVisionPhotgrahy(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setUploadedFile(file);
        }
    };

    const show_product_image = productPhotographyData.isImageDataPresent;
    const show_vision_image = visionPhotographyData.isImageDataPresent;

    const getImageToShow = () => {
        if (productPhotographyData.isImageDataPresent || productPhotographyData.imageData.data) {
            return productPhotographyData;
        } else {
            return visionPhotographyData;
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center">
            <ShowImage
                productImage={getImageToShow()}
                visionImage={visionPhotographyData}
                handleRemoveImage={handleRemoveImage}
            />
            <ShowUploadedFile uploadedFile={uploadedFile} handleRemoveDoc={handleRemoveDoc} />
            <FileUploadSelector
                show={!(show_product_image || uploadedFile || show_vision_image)}
                handleFileChange={handleFileChange}
                handleImageIconClick={handleImageIconClick}
                handleVisionIconClick={handleVisionIconClick}
                handleAttachedFilesClick={handleAttachedFilesClick}
            />
            <ProductPhotography
                productPhotographyData={productPhotographyData}
                setProductImageData={setProductPhotographyData}
                show={showProductPhotgrahy}
                handleShowModal={setShowProductPhotograhy}
                handleUploadClick={handleUploadClick}
            />
            <VisionPhotography
                visionPhotographyData={visionPhotographyData}
                setVisionPhotographyData={setVisionPhotographyData}
                show={showVisionPhotgrahy}
                handleShowModal={setShowVisionPhotgrahy}
                handleUploadClick={handleUploadClick}
            />
        </div>
    );
};

export default AttachedFiles;
