import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    subscribedUser: false,
};
export const paddlePayWallSlice = createSlice({
    name: 'paddleFlow',
    initialState,
    reducers: {
        setUserSubscriptionStats: (state, action) => {
            state.subscribedUser = action.payload;
        },
    },
});

export const { setUserSubscriptionStats } = paddlePayWallSlice.actions;

export default paddlePayWallSlice.reducer;
