import React, { useEffect, useRef, useState } from 'react';
import {
    BrandElementsFontsClass,
    IBrandElementFont,
    IGetBrandElements,
} from '../../BrandMagicRedux/BrandMagicTypes';
import {
    useAddBrandElementsFontMutation,
    useRemoveBrandElementsFontMutation,
} from '../../BrandMagicRedux/brandMagic.api';
import { Row } from 'react-bootstrap';
import DesignElementsFontClass from './DesignElementsFontClass';
import { getGoogleFontsListAPI } from 'polotno/config';
import { allowedExtensionsCustomFonts } from '../../../../constants/createFlow';
import { displayErrorToast } from '../../../../utils/ToastUtils';

interface IProps {
    brandElements: IGetBrandElements | undefined;
    brandElementsLoading: boolean;
}

const DesignElementsFonts: React.FC<IProps> = ({ brandElements, brandElementsLoading }) => {
    const [uploadingFontClass, setUploadingFontClass] = useState<BrandElementsFontsClass | null>(
        null,
    );
    const [deletingFontClass, setDeletingFontClass] = useState<BrandElementsFontsClass | null>(
        null,
    );
    const [deletingFontFamily, setDeletingFontFamily] = useState<string | null>(null);
    const [uploadFont, { isLoading: addFontLoading }] = useAddBrandElementsFontMutation();
    const [deleteFont, { isLoading: removeFontLoading }] = useRemoveBrandElementsFontMutation();
    const [headerFonts, setHeaderFonts] = useState<IBrandElementFont[]>([]);
    const [subHeaderFonts, setSubHeaderFonts] = useState<IBrandElementFont[]>([]);
    const [bodyFonts, setBodyFonts] = useState<IBrandElementFont[]>([]);
    const [accentFonts, setAccentFonts] = useState<IBrandElementFont[]>([]);
    const headerFontInputRef = useRef<HTMLInputElement>(null);
    const subHeaderFontInputRef = useRef<HTMLInputElement>(null);
    const bodyFontInputRef = useRef<HTMLInputElement>(null);
    const accentFontInputRef = useRef<HTMLInputElement>(null);
    const [googleFonts, setGoogleFonts] = useState<string[]>([]);

    // handle font file upload
    const handleUploadFontFile = async (
        event: React.ChangeEvent<HTMLInputElement>,
        fontClass: BrandElementsFontsClass,
    ) => {
        const fileInput = event.target;
        const fileList = fileInput.files;
        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            const fontFamily = file?.name.split('.')[0];
            const fileExtension = file?.name.split('.').pop()?.toLowerCase();
            // Check file extension
            if (!allowedExtensionsCustomFonts.includes(fileExtension as string)) {
                displayErrorToast('Only ttf, otf, woff, and woff2 files are allowed.');
                return;
            }
            await uploadFont({ fontFile: file, fontClass, fontFamily }).then(() => {
                setUploadingFontClass(null);
            });
        }
    };

    const handleUploadFont = async (fontFamily: string, fontClass: BrandElementsFontsClass) => {
        if (fontFamily.length > 0) {
            await uploadFont({ fontClass, fontFamily });
        }
    };

    // handle delete font
    const handleDeleteFont = async (fontFamily: string, fontClass: BrandElementsFontsClass) => {
        setDeletingFontClass(fontClass);
        setDeletingFontFamily(fontFamily);
        await deleteFont({ fontClass, fontFamily }).then(() => {
            setDeletingFontClass(null);
            setDeletingFontFamily(null);
        });
    };

    useEffect(() => {
        if (brandElements) {
            const fonts = brandElements.fonts;

            if (
                fonts &&
                fonts.hasOwnProperty(BrandElementsFontsClass.HEADER) &&
                fonts[BrandElementsFontsClass.HEADER]
            ) {
                setHeaderFonts(fonts[BrandElementsFontsClass.HEADER]);
            }
            if (
                fonts &&
                fonts.hasOwnProperty(BrandElementsFontsClass.SUB_HEADER) &&
                fonts[BrandElementsFontsClass.SUB_HEADER]
            ) {
                setSubHeaderFonts(fonts[BrandElementsFontsClass.SUB_HEADER]);
            }
            if (
                fonts &&
                fonts.hasOwnProperty(BrandElementsFontsClass.BODY) &&
                fonts[BrandElementsFontsClass.BODY]
            ) {
                setBodyFonts(fonts[BrandElementsFontsClass.BODY]);
            }
            if (
                fonts &&
                fonts.hasOwnProperty(BrandElementsFontsClass.ACCENT) &&
                fonts[BrandElementsFontsClass.ACCENT]
            ) {
                setAccentFonts(fonts[BrandElementsFontsClass.ACCENT]);
            }
        }
    }, [brandElements]);

    useEffect(() => {
        const fontListURL = getGoogleFontsListAPI();

        fetch(fontListURL + `${process.env.REACT_APP_POLOTNO_API_KEY}`)
            .then((response) => response.json())
            .then((data) => {
                setGoogleFonts(data);
            })
            .catch((error) => {
                console.error('Error fetching font list:', error);
            });
    }, []);

    return (
        <div className="fonts designElements mb-4 p-3">
            <h1 className="fw-bold text-primary mb-3">Fonts</h1>

            {/* {brandElementsLoading ? (
                <div className="SpinnerDiv">
                    <Spinner
                        animation="border"
                        style={{ marginTop: '40px' }}
                        className="custom-spinner"
                    />
                </div>
            ) : (
                <div className="d-flex justify-content-between font">
                    <DesignElementsFontClass
                        fontClassTitle="Header"
                        fontClass={BrandElementsFontsClass.HEADER}
                        fontInputRef={headerFontInputRef}
                        addFontLoading={addFontLoading}
                        removeFontLoading={removeFontLoading}
                        uploadingFontClass={uploadingFontClass}
                        deletingFontClass={deletingFontClass}
                        deletingFontFamily={deletingFontFamily}
                        uploadedFonts={headerFonts}
                        googleFonts={googleFonts}
                        setUploadingFontClass={setUploadingFontClass}
                        handleUploadFont={handleUploadFont}
                        handleDeleteFont={handleDeleteFont}
                        handleUploadFontFile={handleUploadFontFile}
                    />

                    <DesignElementsFontClass
                        fontClassTitle="Sub-Header"
                        fontClass={BrandElementsFontsClass.SUB_HEADER}
                        fontInputRef={subHeaderFontInputRef}
                        addFontLoading={addFontLoading}
                        removeFontLoading={removeFontLoading}
                        uploadingFontClass={uploadingFontClass}
                        deletingFontClass={deletingFontClass}
                        deletingFontFamily={deletingFontFamily}
                        uploadedFonts={subHeaderFonts}
                        googleFonts={googleFonts}
                        setUploadingFontClass={setUploadingFontClass}
                        handleUploadFont={handleUploadFont}
                        handleDeleteFont={handleDeleteFont}
                        handleUploadFontFile={handleUploadFontFile}
                    />

                    <DesignElementsFontClass
                        fontClassTitle="Body"
                        fontClass={BrandElementsFontsClass.BODY}
                        fontInputRef={bodyFontInputRef}
                        addFontLoading={addFontLoading}
                        removeFontLoading={removeFontLoading}
                        uploadingFontClass={uploadingFontClass}
                        deletingFontClass={deletingFontClass}
                        deletingFontFamily={deletingFontFamily}
                        uploadedFonts={bodyFonts}
                        googleFonts={googleFonts}
                        setUploadingFontClass={setUploadingFontClass}
                        handleUploadFont={handleUploadFont}
                        handleDeleteFont={handleDeleteFont}
                        handleUploadFontFile={handleUploadFontFile}
                    />

                    <DesignElementsFontClass
                        fontClassTitle="Accent"
                        fontClass={BrandElementsFontsClass.ACCENT}
                        fontInputRef={accentFontInputRef}
                        addFontLoading={addFontLoading}
                        removeFontLoading={removeFontLoading}
                        uploadingFontClass={uploadingFontClass}
                        deletingFontClass={deletingFontClass}
                        deletingFontFamily={deletingFontFamily}
                        uploadedFonts={accentFonts}
                        googleFonts={googleFonts}
                        setUploadingFontClass={setUploadingFontClass}
                        handleUploadFont={handleUploadFont}
                        handleDeleteFont={handleDeleteFont}
                        handleUploadFontFile={handleUploadFontFile}
                    />
                </div>
            )} */}
            <Row>
                <DesignElementsFontClass
                    fontClassTitle="Header"
                    fontClass={BrandElementsFontsClass.HEADER}
                    fontInputRef={headerFontInputRef}
                    addFontLoading={addFontLoading}
                    removeFontLoading={removeFontLoading}
                    uploadingFontClass={uploadingFontClass}
                    deletingFontClass={deletingFontClass}
                    deletingFontFamily={deletingFontFamily}
                    uploadedFonts={headerFonts}
                    googleFonts={googleFonts}
                    setUploadingFontClass={setUploadingFontClass}
                    handleUploadFont={handleUploadFont}
                    handleDeleteFont={handleDeleteFont}
                    handleUploadFontFile={handleUploadFontFile}
                />

                <DesignElementsFontClass
                    fontClassTitle="Sub-Header"
                    fontClass={BrandElementsFontsClass.SUB_HEADER}
                    fontInputRef={subHeaderFontInputRef}
                    addFontLoading={addFontLoading}
                    removeFontLoading={removeFontLoading}
                    uploadingFontClass={uploadingFontClass}
                    deletingFontClass={deletingFontClass}
                    deletingFontFamily={deletingFontFamily}
                    uploadedFonts={subHeaderFonts}
                    googleFonts={googleFonts}
                    setUploadingFontClass={setUploadingFontClass}
                    handleUploadFont={handleUploadFont}
                    handleDeleteFont={handleDeleteFont}
                    handleUploadFontFile={handleUploadFontFile}
                />

                <DesignElementsFontClass
                    fontClassTitle="Body"
                    fontClass={BrandElementsFontsClass.BODY}
                    fontInputRef={bodyFontInputRef}
                    addFontLoading={addFontLoading}
                    removeFontLoading={removeFontLoading}
                    uploadingFontClass={uploadingFontClass}
                    deletingFontClass={deletingFontClass}
                    deletingFontFamily={deletingFontFamily}
                    uploadedFonts={bodyFonts}
                    googleFonts={googleFonts}
                    setUploadingFontClass={setUploadingFontClass}
                    handleUploadFont={handleUploadFont}
                    handleDeleteFont={handleDeleteFont}
                    handleUploadFontFile={handleUploadFontFile}
                />

                <DesignElementsFontClass
                    fontClassTitle="Accent"
                    fontClass={BrandElementsFontsClass.ACCENT}
                    fontInputRef={accentFontInputRef}
                    addFontLoading={addFontLoading}
                    removeFontLoading={removeFontLoading}
                    uploadingFontClass={uploadingFontClass}
                    deletingFontClass={deletingFontClass}
                    deletingFontFamily={deletingFontFamily}
                    uploadedFonts={accentFonts}
                    googleFonts={googleFonts}
                    setUploadingFontClass={setUploadingFontClass}
                    handleUploadFont={handleUploadFont}
                    handleDeleteFont={handleDeleteFont}
                    handleUploadFontFile={handleUploadFontFile}
                />
            </Row>
        </div>
    );
};

export default DesignElementsFonts;
