import React from 'react';
import { InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';

interface CustomNavProfileProps {
    show: boolean;
}
const CustomNavProfile: React.FC<CustomNavProfileProps> = ({ show }) => {
    if (!show) return null;
    return (
        <div className="custom_nav-profile position-fixed bottom-0 end-0 m-3 d-none d-lg-block">
            <InputGroup className="custom-dropdown">
                <DropdownButton
                    align="end"
                    title={<i className="ph ph-question lg"></i>}
                    id="dropdown-menu-align-end"
                    className="dropdown__toggle"
                >
                    <Dropdown.Item
                        href="https://www.youtube.com/watch?v=Zy8gcS27PMc"
                        target="_blank"
                    >
                        <i className="ph ph-monitor-play"></i> Watch tutorial video{' '}
                    </Dropdown.Item>
                    <Dropdown.Item href="mailto:monte@pixii.ai,risa@pixii.ai">
                        <i className="ph ph-envelope-simple"></i> Get help or Share feedback{' '}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={(e) => {
                            window.location.href = '/faq';
                            e.preventDefault();
                        }}
                    >
                        <i className="ph ph-arrow-square-out"></i>FAQ{' '}
                    </Dropdown.Item>
                </DropdownButton>
            </InputGroup>
        </div>
    );
};

export default CustomNavProfile;
