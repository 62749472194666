import { useEffect } from 'react';

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
    useEffect(() => {
        if (textAreaRef) {
            const minTextAreaHeight = 56;
            const maxTextAreaHeight = 155;

            textAreaRef.style.height = `${minTextAreaHeight}px`;
            const scrollHeight = textAreaRef.scrollHeight;

            if (!value) {
                textAreaRef.style.height = `${minTextAreaHeight}px`;
                textAreaRef.style.overflowY = 'hidden';
            } else {
                const newHeight = Math.min(scrollHeight, maxTextAreaHeight);
                textAreaRef.style.height = `${newHeight}px`;
                textAreaRef.style.overflowY = 'auto';
                textAreaRef.style.overflowY = newHeight === maxTextAreaHeight ? 'auto' : 'hidden';
            }
        }
    }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
