import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { useUpdateOnboardingUserMutation } from '../../CreateFlow/CreateFlowRedux/createFlow.api';
import { useAppDispatch, useAppSelector } from '../../../commons/hooks/redux';
import { selectCreateFlow } from '../../CreateFlow/CreateFlowRedux/selector';
import { setOnBoardedUser } from '../../CreateFlow/CreateFlowRedux/createFlowSlice';
import { displayErrorToast } from '../../../utils/ToastUtils';
import { useGetBrandElementsQuery } from '../BrandMagicRedux/brandMagic.api';
import DesignElementsFonts from './Component/DesignElementsFonts';
import ColorPicker from './Component/ColorPicker';
import DesignElementsLogo from './Component/DesignElementsLogo';

type DesignElementsProps = {
    handleNext: () => void;
    handlePrev: () => void;
    paddle: any;
};

const DesignElements: React.FC<DesignElementsProps> = ({ handleNext, handlePrev, paddle }) => {
    const { onBoardedUser } = useAppSelector(selectCreateFlow);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [updateOnboardingUser, { isLoading }] = useUpdateOnboardingUserMutation();
    const {
        data: brandElementsData,
        isLoading: brandElementsLoading,
        refetch,
    } = useGetBrandElementsQuery();

    useEffect(() => {
        refetch();
    }, []);

    const handleFinish = async () => {
        try {
            const data = await updateOnboardingUser({}).unwrap();
            dispatch(setOnBoardedUser(true));
            navigate('/');
        } catch {
            displayErrorToast('Failed to setup please try again!');
        }
    };
    return (
        <div>
            <Row>
                {/* <div className="content-design">
                    <Col lg={8} className="brandElements">
                        <ColorPicker
                            brandElements={brandElementsData}
                            brandElementsLoading={brandElementsLoading}
                        />
                        <Row className="imageUpload m-0">
                            <Col>
                                <DesignElementsAssets />
                            </Col>
                            <Col>
                                <DesignElementsLogo
                                    brandElements={brandElementsData}
                                    brandElementsLoading={brandElementsLoading}
                                />
                            </Col>
                            <DesignElementsLogo
                                brandElements={brandElementsData}
                                brandElementsLoading={brandElementsLoading}
                            />
                        </Row>

                        <DesignElementsProductPhoto />
                    </Col>
                    <Col lg={4}>
                        <DesignElementsFonts
                            brandElements={brandElementsData}
                            brandElementsLoading={brandElementsLoading}
                        />
                    </Col>
                </div> */}
                <div className="brandMagic_content">
                    <DesignElementsFonts
                        brandElements={brandElementsData}
                        brandElementsLoading={brandElementsLoading}
                    />
                    <ColorPicker
                        brandElements={brandElementsData}
                        brandElementsLoading={brandElementsLoading}
                    />
                    <DesignElementsLogo
                        brandElements={brandElementsData}
                        brandElementsLoading={brandElementsLoading}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3 brandMagic_buttons">
                    <Button onClick={handlePrev} variant="secondary" size="lg">
                        Back
                    </Button>
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => {
                            handleNext();
                        }}
                    >
                        Next
                    </Button>
                </div>
            </Row>
        </div>
    );
};

export default DesignElements;
