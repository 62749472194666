import React, { useEffect, useRef, useState } from 'react';
import { BrandElementsFontsClass, IBrandElementFont } from '../../BrandMagicRedux/BrandMagicTypes';

import { getGoogleFontImage } from 'polotno/config';
import { Button, Card, Col, Dropdown, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
interface IProps {
    fontClassTitle: string;
    fontClass: BrandElementsFontsClass;
    fontInputRef: React.RefObject<HTMLInputElement>;
    addFontLoading: boolean;
    removeFontLoading: boolean;
    uploadingFontClass: BrandElementsFontsClass | null;
    deletingFontClass: BrandElementsFontsClass | null;
    deletingFontFamily: string | null;
    uploadedFonts: IBrandElementFont[];
    googleFonts: string[];
    setUploadingFontClass: React.Dispatch<React.SetStateAction<BrandElementsFontsClass | null>>;
    handleUploadFont: (fontFamily: string, fontClass: BrandElementsFontsClass) => Promise<void>;
    handleDeleteFont: (fontFamily: string, fontClass: BrandElementsFontsClass) => Promise<void>;
    handleUploadFontFile: (
        event: React.ChangeEvent<HTMLInputElement>,
        fontClass: BrandElementsFontsClass,
    ) => Promise<void>;
}

const DesignElementsFontClass: React.FC<IProps> = ({
    fontClassTitle,
    fontClass,
    fontInputRef,
    addFontLoading,
    removeFontLoading,
    uploadingFontClass,
    deletingFontClass,
    deletingFontFamily,
    uploadedFonts,
    googleFonts,
    setUploadingFontClass,
    handleUploadFont,
    handleDeleteFont,
    handleUploadFontFile,
}) => {
    const [filteredGoogleFonts, setFilteredGoogleFonts] = useState<string[]>([]);
    const [showFontsSuggestion, setShowFontsSuggestion] = useState(false);
    const fontsSuggestionContainerRef = useRef<HTMLDivElement>(null);
    const fontFileInputRef = useRef<HTMLInputElement>(null);

    const handleFontInputChange = () => {
        if (fontInputRef && fontInputRef.current) {
            const fontCurrentValue = fontInputRef.current.value;

            if (fontCurrentValue.length > 0) {
                const filteredFonts = googleFonts.filter((font) =>
                    font.toLocaleLowerCase().startsWith(fontCurrentValue.toLocaleLowerCase()),
                );
                setFilteredGoogleFonts(filteredFonts);
                setShowFontsSuggestion(true);
            } else {
                setShowFontsSuggestion(false);
            }
        } else setShowFontsSuggestion(false);
    };

    const handleFontFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowFontsSuggestion(false);
        setUploadingFontClass(fontClass);
        handleUploadFontFile(event, fontClass);
    };

    const handleUploadFontFileClick = () => {
        if (fontFileInputRef && fontFileInputRef.current) {
            fontFileInputRef.current.click();
        }
    };

    const uploadGoogleFont = (fontFamily: string) => {
        setShowFontsSuggestion(false);
        setUploadingFontClass(fontClass);
        handleUploadFont(fontFamily, fontClass);
    };

    useEffect(() => {
        // Function for click event
        function handleOutsideClick(event: MouseEvent) {
            if (
                fontsSuggestionContainerRef &&
                fontsSuggestionContainerRef.current &&
                !fontsSuggestionContainerRef.current.contains(event.target as Node)
            ) {
                setShowFontsSuggestion(false);
            }
        }

        // Adding click event listener
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [fontsSuggestionContainerRef]);

    return (
        <Col>
            <input
                ref={fontFileInputRef}
                type="file"
                onChange={handleFontFileInputChange}
                hidden={true}
            />

            {/* Add font input */}
            <div className="font-type">
                <h3 className="p-0 m-0 mb-2 mt-2 text-primary">{fontClassTitle}</h3>
                <div className="font-upload-container" style={{ maxWidth: '280px' }}>
                    <div className="font-input-suggestion-container ">
                        <InputGroup className="custom-dropdown">
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="outline-primary"
                                    className="dropdown__toggle d-flex justify-content-between w-100"
                                    id="dropdown-autoclose-inside"
                                    // style={{ width: '14vw' }}
                                >
                                    {addFontLoading && uploadingFontClass === fontClass ? (
                                        <div className=" flex-grow-1">
                                            <Spinner
                                                animation="border"
                                                role="status"
                                                style={{
                                                    width: '1.5rem',
                                                    height: '1.5rem',
                                                    color: '#F2E6F2',
                                                }}
                                            >
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <span className="h4 m-1 flex-grow-1 text-start">
                                            {uploadedFonts && uploadedFonts.length > 0 ? (
                                                <div className="d-flex justify-content-between">
                                                    {' '}
                                                    <p
                                                        className="text-truncate"
                                                        style={{
                                                            width: '200px',
                                                            maxWidth: '250px',
                                                        }}
                                                    >
                                                        {uploadedFonts[0].fontFamily}
                                                    </p>
                                                    <i className="ph ph-caret-down p-0 m-0 flex-shrink-0"></i>
                                                </div>
                                            ) : (
                                                <div className="d-flex justify-content-between">
                                                    {' '}
                                                    <p>Select font</p>
                                                    <i className="ph ph-caret-down p-0 m-0 flex-shrink-0"></i>
                                                </div>
                                            )}
                                        </span>
                                    )}
                                    {/* Display selected font or class title */}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown__toggle--menu  mt-0 w-100">
                                    <div className="border-bottom border-primary pb-2">
                                        <Form.Control
                                            placeholder={'\u{1F50E} Search Font'}
                                            ref={fontInputRef}
                                            className="font-family-input"
                                            onChange={handleFontInputChange}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                    <div className="border-bottom border-primary mb-2 position-relative">
                                        {/* FOR CUSTOM UPLOAD */}
                                        <div className=" mb-2">
                                            <Dropdown.Item className="  pt-0 pb-0">
                                                <Form.Group controlId="formFile" className="w-100">
                                                    <Form.Label className="d-flex align-items-center justify-content-between w-100 mt-2">
                                                        <div
                                                            style={{ fontSize: '0.85rem' }}
                                                            className="d-flex align-items-center justify-content-between pt-2"
                                                        >
                                                            <i
                                                                className="ph  ph-upload-simple pe-2"
                                                                style={{ fontSize: '1.05rem' }}
                                                            ></i>{' '}
                                                            Upload Your Font
                                                        </div>
                                                        <div>
                                                            <i className="ph ph-plus"></i>
                                                        </div>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        className="position-absolute top-0 start-0"
                                                        style={{
                                                            opacity: 0,
                                                            width: '100%',
                                                            height: 'auto',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={handleFontFileInputChange}
                                                    />
                                                </Form.Group>
                                            </Dropdown.Item>
                                        </div>
                                        {/* SHOWCASE UPLOADED FONT FOR LATER USE*/}
                                        {/* <h5 className="p-1 ps-2 mb-0 text-light">Your fonts</h5>
                                            <Dropdown.Item className="h4 d-flex align-items-center justify-content-between">
                                                <div style={{ fontSize: '0.85rem' }}>
                                                    CabinetGrotesk-Regular
                                                </div>
                                                <Button className="iconbtn">
                                                    <i
                                                        className="ph ph-trash"
                                                        style={{ fontSize: '1.05rem' }}
                                                    ></i>
                                                </Button>
                                            </Dropdown.Item> */}
                                    </div>

                                    {googleFonts.length > 0 && !showFontsSuggestion && (
                                        <>
                                            {googleFonts.map((filteredGoogleFont, index) => {
                                                return (
                                                    <Dropdown.Item
                                                        className="w-100"
                                                        key={index}
                                                        style={{
                                                            fontFamily: filteredGoogleFont,
                                                        }}
                                                        onClick={() =>
                                                            uploadGoogleFont(filteredGoogleFont)
                                                        }
                                                    >
                                                        <img
                                                            src={getGoogleFontImage(
                                                                filteredGoogleFont,
                                                            )}
                                                            style={{
                                                                height: '20px',
                                                            }}
                                                        />
                                                    </Dropdown.Item>
                                                );
                                            })}
                                        </>
                                    )}
                                    {showFontsSuggestion && (
                                        <div
                                            className="font-suggestion-container"
                                            ref={fontsSuggestionContainerRef}
                                        >
                                            {filteredGoogleFonts.length > 0 ? (
                                                <>
                                                    {filteredGoogleFonts.map(
                                                        (filteredGoogleFont, index) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    className="w-100"
                                                                    key={index}
                                                                    style={{
                                                                        fontFamily:
                                                                            filteredGoogleFont,
                                                                    }}
                                                                    onClick={() =>
                                                                        uploadGoogleFont(
                                                                            filteredGoogleFont,
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        src={getGoogleFontImage(
                                                                            filteredGoogleFont,
                                                                        )}
                                                                        style={{
                                                                            height: '20px',
                                                                        }}
                                                                    />
                                                                </Dropdown.Item>
                                                            );
                                                        },
                                                    )}
                                                </>
                                            ) : (
                                                <p className="p-2">No Font Found</p>
                                            )}
                                        </div>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </InputGroup>
                    </div>
                    {/* {addFontLoading && uploadingFontClass === fontClass && (
                        <div className="skeleton-loader d-flex align-items-center m-0 p-0 mt-3">
                            <div className="skeleton-line"></div>
                        </div>
                    )} */}
                </div>
            </div>

            {/* UPLOADED FONTS */}
            {/* {uploadedFonts.length > 0 &&
                uploadedFonts.map((font, index) => {
                    return (
                        <div
                            className="font-uploaded d-flex justify-content-between align-items-center mt-2 mb-2"
                            key={index}
                        >
                            <p className="font-name">{font.fontFamily}</p>
                            <Button
                                variant="white"
                                className="iconbtn"
                                onClick={() => handleDeleteFont(font.fontFamily, fontClass)}
                            >
                                {removeFontLoading &&
                                deletingFontClass === fontClass &&
                                deletingFontFamily === font.fontFamily ? (
                                    <Spinner
                                        animation="grow"
                                        style={{ width: '1.5rem', height: '1.5rem' }}
                                    />
                                ) : (
                                    <i className="pxi-trash lg"></i>
                                )}
                            </Button>
                        </div>
                    );
                })} */}
        </Col>
    );
};

export default DesignElementsFontClass;
