import React, { useState } from 'react';
import TextPromptArea from './TextPromptArea';
import GeneratedText from './GeneratedText';

interface TextContainerProps {
    store: any;
}

export interface PrefillData {
    command: Model;
    customerSegments: string[];
    prompt: string;
    potions: string | null;
    brandVoice: string;
}

export type Model = 'HEADLINE' | 'CAPTION';

export default function TextContainer({ store }: TextContainerProps) {
    const [inputValue, setInputValue] = useState('');
    const [sendTextData, setSendTextData] = useState(false);
    const [imagePrompt, setImagePrompt] = useState('');
    const [activePotion, setActivePotion] = useState<null | string>();
    const [selectedUserFavourite, setSelectedUserFavourite] = useState<null | string>();
    // const [modelType, setModelType] = useState<Model>('QUALITY FAST');

    const handleUserReuseSelect = (data: PrefillData, resuse?: boolean) => {
        const { prompt, command, customerSegments, potions, brandVoice } = data;
        setInputValue(changePromptToPromptSelection(prompt));
        // setModelType(modelType);
        // setActivePotion(pixiiPotion);
        // if (resuse) {
        //     setSelectedUserFavourite(null);
        // } else {
        //     setSelectedUserFavourite(potionTitle);
        // }
    };
    function changePromptToPromptSelection(str: string) {
        // Define regular expressions for matching patterns
        const headlineRegex = /\/Headline/g;
        const captionRegex = /\/Caption/g;
        const atRegex = /(?<!@\[)@(\w+)/g;
        const potionRegx = /~[\w-]+/g;
        // Replace /Headline with @[/Headline ](Headline)
        str = str.replace(headlineRegex, `@[/Headline ](Headline)`);

        // Replace /Caption with @[/Caption ](Caption)
        str = str.replace(captionRegex, `@[/Caption ](Caption)`);

        const atTitles = str.match(atRegex);
        // Replace @ with @[${atTitles[0]}](0)
        if (atTitles) {
            str = str.replace(atRegex, `@[${atTitles[0]}](0)`);
        }
        // Modify @[~${potion.id} ](${potion.id})
        const potionTitles = str.match(potionRegx);
        if (potionTitles) {
            str = str.replace(potionRegx, `@[${potionTitles[0]}](0)`);
        }

        return str;
    }

    const handleSendTextData = () => {
        setSendTextData(true);
    };
    return (
        <div className="generate">
            <div className="userPrompt">
                <TextPromptArea
                    handleSendTextData={handleSendTextData}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                />
                <GeneratedText
                    store={store}
                    sendTextData={sendTextData}
                    setSendTextData={setSendTextData}
                    setInputValue={setInputValue}
                    handleUserReuseSelect={handleUserReuseSelect}
                />
            </div>
        </div>
    );
}
