import React, { useState, memo, useEffect, useRef } from 'react';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import {
    unstable_setAnimationsEnabled,
    unstable_setTextVerticalResizeEnabled,
    unstable_setTextOverflow,
    setColorsPresetFunc,
    getGoogleFontsListAPI,
    setGoogleFonts,
} from 'polotno/config';
import { saveAs } from 'file-saver';
// just to show what we have
import {
    TextSection,
    PhotosSection,
    ElementsSection,
    SizeSection,
    LayersSection,
    BackgroundSection,
} from 'polotno/side-panel/side-panel';
import { SectionTab } from 'polotno/side-panel/tab-button';
import { observer } from 'mobx-react-lite';
import { Button, Spinner, Tab, Form } from 'react-bootstrap';
import CustomUpload from './CustomUpload';
import { PageControls } from './PolotnoPageControls';
import {
    useGetFavouritesResultsQuery,
    useLazyGetFlagForOnboardedUserQuery,
} from '../CreateFlow/CreateFlowRedux/createFlow.api';
import CustomVariants from './CustomVariants';
import CustomProject from './CustomProject';
import createStore from 'polotno/model/store';
import { useAppDispatch, useAppSelector } from '../../commons/hooks/redux';
import {
    callSaveApi,
    setActiveUserEmailId,
    updateLoadingState,
    updateProject,
    updateIsBlankCanvasNewproject,
    createNewProject,
} from './EditorRedux/editorSlice';
import { useCreateProjectMutation, useGetCurrentProjectQuery } from './EditorRedux/editor.api';
import { useSelector } from 'react-redux';
import { useGetBrandElementsQuery } from '../BrandMagic/BrandMagicRedux/brandMagic.api';
import { PolotnoDownloadButton } from './PolotnoDownloadButton';
import { PolotnoQuickSaveButton } from './PolotnoQuickSaveButton';
import Generate from './Generate';
import config from '../../config';
import { selectEditFlow } from './EditorRedux/selector';
import { useGetTemplateJSONMutation } from '../Apprentice/ApprenticeRedux/apprentice.api';

import template from '../../assets/intagram606.json';
import Paywall from '../BrandMagic/BrandMagicPages/Component/Paywall';
import { useNavigate } from 'react-router-dom';
import { setOnBoardedUser, setUserData } from '../CreateFlow/CreateFlowRedux/createFlowSlice';
import { setUserSubscriptionStats } from '../PaddlePayWall/PaddlePayWallRedux/paddlePayWallSlice';
import { findExtraTemplateFields } from '../../utils/TemplateUtils';
import { setApprenticeTemplate, setExtraTemplateFields } from './EditorRedux/templateSlice';

const createChildren = (store, childrenIds, id, text) => {
    store.activePage.clone();
    const newPage = store.activePage;
    newPage.children.forEach((child, i) => {
        if (childrenIds[i] === id) {
            child.set({ prevId: childrenIds[i], text });
        } else {
            child.set({ prevId: childrenIds[i] });
        }
    });
};

// PALLETE SECTION
const PaletteSection = {
    name: 'Palette',
    Tab: (props) => (
        <SectionTab name="Palette" {...props}>
            <div>
                <i className="ph ph-palette lg"></i>
            </div>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({ store }) => {
        // const [textT, setText] = useState("");
        const Text = (text) => {
            // setText(text);
            return text;
        };
        // console.log({ fn: Text(), textT }, "helo ets");
        const addText = (attrs) => {
            const x = attrs?.x;
            const y = attrs?.y;

            store.activePage?.addElement({
                type: 'text',
                fontFamily: 'Roboto',
                ...attrs,
                x,
                y,
            });
        };
        const { data, isLoading } = useGetFavouritesResultsQuery();

        return (
            <div>
                <Tab.Container id="left-tabs" defaultActiveKey="adv">
                    {/* Editor Nav */}
                    {/* Editor content */}
                    <div className="left-tab_contents">
                        <Tab.Content>
                            <Tab.Pane eventKey="adv">
                                <div className="left-tab_content">
                                    {/* <CustomTabPolotono Text={Text} store={store} /> */}
                                    <h4>Generated Images</h4>

                                    {isLoading ? (
                                        <Spinner
                                            animation="border"
                                            style={{ marginTop: '40px' }}
                                            className="custom-spinner"
                                        />
                                    ) : (
                                        // <CustomImagePallett store={store} savedImages={data} />
                                        // <CustomCreate store={store} savedImages={data}  />
                                        <Generate store={store} />
                                    )}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        );
    }),
};

// VARIANTS SECTION
const VariantsSection = {
    name: 'variants',
    Tab: (props) => (
        <SectionTab name="Variants" {...props}>
            <i className="ph-fill ph-circles-three-plus lg"></i>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({ store }) => {
        return <CustomVariants store={store} createChildren={createChildren} />;
    }),
};
// UPLOAD SECTION
const UploadPanel = observer(({ store }) => {
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CustomUpload store={store} />
        </div>
    );
});
//UPLOAD PANEL
const CustomUploadPanel = {
    name: 'Assets',
    Tab: (props) => (
        <SectionTab name="Assets" {...props}>
            <i className="ph ph-file-image lg"></i>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: UploadPanel,
};
// CAPTIONS SECTION
const CaptionsSection = {
    name: 'captions',
    Tab: (props) => (
        <SectionTab name="Captions" {...props}>
            <i className="ph ph-closed-captioning lg"></i>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({ store }) => {
        const convertToCSV = (array) => {
            return array.join('\n');
        };
        const handleCaptionDownload = () => {
            const json = store.toJSON();
            let allCaptions = json?.pages.map((item) => {
                // Check if item.custom exists and has a caption property
                if (item.custom && item.custom.caption) {
                    return item.custom.caption;
                }
                return '*NO CAPTION*';
            });
            const csvString = convertToCSV(allCaptions);
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, 'pixiiCaptions.csv');
        };
        return (
            <div className="customCaption">
                <>
                    <h2 className="text-dark fw-semibold align-left">Caption</h2>
                    {/* <TextArea
                        value={store.activePage?.custom?.caption || ''}
                        placeholder="Set caption of current page"
                        className="customCaption-textarea"
                        onChange={(e) => {
                            if (store.activePage) {
                                store.activePage.set({
                                    custom: {
                                        ...store.activePage.custom,
                                        caption: e.target.value,
                                    },
                                });
                            }
                        }}
                    /> */}
                    <Form.Group className="mb-3">
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Set caption of current page"
                            value={store.activePage?.custom?.caption || ''}
                            onChange={(e) => {
                                if (store.activePage) {
                                    store.activePage.set({
                                        custom: {
                                            ...store.activePage.custom,
                                            caption: e.target.value,
                                        },
                                    });
                                }
                            }}
                        />
                    </Form.Group>
                </>
                <div className="customCaption-button">
                    <Button variant="primary" size="lg" onClick={handleCaptionDownload}>
                        Download Captions
                    </Button>
                </div>
            </div>
        );
    }),
};

// PROJECTS SECTION
const ProjectSection = {
    name: 'Projects',
    Tab: (props) => (
        <SectionTab name="Projects" {...props}>
            <i className="ph-fill ph-folder-notch lg"></i>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({ store }) => {
        return <CustomProject store={store} />;
    }),
};
// GENERATE
const GenerateSection = {
    name: 'Generate',
    Tab: (props) => (
        <SectionTab name="Generate" {...props}>
            <i className="ph-fill ph-sparkle lg"></i>
        </SectionTab>
    ),
    // we need observer to update component automatically on any store changes
    Panel: observer(({ store }) => {
        return <Generate store={store} />;
    }),
};
const sections = [
    // GET STARTED
    // OnBrandTemplateSection,
    ProjectSection,
    // CREATIVE HUB
    GenerateSection,
    CustomUploadPanel,
    // VariantsSection,
    SizeSection,
    // PART OF THE PROJECT
    BackgroundSection,
    // CaptionsSection,
    LayersSection,
    // GENERIC RESOURCES
    TextSection,
    PhotosSection,
    ElementsSection,
];

const PolotnoEditor = ({ store, paddle }) => {
    const [getFlagForOnboardedUser] = useLazyGetFlagForOnboardedUserQuery();
    const subscriptonStatus = useSelector((state) => state.paddleFlow.subscribedUser);
    const loadingState = useSelector((state) => state.editFlow.loadingState);
    const isNewProject = useSelector((state) => state.editFlow.isNewProject);
    const { projectJSONTemplate, activeUserSessionId } = useAppSelector(selectEditFlow); // AD_ID
    const apprenticeView = useSelector((state) => state.editFlow.apprenticeView);
    // const { data: chatJSON = null, isLoading: isJSONTemplateLoading } = useGetTemplateJSONQuery(
    //     { templateId: projectJSONTemplate, sessionId: activeUserSessionId },
    //     {
    //         skip: !projectJSONTemplate,
    //     },
    // );
    const [getTemplateJSON, { isLoading: isJSONTemplateLoading1 }] = useGetTemplateJSONMutation();
    const { data: currentProject, isLoading: currentLoading } = useGetCurrentProjectQuery();
    const { data: brandElementsData } = useGetBrandElementsQuery();
    const [createProjectApi, { isLoading: isCreatingProject }] = useCreateProjectMutation();
    const t_id_create_new_project = useSelector((state) => state.editFlow.createNewProject);
    const [chatJSON1, setChatJSON1] = useState(null);
    unstable_setTextVerticalResizeEnabled(true);
    unstable_setTextOverflow('change-font-size');
    unstable_setAnimationsEnabled(true);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    console.log('THIS IS POLOTNO EDITOR', [projectJSONTemplate, activeUserSessionId]);

    useEffect(() => {
        console.log('I AM HERE');
        const fetchTemplateJSON = async () => {
            if (projectJSONTemplate && activeUserSessionId) {
                const responseData = await getTemplateJSON({
                    templateId: projectJSONTemplate,
                    sessionId: activeUserSessionId,
                }).unwrap();
                setChatJSON1(responseData);
            }
        };

        fetchTemplateJSON();
    }, [projectJSONTemplate, activeUserSessionId, getTemplateJSON]);
    console.log('THIS IS TH CHAT JSON', chatJSON1);

    // update the colors after fetching
    const mapFontFamilies = (key) => {
        return brandElementsData?.fonts?.[key]?.map((obj) => obj.fontFamily) || [];
    };
    const mapUserDefinedFonts = (key) => {
        if (brandElementsData?.fonts?.[key]) {
            const fonts = brandElementsData?.fonts?.[key];
            const userDefinedFonts = [];
            fonts.forEach((font) => {
                if (font.url) userDefinedFonts.push(font);
            });
            return userDefinedFonts;
        }
        return [];
    };

    function clearStoreAndAddPage() {
        store.clear();
        store.addPage();
        dispatch(updateIsBlankCanvasNewproject(true));
        dispatch(updateProject({ id: null, name: 'Untitled' }));
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getFlagForOnboardedUser(); // get flag
                dispatch(setUserSubscriptionStats(data?.subscribedUser)); // update state
                dispatch(setActiveUserEmailId(data.email));
                if (!data?.subscribedUser) navigate('/brandProfile');
            } catch (error) {
                if (error && error?.status === 500) {
                    //Error 422 Invalid Data
                    displayErrorToast('Server Busy Please Try Again Later');
                } else {
                    displayErrorToast(error?.data?.message);
                }
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fontListURL = getGoogleFontsListAPI();
        brandElementsData?.fonts?.Header !== undefined &&
            fetch(fontListURL)
                .then((response) => response.json())
                .then((data) => {
                    const combinedArray = [
                        ...mapFontFamilies('Header'),
                        ...mapFontFamilies('Accent'),
                        ...mapFontFamilies('Body'),
                        ...mapFontFamilies('Sub-Header'),
                    ];
                    // const extractedData = brandElementsData?.fonts?.Header.map(
                    //     (obj) => obj.fontFamily,
                    // );
                    // Process the list of fonts (data.items) here
                    setGoogleFonts([...combinedArray, ...data]);
                })
                .catch((error) => {
                    console.error('Error fetching font list:', error);
                });

        // user defined fonts
        const userDefinedFonts = [
            ...mapUserDefinedFonts('Header'),
            ...mapUserDefinedFonts('Accent'),
            ...mapUserDefinedFonts('Body'),
            ...mapUserDefinedFonts('Sub-Header'),
        ];

        // COMMENTING THIS OUT AS POLOTNO HAS A HARD TIME LOADING CUSTOM FONTS MANUALLY
        // userDefinedFonts.forEach(async (userDefinedFont) => {
        //     console.log('USER DEFINED FONTS', userDefinedFont);
        //     await store.addFont({
        //         fontFamily: userDefinedFont.fontFamily,
        //         url: userDefinedFont.url,
        //     });
        // });

        // define your own function
        brandElementsData?.colors?.length > 1 &&
            setColorsPresetFunc((test) => {
                // return array of colors
                return brandElementsData?.colors;
            });
    }, [brandElementsData]);

    useEffect(() => {
        dispatch(callSaveApi(false));
    }, []);
    const getFormattedDate = () => {
        const now = new Date();

        const hour = String(now.getHours()).padStart(2, '0');
        const minute = String(now.getMinutes()).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();

        return `${hour}:${minute}_${day}_${month}_${year}`;
    };

    const projectCreationInitiatedRef = useRef(false);

    const previousProjectIdRef = useRef();

    useEffect(() => {
        const HandleFunction = async () => {
            if (isNewProject) {
                clearStoreAndAddPage();
                return;
            }
            const came_from_apprentice_and_data_loaded = chatJSON1?.template && projectJSONTemplate;
            if (came_from_apprentice_and_data_loaded) {
                const extraFields = findExtraTemplateFields(chatJSON1.template);
                dispatch(setExtraTemplateFields(extraFields));
                dispatch(setApprenticeTemplate(chatJSON1.template));
                dispatch(updateIsBlankCanvasNewproject(false));
                if (chatJSON1.id !== projectJSONTemplate) return;
                store.loadJSON(chatJSON1.template);
                if (projectCreationInitiatedRef.current || !t_id_create_new_project) return;
                await createNewProjectAPI(chatJSON1?.template);
                dispatch(updateLoadingState(false));
                return;
            } else if (currentProject) {
                dispatch(setExtraTemplateFields(null));
                dispatch(setApprenticeTemplate(null));
                dispatch(
                    updateProject({
                        id: currentProject.id,
                        name: currentProject.name,
                    }),
                );
                dispatch(updateIsBlankCanvasNewproject(false));
                store.loadJSON(currentProject.json);
                return;
            } else {
                dispatch(setExtraTemplateFields(null));
                dispatch(setApprenticeTemplate(null));
            }

            clearStoreAndAddPage();
        };
        HandleFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectJSONTemplate, chatJSON1, currentProject]);

    const createNewProjectAPI = async (projectTemplate) => {
        projectCreationInitiatedRef.current = true;
        dispatch(updateLoadingState(true));
        dispatch(createNewProject(''));
        const res = await createProjectApi({
            name: getFormattedDate(),
            json: projectTemplate,
            isImportedOrCreated: true,
        });
        const projectData = res?.data?.data;
        dispatch(
            updateProject({
                id: projectData?.id,
                name: projectData?.name,
                json: projectData?.json,
            }),
        );
        projectCreationInitiatedRef.current = false;
    };
    useEffect(() => {
        store.on('change', () => {
            dispatch(updateLoadingState(false));
        });
    }, [store]);

    const ActionControls = ({ store }) => {
        return (
            <div className="d-flex justify-content-between align-item-center me-3">
                <PolotnoQuickSaveButton store={store} />
                <PolotnoDownloadButton store={store} />
            </div>
        );
    };
    const [height, setHeight] = useState(window.innerHeight * 0.5);
    const adjustHeight = () => {
        const isMobile = window.innerWidth <= 768;
        if (isMobile) {
            setHeight(window.innerHeight - 48);
        } else {
            setHeight(window.innerHeight);
        }
    };
    useEffect(() => {
        adjustHeight();
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);
    const show_loader = currentLoading || loadingState || isJSONTemplateLoading1;

    return (
        <>
            <Paywall
                show={!subscriptonStatus}
                onHide={() => setOpenPaywall(false)}
                paddle={paddle}
            />
            <div style={{ height: `${height}px` }}>
                <PolotnoContainer>
                    <SidePanelWrap>
                        <SidePanel store={store} sections={sections} defaultSection="Palette" />
                    </SidePanelWrap>
                    {show_loader ? (
                        <div
                            className="projectLoader"
                            style={{ padding: '24px', background: '#f9f9f9' }}
                        >
                            <Spinner
                                as="span"
                                className="mr-1 pe-none"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                                style={{
                                    marginTop: '40px',
                                    width: '48px',
                                    height: '48px',
                                    color: '#967AA7',
                                    background: 'transparent',
                                }}
                            />
                        </div>
                    ) : (
                        <WorkspaceWrap>
                            <Toolbar
                                store={store}
                                components={{
                                    ActionControls,
                                }}
                            />
                            <Workspace
                                store={store}
                                components={{ PageControls }}
                                backgroundColor="white"
                            />
                            <ZoomButtons store={store} />
                        </WorkspaceWrap>
                    )}
                </PolotnoContainer>
            </div>
        </>
    );
};
export default memo(PolotnoEditor);
