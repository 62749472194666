import { APP_ENVIRONMENT_DEVELOPMENT } from '../constants/app';

const config = {
    env: process.env.REACT_APP_ENVIRONMENT || APP_ENVIRONMENT_DEVELOPMENT,
    appName: process.env.REACT_APP_NAME || 'PIXII',
    apiBaseUrl: process.env.REACT_APP_BASE_URL,
    s3BaseUrl: process.env.REACT_APP_AMAZON_URL,
    awsCognitoRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
    awsUserPoolId: process.env.REACT_APP_AWS_USERPOOL_ID,
    awsUserPoolWebClientId: process.env.REACT_APP_AWS_USERPOOL_WEBCLIENTID,
};

export default config;
