import { baseApiSlice } from '../../../commons/base-api.config';
import {
    API_APPRENTICE_GENERATE_AD,
    API_APPRENTICE_GET_CHAT,
    API_APPRENTICE_GET_CHATS,
    API_APPRENTICE_NEW_TEMPLATE_FROM_EDITOR,
    API_APPRENTICE_REMOVE_BACKGROUND,
    API_APPRENTICE_SET_ACTIVE_TEMPLATE,
    API_PRESIGN_TEMPLATE,
} from '../../../constants/api';
import { HTTP_METHODS } from '../../../constants/http';
import {
    IChatApprenticeRequestpayload,
    IGetChatsRequestPayload,
    INewTemplateFromEditorRequestpayload,
    IPresignTemplateRequestpayload,
} from '../apprentice.interface';

export const apprenticeApiSlice = baseApiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            chatApprentice: builder.mutation<any, FormData>({
                query: (data) => {
                    return {
                        url: API_APPRENTICE_GENERATE_AD,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (res: any) => res.data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            backgroundRemoval: builder.mutation<any, FormData>({
                query: (data) => {
                    return {
                        url: API_APPRENTICE_REMOVE_BACKGROUND,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (res: any) => res.data,
                transformErrorResponse: (response: any) => response.data,
            }),
            newTemplateFromEditor: builder.mutation<any, INewTemplateFromEditorRequestpayload>({
                query: (data) => {
                    return {
                        url: API_APPRENTICE_NEW_TEMPLATE_FROM_EDITOR,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (res: any) => res.data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getChats: builder.query<any, IGetChatsRequestPayload>({
                query(data) {
                    return {
                        url: API_APPRENTICE_GET_CHATS,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                providesTags: ['chats'],
                transformResponse: (res: any) => res.data,
                transformErrorResponse: (response) => response,
            }),
            getTemplateJSON: builder.mutation<any, any>({
                query: (data) => {
                    return {
                        url: API_APPRENTICE_GET_CHAT,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (res: any) => res.data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            setActiveTemplateJSON: builder.mutation<any, any>({
                query: (data) => {
                    return {
                        url: API_APPRENTICE_SET_ACTIVE_TEMPLATE,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (res: any) => res.data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            presignTemplate: builder.mutation<any, IPresignTemplateRequestpayload>({
                query: (data) => {
                    return {
                        url: API_PRESIGN_TEMPLATE,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (res: any) => res.data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
        };
    },
});

export const {
    useChatApprenticeMutation,
    useBackgroundRemovalMutation,
    useGetChatsQuery,
    useGetTemplateJSONMutation,
    useSetActiveTemplateJSONMutation,
    useNewTemplateFromEditorMutation,
    usePresignTemplateMutation,
} = apprenticeApiSlice;
