import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createFlowReducer from '../pages/CreateFlow/CreateFlowRedux/createFlowSlice';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { authReducer } from '../pages/Auth/redux/auth.slice';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { baseApiSlice } from '../commons/base-api.config';
import persistReducer from 'redux-persist/es/persistReducer';
import { APP_ENVIRONMENT_PRODUCTION } from '../constants/app';
import config from '../config';
import editFlowReducer from '../pages/Editor/EditorRedux/editorSlice';
import templateFlowReducer from '../pages/Editor/EditorRedux/templateSlice';
import paddleFlowReducer from '../pages/PaddlePayWall/PaddlePayWallRedux/paddlePayWallSlice';

const persistConfig = {
    key: 'root',
    storage,
    // which reducer you want to store
    whitelist: ['createFlow', 'editFlow', 'paddleFlow'],
};
const combinedMiddleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat([baseApiSlice.middleware]);

const combinedReducer = combineReducers({
    createFlow: createFlowReducer,
    editFlow: editFlowReducer,
    templateFlow: templateFlowReducer,
    paddleFlow: paddleFlowReducer,
    auth: authReducer,
    [baseApiSlice.reducerPath]: baseApiSlice.reducer,
});
const persistedReducers = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
    reducer: persistedReducers,
    middleware: combinedMiddleware,
    devTools: config.env !== APP_ENVIRONMENT_PRODUCTION,
});
//store your redux reducers using redux persist
export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
