import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface TipsDisplayProps {
    show: boolean;
    tips: string[];
    handleTipClick: (tip: string) => void;
}

const TipsDisplay: React.FC<TipsDisplayProps> = ({ show, tips, handleTipClick }) => {
    return (
        <AnimatePresence>
            {show && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1, exit: { duration: 0.15 } }}
                    className="d-flex align-items-center tips gap-2 py-2 pe-3"
                >
                    {tips.map((tip, index) => (
                        <div
                            key={index}
                            className="tip d-flex align-items-center rounded"
                            onClick={() => handleTipClick(tip)}
                        >
                            {tip}
                        </div>
                    ))}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default TipsDisplay;
