import React, { useState } from 'react';
import { Form, Modal, Image, Spinner } from 'react-bootstrap';
import { allowedExtensionsProject } from '../../constants/createFlow';
import { useCreateProjectMutation } from '../../pages/Editor/EditorRedux/editor.api';
import fileIcon from '../../assets/images/fileIcon.png';
import { useAppDispatch } from '../../commons/hooks/redux';
import {
    persistEditorData,
    persistProjectId,
    persistProjectName,
    saveProjectDetails,
} from '../../pages/Editor/EditorRedux/editorSlice';
import { displayErrorToast } from '../../utils/ToastUtils';
interface TemplateModalProps {
    show: boolean;
    onHide: () => void;
    store: any;
}
const TemplateModal: React.FC<TemplateModalProps> = (props) => {
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [projectName, setProjectName] = useState('');
    const [projectJSON, setProjectJSON] = useState();
    const [error, setError] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [createProject, { isLoading }] = useCreateProjectMutation();
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const imagePreviewDataURL = async (json: any) => {
        setLoading(true);
        try {
            const req = await fetch(
                `https://api.polotno.com/api/render?KEY=${process.env.REACT_APP_POLOTNO_API_KEY}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        // polotno json from store.toJSON()
                        design: json,
                        // optional output format for export
                        outputFormat: 'url',
                        // optional export options for store.toDataURL() method
                        exportOptions: {},
                    }),
                },
            );

            const { url } = await req.json();
            setLoading(false);
            return url;
        } catch (e) {
            displayErrorToast('Server is busy, please try again later.');
            setLoading(false);
        }
    };

    const handleSaveProject = async () => {
        const imgURL = await imagePreviewDataURL(projectJSON);
        const projectTemplate = {
            name: projectName,
            json: projectJSON,
            imgDataURL: imgURL,
        };

        try {
            await createProject(projectTemplate).then((res: any) => {
                dispatch(persistProjectId(res?.data?.data.id));
                dispatch(persistProjectName(res?.data?.data.name));
                dispatch(persistEditorData(res?.data?.data.json));
                const payload = {
                    userId: res?.data?.data.userId,
                    projectName: res?.data?.data.name,
                    projectId: res?.data?.data.id,
                    projectJson: res?.data?.data.json,
                };
                dispatch(saveProjectDetails(payload));
                props.store?.loadJSON(projectJSON);
                props.onHide();
            });
        } catch (error: any) {
            if (error && error?.status === 500) {
                //Error 422 Invalid Data
                displayErrorToast('Server Busy Please Try Again Later');
            } else {
                displayErrorToast(error?.data?.message);
            }
        }
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const fileList = fileInput.files;

        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const fileName = file.name.replace(`.${fileExtension}`, '');

            if (!allowedExtensionsProject.includes(fileExtension as string)) {
                setError('Only JSON files are allowed.');
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const fileContent = e?.target?.result as string;
                    const jsonData = JSON.parse(fileContent);
                    setProjectJSON(jsonData);
                } catch (err) {
                    console.error('Error parsing JSON data:', error);
                    setError('Error parsing JSON file.');
                }
            };
            reader.readAsText(file);
            setError('');
            setProjectName(fileName);
            setUploadedFile(file);
        }
    };

    const renderPreview = () => {
        if (uploadedFile) {
            // return <img src={previewTemplate} alt="Preview" style={{ maxWidth: "100%" }} />;
            return (
                <div className="uploaded_content_img">
                    <Image src={fileIcon} />
                </div>
            );
        } else {
            return null;
        }
    };
    const handleSelect = () => {
        setIsSelected((prevSelected) => !prevSelected);
    };

    return (
        <Modal
            {...props}
            dialogClassName="modal-70w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="project-upload"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="project-upload_title">
                    Select a Pixii project to import
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="project-upload_contents">
                <Form.Group className="form-group">
                    {/* <div className="custom-file-inputs">
            <div className="custom-file-input">
              <label htmlFor="fileInput" className="file-input-label">
                <i className="pxi-cloud-upload lg"></i>
                <p>Click to upload </p>
                <span>or browse</span>
              </label>
              <input
                id="fileInput"
                type="file"
                className="file-input"
                onChange={handleFileInputChange}
              />
            </div>
          </div> */}
                    <label htmlFor="fileInput" className="custom-file-inputs photography">
                        <div className="custom-file-input">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_1888_37896)">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.23594 13.1442C7.23594 7.9895 11.39 3.82031 16.5026 3.82031C20.8552 3.82031 24.5141 6.84203 25.5049 10.923C25.5821 11.2409 25.8099 11.5009 26.1148 11.6193C28.4857 12.5399 30.1693 14.8557 30.1693 17.5687C30.1693 19.6557 29.1739 21.5069 27.6332 22.6706C27.1925 23.0035 27.1051 23.6306 27.438 24.0713C27.7708 24.512 28.3979 24.5994 28.8386 24.2665C30.8603 22.7395 32.1693 20.3068 32.1693 17.5687C32.1693 14.1934 30.1818 11.2834 27.3169 9.95814C25.9483 5.25876 21.6286 1.82031 16.5026 1.82031C10.4574 1.82031 5.53225 6.60238 5.24882 12.598C2.66627 13.5911 0.835938 16.1037 0.835938 19.0435C0.835938 21.5958 2.21612 23.8254 4.26646 25.018C4.74385 25.2957 5.35597 25.1338 5.63367 24.6565C5.91136 24.1791 5.74948 23.5669 5.27208 23.2892C3.81532 22.4419 2.83594 20.8584 2.83594 19.0435C2.83594 16.7576 4.39062 14.8399 6.48784 14.2978C6.93554 14.182 7.24551 13.7744 7.23741 13.3121C7.23643 13.2563 7.23594 13.2003 7.23594 13.1442ZM21.8764 21.4465L17.2097 16.7799C16.8192 16.3893 16.186 16.3893 15.7955 16.7799L11.1288 21.4465C10.7383 21.8371 10.7383 22.4702 11.1288 22.8608C11.5194 23.2513 12.1525 23.2513 12.543 22.8608L15.5026 19.9012V29.487C15.5026 30.0393 15.9503 30.487 16.5026 30.487C17.0549 30.487 17.5026 30.0393 17.5026 29.487V19.9012L20.4622 22.8608C20.8527 23.2513 21.4859 23.2513 21.8764 22.8608C22.2669 22.4702 22.2669 21.8371 21.8764 21.4465Z"
                                        fill="#353535"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1888_37896">
                                        <rect
                                            width="32"
                                            height="32"
                                            fill="white"
                                            transform="translate(0.5 0.152344)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p>Click to upload template</p>
                            {/* <span>or browse</span> */}
                        </div>
                    </label>
                    <input
                        id="fileInput"
                        type="file"
                        className="file-input"
                        onChange={handleFileInputChange}
                    />
                </Form.Group>
                {uploadedFile && (
                    <>
                        <p className="uploaded-title">Select Project</p>
                        <div className="uploaded_contents">
                            {/* selectedPhoto for when the user selects an image */}
                            <div
                                className={
                                    isSelected
                                        ? 'uploaded_content selectedTemplate'
                                        : 'uploaded_content'
                                }
                                onClick={handleSelect}
                            >
                                {renderPreview()}{' '}
                                <p className="uploaded_content_title">{uploadedFile.name}</p>
                            </div>
                        </div>
                    </>
                )}
                {error && (
                    <div className="error-message">
                        <i className="ph ph-warning-circle sm"></i>
                        <p>{error}</p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button
                    className={`btn ${isSelected ? 'btn-primary' : 'btn-disabled'}`}
                    onClick={handleSaveProject}
                >
                    {isLoading || loading ? <Spinner animation="border" /> : <>Upload</>}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default TemplateModal;
