import CryptoJS from 'crypto-js';
import { TEMPLATE_LAYERS_EXTRA_FIELDS } from '../constants/template';

export const calculateJSONHash = (jsonObject) => {
    const jsonString = JSON.stringify(jsonObject);
    const hash = CryptoJS.SHA256(jsonString).toString();
    return hash;
};

export const findExtraTemplateFields = (data) => {
    const result = {};
    data.pages.forEach((page) => {
        if (result[page.id] === undefined) {
            result[page.id] = {};
        }
        page.children.forEach((element) => {
            Object.keys(element).forEach((field) => {
                if (TEMPLATE_LAYERS_EXTRA_FIELDS.includes(field)) {
                    if (!result[page.id][element.id]) {
                        result[page.id][element.id] = {};
                    }
                    result[page.id][element.id][field] = element[field];
                }
            });
        });
    });

    return result;
};

export const addExtraTemplateFields = (oldData, fieldsToAdd) => {
    const data = JSON.parse(JSON.stringify(oldData));
    data.pages.forEach((page) => {
        if (fieldsToAdd[page.id]) {
            page.children.forEach((element) => {
                const elementUpdates = fieldsToAdd[page.id][element.id];
                if (elementUpdates) {
                    Object.keys(elementUpdates).forEach((field) => {
                        element[field] = elementUpdates[field];
                    });
                }
            });
        }
    });
    return data;
};

const shallowArrayEquals = (a, b) => {
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
};

const compareTemplateColorsReplace = (colorsReplace1, colorsReplace2) => {
    if (Object.keys(colorsReplace1).length != Object.keys(colorsReplace2).length) {
        return false;
    }
    for (const key in colorsReplace1) {
        if (
            colorsReplace1.hasOwnProperty(key) &&
            colorsReplace2.hasOwnProperty(key) &&
            !TEMPLATE_LAYERS_EXTRA_FIELDS.includes(key)
        ) {
            const value1 = colorsReplace1[key];
            const value2 = colorsReplace2[key];

            if (value1 !== value2) {
                return false;
            }
        }
    }

    return true;
};

const compareTemplateChild = (child1, child2) => {
    for (const key in child1) {
        if (
            child1.hasOwnProperty(key) &&
            child2.hasOwnProperty(key) &&
            !TEMPLATE_LAYERS_EXTRA_FIELDS.includes(key)
        ) {
            const value1 = child1[key];
            const value2 = child2[key];

            if (key === 'colorsReplace') {
                if (!compareTemplateColorsReplace(value1, value2)) return false;
            } else if (Array.isArray(value1) && Array.isArray(value2)) {
                if (!shallowArrayEquals(value1, value2)) return false;
            } else if (value1 !== value2) {
                return false;
            }
        }
    }

    return true;
};

const compareTemplateChildren = (children1, children2) => {
    const children1map = {};
    const children2map = {};
    for (const key in children1) {
        if (children1.hasOwnProperty(key)) {
            children1map[children1[key].id] = children1[key];
        }
    }
    for (const key in children2) {
        if (children2.hasOwnProperty(key)) {
            children2map[children2[key].id] = children2[key];
        }
    }
    if (Object.keys(children1map).length != Object.keys(children2map).length) {
        return false;
    }
    for (const key in children1map) {
        if (children1map.hasOwnProperty(key) && children2map.hasOwnProperty(key)) {
            if (!compareTemplateChild(children1map[key], children2map[key])) return false;
        }
    }
    return true;
};

const compareTemplatePage = (page1, page2) => {
    for (const key in page1) {
        if (
            page1.hasOwnProperty(key) &&
            page2.hasOwnProperty(key) &&
            !TEMPLATE_LAYERS_EXTRA_FIELDS.includes(key)
        ) {
            const value1 = page1[key];
            const value2 = page2[key];

            if (key === 'children') {
                if (!compareTemplateChildren(value1, value2)) return false;
            } else if (value1 !== value2) {
                return false;
            }
        }
    }

    return true;
};

const compareTemplatePages = (pages1, pages2) => {
    const pages1map = {};
    const pages2map = {};
    for (const key in pages1) {
        if (pages1.hasOwnProperty(key)) {
            pages1map[pages1[key].id] = pages1[key];
        }
    }
    for (const key in pages2) {
        if (pages2.hasOwnProperty(key)) {
            pages2map[pages2[key].id] = pages2[key];
        }
    }
    if (Object.keys(pages1map).length != Object.keys(pages2map).length) {
        return false;
    }
    for (const key in pages1map) {
        if (pages1map.hasOwnProperty(key) && pages2map.hasOwnProperty(key)) {
            if (!compareTemplatePage(pages1map[key], pages2map[key])) return false;
        }
    }
    return true;
};

export const compareTemplates = (template1, template2) => {
    for (const key in template1) {
        if (
            template1.hasOwnProperty(key) &&
            template2.hasOwnProperty(key) &&
            !TEMPLATE_LAYERS_EXTRA_FIELDS.includes(key)
        ) {
            const value1 = template1[key];
            const value2 = template2[key];

            if (key === 'fonts') continue;
            if (key === 'pages') {
                if (!compareTemplatePages(value1, value2)) return false;
            } else if (value1 !== value2) {
                return false;
            }
        }
    }

    return true;
};
