/* eslint-disable prettier/prettier */
import { useState, useRef, useEffect, UIEvent, memo } from 'react';

import { useSelector } from 'react-redux';

import useApprenticeTextArea from '../design/hook/useAutosizeTextArea';
import { ImageType, productImageProps } from './AttachedFiles/Interfaces';

import RenderMessage from './RenderMessage';

import {
    useChatApprenticeMutation,
    useGetChatsQuery,
    useNewTemplateFromEditorMutation,
    useSetActiveTemplateJSONMutation,
} from './ApprenticeRedux/apprentice.api';

import {
    ChatTypeEnum,
    IChat,
    INewTemplateFromEditorRequestpayload,
    IParams,
} from './apprentice.interface';

import { useAppDispatch } from '../../commons/hooks/redux';
import {
    persistProjectId,
    persistProjectName,
    saveProjectTemplate,
    updateApprenticeViewState,
    updateIsFreshStart,
    createNewProject,
    setActiveUserSessionId,
} from '../Editor/EditorRedux/editorSlice';
import { setApprenticeTemplate } from '../Editor/EditorRedux/templateSlice';

import AttachedFiles from './AttachedFiles/AttachedFiles';

import NewUser from './NewUser';

import TipsDisplay from './RenderTips';
import TwoWayDoorLoader from './twoWayDoor';
import { EditingModeMessage } from './RenderText';
import { LoadingSpinner, FetchingChatsLoader } from './AppereancticeSpinners';
import CustomNavProfile from './CustomNavProfile';
import ChatInput from './ChatInput';

import { addExtraTemplateFields, compareTemplates } from '../../utils/TemplateUtils';

import { LOADING_MESSAGES, TIPS } from './constants';

const ApprenticeChat = ({
    chatQueryParams,
    sessionKey,
    newSession,
    setGetChatsQueryParams,
    store,
    toggleEditingMode,
    editingMode,
    isOpen,
    setIsOpen,
    handleNewSessionChatStart,
    setOpenInEditor,
}: IParams) => {
    const defaultProductPhotographyData = {
        imageData: {
            imageType: ImageType.NONE,
            data: null,
            id: '',
        },
        isImageDataPresent: false,
    };
    const apprenticeTemplate = useSelector((state: any) => state.templateFlow.apprenticeTemplate);
    const extraTemplateFields = useSelector((state: any) => state.templateFlow.extraTemplateFields);
    const [uploadedFileForAd, setuploadedFileForAd] = useState<File | null>(null);

    const [twoWayDoorLoading, setTwoWayDoorLoading] = useState<boolean>(false);

    const [chatApprentice, { isLoading: chatLoading }] = useChatApprenticeMutation();
    const [newTemplateFromEditor] = useNewTemplateFromEditorMutation();
    const [newAdFromEditorLoading, setNewAdFromEditorLoading] = useState(false);
    const [chats, setChats] = useState<any[]>([]);
    const { data, isLoading: getChatsLoading, refetch: refetchChat } = useGetChatsQuery(
        chatQueryParams,
    );
    const [hasNext, setHasNext] = useState(false);
    const [refetchChatLoading, setRefetchChatLoading] = useState(false);
    const [initialChatLoading, setInitialChatLoading] = useState(true);
    const [value, setValue] = useState('');
    const [isScrolledUp, setIsScrolledUp] = useState(false);
    const [toShowTips, setToShowTips] = useState(false);
    const [messages, setMessages] = useState<IChat[]>([]);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const [tips, setTips] = useState<string[]>(TIPS);
    const chatInputRef = useRef<HTMLDivElement>(null);
    const loadingMessages = LOADING_MESSAGES;

    const [productPhotographyData, setProductPhotographyData] = useState<productImageProps>(
        defaultProductPhotographyData,
    );
    const [visionPhotographyData, setVisionPhotographyData] = useState<productImageProps>(
        defaultProductPhotographyData,
    );

    const [setActiveTemplateJSON] = useSetActiveTemplateJSONMutation();

    const setProductImageToDefault = () => {
        setProductPhotographyData({
            imageData: {
                imageType: ImageType.NONE,
                data: null,
                id: '',
            },
            isImageDataPresent: false,
        });
    };
    const [loadingStep, setLoadingStep] = useState<number>(0);

    const [isTextAreaFocused, setTextAreaFocus] = useState<boolean>(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [height, setHeight] = useState(window.innerHeight);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    // NEED THIS FOR DYNAMIC HEIGHT OF THE TEXTAREA
    useApprenticeTextArea(textAreaRef.current, value);

    // Update chats state with the latest chat messages
    useEffect(() => {
        if (!data) return;

        if (!data?.has_next) setRefetchChatLoading(false);
        setHasNext(data?.has_next || false);
        setChats(data?.chats || []);
    }, [data]);

    useEffect(() => {
        const fetchLatestChats = async () => {
            try {
                setRefetchChatLoading(true);
                const { data } = await refetchChat();

                if (!data || !data.chats) return;

                setMessages(data.chats.reverse()); // Reverse to display latest chats first
            } catch (error) {
                // displayErrorToast('Server Busy Please Try Again Later');
            }
        };

        fetchLatestChats();
    }, [refetchChat]);

    useEffect(() => {
        const timer = setInterval(() => {
            setLoadingStep((prevStep) => (prevStep + 1) % loadingMessages.length);
        }, 4000);

        return () => clearInterval(timer);
    }, [loadingMessages.length]);

    const handleGetAdFromEditor = async () => {
        if (store != null && !chatLoading && !initialChatLoading) {
            setNewAdFromEditorLoading(true);
            const projectJSON = store.toJSON();

            if (projectJSON && projectJSON.pages && projectJSON.pages.length > 0) {
                if (apprenticeTemplate != null) {
                    if (!compareTemplates(apprenticeTemplate, projectJSON)) {
                        dispatch(setApprenticeTemplate(projectJSON));
                        const newprojectJSON = addExtraTemplateFields(
                            projectJSON,
                            extraTemplateFields,
                        );
                        const payload: INewTemplateFromEditorRequestpayload = {
                            sessionId: sessionKey,
                            template: newprojectJSON,
                        };
                        const response: any = await newTemplateFromEditor(payload);
                        if (response && response.data && response.data.chats) {
                            const chatsData: IChat[] = response.data.chats;
                            const newChats: IChat[] = [...chatsData];
                            if (newChats && newChats.length > 0) {
                                newChats.reverse();
                                const oldMessages = [...messages];
                                const newMessages = [...oldMessages, ...newChats];
                                setMessages(newMessages);
                            }
                        }
                    }
                } else {
                    dispatch(setApprenticeTemplate(projectJSON));
                    let newprojectJSON = projectJSON;
                    if (extraTemplateFields) {
                        newprojectJSON = addExtraTemplateFields(projectJSON, extraTemplateFields);
                    }
                    const payload: INewTemplateFromEditorRequestpayload = {
                        sessionId: sessionKey,
                        template: newprojectJSON,
                    };
                    const response: any = await newTemplateFromEditor(payload);
                    if (response && response.data && response.data.chats) {
                        const chatsData: IChat[] = response.data.chats;
                        const newChats: IChat[] = [...chatsData];
                        if (newChats && newChats.length > 0) {
                            newChats.reverse();
                            const oldMessages = [...messages];
                            const newMessages = [...oldMessages, ...newChats];
                            setMessages(newMessages);
                        }
                    }
                }
            }
            setNewAdFromEditorLoading(false);
        }
    };
    const cleanInput = (input: string): string => {
        return input.replace(/\@\[(.*?)\]\((.*?)\)/g, '$1');
    };

    // CHAT WITH APPRENTICE

    const createMessage = (type: string, value: string, system: boolean) => {
        return {
            session_id: '',
            type: type,
            value: value,
            system: system,
            id: '',
            created_at: new Date(),
            updated_at: new Date(),
        };
    };
    const buildFormData = (query: string) => {
        const formData = new FormData();
        formData.append('prompt', query);
        formData.append('sessionId', sessionKey);
        if (uploadedFileForAd) {
            formData.append('fileInput', uploadedFileForAd as File);
        }
        if (productPhotographyData.isImageDataPresent) {
            formData.append('imageType', 'PRODUCT_IMAGE');
            if (productPhotographyData.imageData.imageType === 'file')
                formData.append('fileInput', productPhotographyData.imageData.data as File);
            if (productPhotographyData.imageData.imageType === 'url')
                formData.append('productImage', productPhotographyData.imageData.data as string);
        } else if (visionPhotographyData.isImageDataPresent) {
            formData.append('imageType', 'VISION_IMAGE');
            if (visionPhotographyData.imageData.imageType === 'file')
                formData.append('fileInput', visionPhotographyData.imageData.data as File);
            if (visionPhotographyData.imageData.imageType === 'url')
                formData.append('productImage', visionPhotographyData.imageData.data as string);
        }
        /* 
            // PIX-785: Not removing the uploaded files if message sent 
            // Just uncomment to make it delete the uploaded files
            setProductImageToDefault();
            setuploadedFileForAd(null);
        */

        return formData;
    };

    const scrollToBottom = () => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            setTimeout(() => {
                container.scrollTo({
                    top: container.scrollHeight + 1000,
                    behavior: 'smooth',
                });
            }, 1000);
        }
    };

    const handleSubmit = async (event: any) => {
        setShowSuggestions(false);
        setToShowTips(false);
        if (newAdFromEditorLoading || chatLoading || initialChatLoading) return;

        if (newSession) handleNewSessionChatStart();

        event.preventDefault();
        setIsScrolledUp(false);
        setValue('');
        const oldMessages = [...messages];

        setMessages([
            ...messages,
            createMessage(ChatTypeEnum.TEXT, value, false),
            createMessage(ChatTypeEnum.TEXT, 'Generating', true),
        ]);
        scrollToBottom();
        const chatResponse: any = await chatApprentice(buildFormData(cleanInput(value)));

        HandleGenerateAdCallResponse(chatResponse, oldMessages);

        setTextAreaFocus(false);
    };

    const getSuggestions = (chatResponse: IChat[]) => {
        const success = chatResponse?.length > 0 && chatResponse[0].value?.length > 0;
        if (success) {
            const newSuggestions = chatResponse[0].value[0].suggestions;
            if (newSuggestions && newSuggestions.length > 0) {
                setTips(newSuggestions);
            }
        }
    };

    const HandleGenerateAdCallResponse = (chatResponse: any, oldMessages: IChat[]) => {
        const success = chatResponse && chatResponse.data && chatResponse.data.chats;
        if (success) {
            const newChats: IChat[] = [...chatResponse.data.chats];
            getSuggestions(newChats);
            // setTips(newChats[0].value[0].suggestions || TIPS);
            setToShowTips(true);
            if (newChats && newChats.length > 0)
                setMessages([...oldMessages, ...newChats.reverse()]);
        }
        chatResponse?.error &&
            setMessages((messages) => {
                const lastMessage = messages.slice(-1)[0] || null;
                const updatedMessages =
                    lastMessage && lastMessage.value === 'Generating'
                        ? messages.slice(0, -1)
                        : messages;
                return [
                    ...updatedMessages,
                    createMessage('error', chatResponse?.error.message, true),
                ];
            });
    };

    useEffect(() => {
        //Check If he/she Is A New User
        const isChatsNotEmpty = chats.length > 0;
        dispatch(updateIsFreshStart(!isChatsNotEmpty));
    }, [chats, dispatch]);

    useEffect(() => {
        !isScrolledUp && scrollToBottom();
    }, [messages, isScrolledUp]);

    useEffect(() => {
        if (chats?.length) {
            const newChats: IChat[] = [...chats];
            if (newChats && newChats.length > 0) {
                // const _latest = newChats[0].value[0].suggestions;
                // setTips(_latest);
                getSuggestions(newChats);
                setShowSuggestions(true);

                const newChatsUICompatible = newChats.reverse();
                const oldMessages = [...messages];

                setMessages((oldMessages) => {
                    const combinedMessages = [...newChatsUICompatible, ...oldMessages];
                    const sortedMessages = combinedMessages.sort((a, b) => {
                        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                    });
                    const uniqueCombinedMessages = sortedMessages.filter(
                        (message, index, self) =>
                            index === self.findIndex((m) => m.id === message.id),
                    );
                    return uniqueCombinedMessages;
                });
                if (oldMessages.length === 0) scrollToBottom();
            }
            setRefetchChatLoading(false);
            // if (scrollContainerRef.current && isScrolledUp)
            //     scrollContainerRef.current.scrollTop = 200;
        }
    }, [chats]);

    useEffect(() => {
        if (!getChatsLoading && initialChatLoading) {
            setInitialChatLoading(false);
        }
    }, [getChatsLoading]);

    const [isScrolling, setIsScrolling] = useState(false);

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            if (scrollContainerRef.current.scrollTop === 0) {
                const currentScrollPos = scrollContainerRef.current.scrollTop;
                const scrolling = currentScrollPos !== prevScrollPos;

                setIsScrolling(scrolling);

                setPrevScrollPos(currentScrollPos);

                if (hasNext) {
                    setIsScrolledUp(true);
                    setMessages((messages) => {
                        return [createMessage(ChatTypeEnum.HISTORY, '', true), ...messages];
                    });
                    // Update skip value based on the current state
                    setGetChatsQueryParams((prevParams) => ({
                        ...prevParams,
                        skip: prevParams.skip! + (prevParams.skip === 0 ? 15 : 15),
                        limit: 15,
                    }));
                }
            }
        }
    };

    const handleOpenInEditor = (templateId: any, sessionId: string) => {
        setIsOpen(false);
        toggleEditingMode();
        setOpenInEditor(true);
        dispatch(updateApprenticeViewState(true));
        dispatch(setActiveUserSessionId(sessionId));
        setActiveTemplateJSON({
            templateId: templateId,
            sessionId: sessionId,
        });

        dispatch(saveProjectTemplate(templateId));
        dispatch(persistProjectName(''));
        dispatch(persistProjectId(null));
        dispatch(createNewProject(templateId));
        dispatch(updateIsFreshStart(false)); // Not an empty project !
    };

    const handleTipsScroll = (event: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
        setPrevScrollPos(event.currentTarget.scrollTop);
    };

    const handleScrolls = (e: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
        handleTipsScroll(e);
        handleScroll();
    };
    const isMobile = window.innerWidth <= 768;
    const textAreaHeight = isMobile ? 46 : 56;

    const adjustHeight = () => {
        setHeight(window.innerHeight - 64 - textAreaHeight);
    };
    useEffect(() => {
        adjustHeight();
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, []);

    const handleTipClick = (tip: string) => {
        setToShowTips(false);
        setValue(tip);
        setTips((prevTips) => prevTips.filter((t) => t !== tip));
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
        setTextAreaFocus(true);
    };
    const handleAttachedFilesClick = () => {
        setToShowTips(false);
    };
    const handleChatInputFocus = () => {
        setToShowTips(true);
    };
    const handleBlur = () => {
        // Hide tips after a delay
        setTimeout(() => {
            setTextAreaFocus(false);
        }, 600);
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const adjustTextareaHeight = () => {
            if (textAreaRef.current) {
                const lines = value.split('\n').length;
                textAreaRef.current.style.height = `${lines * 20}px`;
            }
        };
        adjustTextareaHeight();
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (chatInputRef.current && !chatInputRef.current.contains(event.target as Node))
                setTextAreaFocus(false);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [chatInputRef]);

    // const is_New_User = messages.length === 0 && newSession;
    const showTipsDisplay =
        isTextAreaFocused && value === '' && !isScrolling && !chatLoading && !newSession;

    const show_message = !initialChatLoading;
    return (
        <div
            className={'apprentice px-3 vh-100'}
            style={{ width: isOpen ? '75%' : isMobile ? '112%' : editingMode ? '107%' : '85%' }}
        >
            <div
                id="scrollableDiv"
                className="responses position-relative"
                ref={scrollContainerRef}
                onScroll={(e) => handleScrolls(e)}
                style={{
                    height: `${height}px`,
                    marginBottom: editingMode ? '4rem' : isMobile ? '3rem' : '3.75rem',
                    // transition: 'height  ease-in-out',
                }}
            >
                {/* <NewUser
                    setValue={setValue}
                    setTextAreaFocus={setTextAreaFocus}
                    editingMode={editingMode}
                    show={is_New_User}
                /> */}
                <FetchingChatsLoader show={initialChatLoading} />
                <LoadingSpinner show={refetchChatLoading && show_message} />
                <RenderMessage
                    show={show_message}
                    tips={showTipsDisplay}
                    messages={messages}
                    chatLoading={chatLoading}
                    twoWayDoorLoading={twoWayDoorLoading}
                    editingMode={editingMode}
                    isMobile={isMobile}
                    loadingStep={loadingStep}
                    loadingMessages={loadingMessages}
                    handleOpenInEditor={handleOpenInEditor}
                />
                <EditingModeMessage show={editingMode && show_message} />

                <TwoWayDoorLoader show={twoWayDoorLoading && show_message} />
            </div>
            <div
                className="form me-1 flex-grow-1 mb-1 apprentice_textarea"
                style={{
                    bottom: editingMode ? '60px' : '50px',
                }}
            >
                <TipsDisplay show={toShowTips} tips={tips} handleTipClick={handleTipClick} />
                <div
                    className="d-flex justify-content-between align-items-end gap-1"
                    ref={chatInputRef}
                >
                    <AttachedFiles
                        uploadedFile={uploadedFileForAd}
                        setUploadedFile={setuploadedFileForAd}
                        productPhotographyData={productPhotographyData}
                        visionPhotographyData={visionPhotographyData}
                        setVisionPhotographyData={setVisionPhotographyData}
                        setProductPhotographyData={setProductPhotographyData}
                        handleAttachedFilesClick={handleAttachedFilesClick}
                    />

                    <ChatInput
                        value={value}
                        isTextAreaFocused={isTextAreaFocused}
                        setValue={setValue}
                        handleGetAdFromEditor={handleGetAdFromEditor}
                        setTwoWayDoorLoading={setTwoWayDoorLoading}
                        scrollToBottom={scrollToBottom}
                        setTextAreaFocus={setTextAreaFocus}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        handleChatInputFocus={handleChatInputFocus}
                    />
                </div>
            </div>
            <CustomNavProfile show={!editingMode} />
        </div>
    );
};

export default memo(ApprenticeChat);
