import React, { useEffect, useState } from 'react';
import { BrandVoiceState } from './BrandVoice';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { brandVoiceLabels } from '../../../../constants/brandMagic';
import AutoResizeTextarea from './AutoResizeableTextArea';
import { useSaveBrandVoiceMutation } from '../../BrandMagicRedux/brandMagic.api';
import Image from './Image';
import { setOnBoardedUser } from '../../../CreateFlow/CreateFlowRedux/createFlowSlice';
import { displayErrorToast } from '../../../../utils/ToastUtils';
import { useUpdateOnboardingUserMutation } from '../../../CreateFlow/CreateFlowRedux/createFlow.api';
import { useAppDispatch, useAppSelector } from '../../../../commons/hooks/redux';
interface IProps {
    handleNext: () => void;
    handlePrev: () => void;
    brandVoice: BrandVoiceState;
    setBrandVoice: React.Dispatch<React.SetStateAction<BrandVoiceState>>;
    isFetchingData: boolean;
}

const Memory = ({ handleNext, handlePrev, brandVoice, setBrandVoice, isFetchingData }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [anythingElseContent, setAnythingElseContent] = useState<string>('');
    const [mimicContent, setMimicContent] = useState<string>('');
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [saveBrandVoice] = useSaveBrandVoiceMutation();
    const [updateOnboardingUser, { isLoading }] = useUpdateOnboardingUserMutation();
    const handleAnythingElseContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event && event.target) {
            setAnythingElseContent(event.target.value);
        }
    };
    const handleMimicContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event && event.target) {
            setMimicContent(event.target.value);
        }
    };

    const handleFinish = async () => {
        try {
            await handleSave();
            const data = await updateOnboardingUser({}).unwrap();
            dispatch(setOnBoardedUser(true));
            navigate('/');
        } catch {
            displayErrorToast('Failed to setup please try again!');
        }
    };

    const handleSave = async () => {
        if ((mimicContent.length > 0 || anythingElseContent.length > 0) && !saveLoading) {
            setSaveLoading(true);
            const newBrandVoice = {
                ...brandVoice,
                scrapedTextContent: mimicContent,
                anythingElse: anythingElseContent,
            };
            setBrandVoice(newBrandVoice);
            await saveBrandVoice(newBrandVoice)
                .unwrap()
                .then((response: any) => {
                    console.log(response, 'BRAND VOICE SAVED SUCCEFULLY');
                    setSaveLoading(false);
                })
                .catch((error) => {
                    console.log(error, 'BRAND VOICE SAVE FAILED');
                    setSaveLoading(false);
                });
        }
    };

    useEffect(() => {
        if (
            brandVoice &&
            brandVoice.scrapedTextContent &&
            brandVoice.scrapedTextContent.length > 0
        ) {
            setMimicContent(brandVoice.scrapedTextContent);
        }
        if (brandVoice && brandVoice.anythingElse && brandVoice.anythingElse.length > 0) {
            setAnythingElseContent(brandVoice.anythingElse);
        }
    }, [brandVoice]);

    return (
        <>
            <div className="brandMagic_content">
                <div className="mb-4">
                    <h1 className="fw-bold text-primary">
                        Give me some text from your brand that you want me to mimic
                    </h1>
                    {brandVoice &&
                        brandVoice.scrapedTextContent &&
                        brandVoice.scrapedTextContent.length > 0 && (
                            <p className="fst-italic text-light" style={{ fontSize: '0.85rem' }}>
                                The prefilled texts below is the one i extracted from your website.
                                You can always modify it as you want
                            </p>
                        )}
                </div>

                <div className="content_potions">
                    <div className="potion-alt">
                        {isFetchingData ? (
                            <div className="d-flex justify-content-center mt-3">
                                <Spinner animation="border" />
                            </div>
                        ) : (
                            <>
                                <Form.Group className="form mb-4 mt-2">
                                    <Form.Label className="h4 text-primary fw-bold m-0">
                                        {brandVoiceLabels['anythingElse']}
                                    </Form.Label>
                                    <div className="whitespace"></div>
                                    <AutoResizeTextarea
                                        value={anythingElseContent}
                                        className={'anythingElse'}
                                        id={'anythingElse'}
                                        onChange={handleAnythingElseContentChange}
                                        rows={4}
                                        readOnly={saveLoading}
                                        required={true}
                                        onBlur={() => {}}
                                    />
                                </Form.Group>
                                <Form.Group className="form mb-4 mt-2">
                                    <Form.Label className="h4 text-primary fw-bold m-0">
                                        {brandVoiceLabels['scrapedTextContent']}
                                    </Form.Label>
                                    <div className="whitespace"></div>
                                    <AutoResizeTextarea
                                        value={mimicContent}
                                        className={'scrapedTextContent'}
                                        id={'scrapedTextContent'}
                                        onChange={handleMimicContentChange}
                                        rows={4}
                                        readOnly={saveLoading}
                                        required={true}
                                        onBlur={() => {}}
                                    />
                                </Form.Group>
                            </>
                        )}
                    </div>
                </div>
                <Image />
                <div className="d-flex justify-content-between align-items-center mt-3 brandMagic_buttons">
                    <Button onClick={handlePrev} variant="secondary" size="lg">
                        Back
                    </Button>
                    {saveLoading ? (
                        <Button variant="primary" size="lg">
                            <Spinner />
                        </Button>
                    ) : brandVoice &&
                      brandVoice.keyProductService &&
                      brandVoice.scrapedTextContent === mimicContent &&
                      brandVoice.anythingElse === anythingElseContent ? (
                        <Button variant="primary" size="lg" onClick={handleFinish}>
                            Finish
                        </Button>
                    ) : (
                        <div className="d-flex justify-content-between align-items-center">
                            <Button
                                variant="primary"
                                size="lg"
                                onClick={handleSave}
                                style={{ marginRight: '5px' }}
                            >
                                Save
                            </Button>
                            <Button variant="primary" size="lg" onClick={handleFinish}>
                                Finish
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Memory;
