// src/utils/projectUtils.js

import { useDispatch } from 'react-redux';
import {
    useCreateProjectMutation,
    useGetProjectByIdQuery,
} from '../pages/Editor/EditorRedux/editor.api';
import {
    updateProject,
    updateIsBlankCanvasNewproject,
} from '../pages/Editor/EditorRedux/editorSlice';
import { displayErrorToast, displaySuccessToast } from './ToastUtils';

export const useCreateAndPersistProject = () => {
    const dispatch = useDispatch();
    const [createProject, { isLoading }] = useCreateProjectMutation();
    const createAndPersistProject = async (projectTemplate, store, alertSuccessMessage = true) => {
        const updateProjectState = (projectDetails) => {
            const { projectId, projectName, projectJson } = projectDetails;
            dispatch(updateIsBlankCanvasNewproject(false));
            alertSuccessMessage &&
                dispatch(updateProject({ id: projectId, name: projectName, json: projectJson }));
        };
        try {
            await createProject(projectTemplate).then((res) => {
                const projectData = res?.data?.data;
                updateProjectState({
                    userId: projectData?.userId,
                    projectId: projectData?.id,
                    projectName: projectData?.name,
                    projectJson: projectData?.json,
                });
            });
            if (!alertSuccessMessage) return; // return if alertSuccessMessage is falsy
            // displaySuccessToast('Saved project successfully');
        } catch (error) {
            displayErrorToast('Failed to save project'); // console.log(error, "Error while saving Project Template");
        }
    };

    return { createAndPersistProject, isLoading };
};

export function useFetchProjectById(projectId) {
    return useGetProjectByIdQuery(projectId); // You can pass parameters if needed
}
