import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { Auth } from 'aws-amplify';

export const BASE_API_SLICE = 'baseApi';

export const prepareBaseQuery = fetchBaseQuery({
    baseUrl: config.apiBaseUrl,
    prepareHeaders: async (headers, { getState }) => {
        if (!headers.has('Authorization')) {
            await Auth.currentSession()
                .then((res) => {
                    const accessToken = res.getAccessToken();
                    const jwt = accessToken.getJwtToken();

                    if (jwt) {
                        headers.set('authorization', `Bearer ${jwt}`);
                    }
                })
                .catch((e) => console.log('failed to get jwt', e));
        }
        // const { token } = (getState() as RootState)?.auth;

        return headers;
    },
});

export const baseApiSlice = createApi({
    reducerPath: 'baseApi',
    baseQuery: prepareBaseQuery,
    endpoints: () => ({}),
    tagTypes: [
        'generatedResults',
        'favourites',
        'productPhotographyImages',
        'uploadedAssets',
        'allProjects',
        'brandVoice',
        'brandFormat',
        'targetCustomer',
        'fetchAdPreferences',
        'onBrandPotion',
        'brandElements',
        'imagePotion',
        'generatedImages',
        'generatedTexts',
        'activeProject',
        'activeSession',
        'chats',
        'logo',
    ],
});
