// Brand Magic
export const API_BRAND_MAGIC_VOICE = 'brand-magic/brand-voice';
export const API_BRAND_MAGIC_FORMAT = 'brand-magic/format';
export const API_BRAND_MAGIC_TARGET_CUSTOMER = 'brand-magic/target-customer';
export const API_BRAND_MAGIC_SCRAPING_URL = '/onboarding';
export const API_BRAND_MAGIC_ONBRAND_POTION = 'brand-magic/potion';
export const API_BRAND_MAGIC_FETCH_COPY_INFO = 'brand-magic/fetch-copy-information';
export const API_BRAND_MAGIC_ADD_COLOR = '/brand-magic/brand-elements/add-color';
export const API_BRAND_MAGIC_REMOVE_COLOR = '/brand-magic/brand-elements/remove-color';
export const API_BRAND_MAGIC_ADD_FONT = '/brand-magic/brand-elements/add-font';
export const API_BRAND_MAGIC_REMOVE_FONT = '/brand-magic/brand-elements/remove-font';
export const API_BRAND_MAGIC_ADD_LOGO = '/brand-magic/brand-elements/add-logo';
export const API_BRAND_MAGIC_REMOVE_LOGO = '/brand-magic/brand-elements/remove-logo';
export const API_BRAND_MAGIC_GET_BRAND_ELEMENTS = '/brand-magic/brand-elements/';
export const API_BRAND_IMAGE_GET_USER_FAVOURITES = '/brand-magic/image';
export const API_DEFAULT_POTIONS = '/pixii-potions/potions/for-users';
export const API_AD_PREFERENCE = '/ad-preference';

// Create Flow
export const API_GENERATE_IMAGE = '/generate-image/text-to-image';
export const API_CREATE_FLOW = 'text-generation';
export const API_CREATE_FLOW_IMAGE = 'generate-image/stable-diffusion/text-to-image';
export const API_CLIP_DROP = 'generate-image/clipdrop/text-to-image';
export const API_CREATE_FLOW_IMAGE_DIMENSION = '/image-dimension?dropDown=true';
export const API_PRODUCT_PHOTOGRAPHY = '/replace-image-background';
export const API_PRODUCT_PHOTO = '/product-photo';
export const API_FLAG_FOR_ONBOARDED_USER = 'auth/init';
export const API_UPDATE_ONBOARDING_USER = '/brand-magic/update-onboarding-status';
export const API_GENERATED_RESULTS = 'create-flow/fetch-generated-results';
export const API_FAVORITE = 'favorite/assets';
export const API_CREATE_IMAGE_POTION = 'brand-magic/image';
// Editor Flow
export const API_PROJECT = 'project';
export const API_PROJECT_CURRENT_PROJECT = 'project/currently-working';
export const API_PROJECT_DUPLICATE = 'project/duplicate';
export const API_GET_CANVAS = 'editor/fetch-canvas-data';
export const API_SAVE_CANVAS = 'editor/save-canvas-data';
export const API_UPLOAD_ASSET = '/upload-asset';
export const API_DOWNLOAD_COUNT = 'download-count/increase';
// Brand Templates
export const API_BRAND_TEMPALTES = '/brand-templates';
// Credit Score
export const API_CREDIT_SCORE = 'auth/init';

// Combined Flow
export const API_GET_GENERATED_IMAGES = 'create-flow/x-edit/image';
export const API_GET_BACKGROUND_REPLACED_IMAGES = 'create-flow/x-edit/backgroundReplaceimage';
export const API_GET_GENERATED_TEXTS = 'create-flow/x-edit/text';

// Apprentice Flow
export const API_APPRENTICE_GENERATE_AD = '/apprentice/generate_ad';
export const API_APPRENTICE_REMOVE_BACKGROUND = '/apprentice/remove_background';
export const API_APPRENTICE_GET_CHATS = '/chat';
export const API_APPRENTICE_GET_CHAT = '/chat/get_template_json';
export const API_APPRENTICE_SET_ACTIVE_TEMPLATE = '/chat/set_active_template';
export const API_CREATE_SESSION = '/sessions/create_session';
export const API_UPDATE_SESSION = '/sessions';
export const API_APPRENTICE_NEW_TEMPLATE_FROM_EDITOR = '/chat/new_template_from_editor';
export const API_PRESIGN_TEMPLATE = '/template/presign_template';

// Sessions
export const API_ACTIVE_SESSION = '/sessions/active_session';

//Paddle PayWall
export const API_VERIFY_TRANSACITON = '/paddle/verifyTransaction';

//User Subscription
export const API_USER_SUBSCRIBTION_STATUS = '/userSubscription/paymentProcess';
