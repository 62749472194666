import React, { useEffect } from 'react';
import { Button, Form, Image, Placeholder, Spinner } from 'react-bootstrap';
import { IGetBrandElements } from '../../BrandMagicRedux/BrandMagicTypes';
import config from '../../../../config';
import {
    useRemoveBrandElementsLogoMutation,
    useUploadBrandElementsLogoMutation,
} from '../../BrandMagicRedux/brandMagic.api';
import { displaySuccessToast, displayWarningToast } from '../../../../utils/ToastUtils';

interface IProps {
    brandElements: IGetBrandElements | undefined;
    brandElementsLoading: boolean;
}

const DesignElementsLogo: React.FC<IProps> = ({ brandElements, brandElementsLoading }) => {
    const [uploadLogo, { isLoading: uploadLogoLoading }] = useUploadBrandElementsLogoMutation();
    const [deleteLogo, { isLoading: removeLogoLoading }] = useRemoveBrandElementsLogoMutation();

    // handle file input change
    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const fileList = fileInput.files;
        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            await uploadLogo({ image: file });
        }
    };

    // handle delete logo
    const handleDelete = async () => {
        await deleteLogo()
            .then(() => {
                displaySuccessToast('Successfully deleted');
            })
            .catch(() => displayWarningToast('There was an error'));
    };

    return (
        <div className="designElements mb-3 p-3">
            <h1 className="fw-bold text-primary mb-1">Logo</h1>
            <span className="h5 text-light text-center m-0 fw-regular">
                Preferred: Square image with transparent background.| Accepted formats: JPG, PNG,
                SVG
            </span>
            {/* <Form.Group className="form-group">
                <label htmlFor="fileInputLogo" className="custom-file-inputs photography">
                    <div className="custom-file-input d-flex justify-content-center align-items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_1888_37896)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.23594 13.1442C7.23594 7.9895 11.39 3.82031 16.5026 3.82031C20.8552 3.82031 24.5141 6.84203 25.5049 10.923C25.5821 11.2409 25.8099 11.5009 26.1148 11.6193C28.4857 12.5399 30.1693 14.8557 30.1693 17.5687C30.1693 19.6557 29.1739 21.5069 27.6332 22.6706C27.1925 23.0035 27.1051 23.6306 27.438 24.0713C27.7708 24.512 28.3979 24.5994 28.8386 24.2665C30.8603 22.7395 32.1693 20.3068 32.1693 17.5687C32.1693 14.1934 30.1818 11.2834 27.3169 9.95814C25.9483 5.25876 21.6286 1.82031 16.5026 1.82031C10.4574 1.82031 5.53225 6.60238 5.24882 12.598C2.66627 13.5911 0.835938 16.1037 0.835938 19.0435C0.835938 21.5958 2.21612 23.8254 4.26646 25.018C4.74385 25.2957 5.35597 25.1338 5.63367 24.6565C5.91136 24.1791 5.74948 23.5669 5.27208 23.2892C3.81532 22.4419 2.83594 20.8584 2.83594 19.0435C2.83594 16.7576 4.39062 14.8399 6.48784 14.2978C6.93554 14.182 7.24551 13.7744 7.23741 13.3121C7.23643 13.2563 7.23594 13.2003 7.23594 13.1442ZM21.8764 21.4465L17.2097 16.7799C16.8192 16.3893 16.186 16.3893 15.7955 16.7799L11.1288 21.4465C10.7383 21.8371 10.7383 22.4702 11.1288 22.8608C11.5194 23.2513 12.1525 23.2513 12.543 22.8608L15.5026 19.9012V29.487C15.5026 30.0393 15.9503 30.487 16.5026 30.487C17.0549 30.487 17.5026 30.0393 17.5026 29.487V19.9012L20.4622 22.8608C20.8527 23.2513 21.4859 23.2513 21.8764 22.8608C22.2669 22.4702 22.2669 21.8371 21.8764 21.4465Z"
                                    fill="#353535"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1888_37896">
                                    <rect
                                        width="32"
                                        height="32"
                                        fill="white"
                                        transform="translate(0.5 0.152344)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="h3 mb-1">Click to upload your logo</p>
                        <span className="h6 fst-italic text-light text-center">
                            Preferred: Square image with transparent background.
                            <br />
                            Accepted formats: JPG, PNG, SVG
                        </span>
                    </div>
                </label>
                <input
                    id="fileInputLogo"
                    type="file"
                    className="file-input"
                    onChange={handleFileInputChange}
                    onClick={(e) => {
                        if (brandElements && brandElements.logo) {
                            e.preventDefault();
                            displayWarningToast('Delete the current logo to add a new one');
                        }
                    }}
                    multiple={false}
                />
            </Form.Group>
            {brandElementsLoading || uploadLogoLoading ? (
                <div className="SpinnerDiv mt-3">
                   
                </div>
            ) : (
                <>
                    {brandElements && brandElements.logo && (
                        <div>
                            {removeLogoLoading ? (
                                <div className="SpinnerDiv mt-3">
                                    
                                </div>
                            ) : (
                                <>
                                    <div className="mt-3 ">
                                        <div className="uploadedLogo">
                                            <Image
                                                src={brandElements.logo}
                                                className="border border-primary rounded p-3 "
                                                style={{ width: '76px', height: '76px' }}
                                            />
                                            <button
                                                className="iconbtn iconbtn-transparent"
                                                onClick={() => handleDelete()}
                                            >
                                                <i className="ph-bold ph-prohibit-inset"></i>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </>
            )} */}
            <div className="d-flex align-items-center justify-contents-center mt-3">
                {brandElementsLoading || uploadLogoLoading ? (
                    <div className="SpinnerDiv">
                        {/* Place your spinner component or loading indicator here */}
                    </div>
                ) : (
                    <>
                        {!brandElements || !brandElements.logo ? (
                            <Form.Group className="form-group me-3">
                                <label htmlFor="fileInputLogo" className="">
                                    <div className="d-flex justify-content-center align-items-center flex-column uploadLogo">
                                        <i className="ph ph-cloud-arrow-up lg"></i>
                                        <p className="h4 text-light mb-1">Add logo</p>
                                        {/* <span className="h6 fst-italic text-light text-center">
                            Preferred: Square image with transparent background.
                            <br />
                            Accepted formats: JPG, PNG, SVG
                        </span> */}
                                    </div>
                                </label>
                                <input
                                    id="fileInputLogo"
                                    type="file"
                                    className="file-input"
                                    onChange={handleFileInputChange}
                                    onClick={(e) => {
                                        if (brandElements && brandElements.logo) {
                                            e.preventDefault();
                                            displayWarningToast(
                                                'Delete the current logo to add a new one',
                                            );
                                        }
                                    }}
                                    multiple={false}
                                />
                            </Form.Group>
                        ) : null}
                    </>
                )}

                <>
                    {brandElements && brandElements.logo && (
                        <div>
                            {removeLogoLoading ? (
                                <div className="SpinnerDiv">
                                    {/* <Spinner animation="border" className="custom-spinner" /> */}
                                </div>
                            ) : (
                                <>
                                    <div className="">
                                        <div className="uploadedLogo">
                                            <Image
                                                src={brandElements.logo}
                                                className="border border-primary rounded p-3 "
                                            />
                                            <button
                                                className="iconbtn iconbtn-transparent"
                                                onClick={() => handleDelete()}
                                            >
                                                <i className="ph-bold ph-prohibit-inset"></i>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};

export default DesignElementsLogo;
