/* eslint-disable */

import { useEffect, useState } from 'react';
import { selectCreateFlow } from '../CreateFlow/CreateFlowRedux/selector';
import { Image, Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../commons/hooks/redux';
import { getImageSize } from 'polotno/utils/image';
import { useDeleteAssetMutation, useGetUploadedAssetsQuery } from './EditorRedux/editor.api';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';
import { motion } from 'framer-motion';
import { displaySuccessToast, displayErrorToast } from '../../utils/ToastUtils';
import { unstable_registerNextDomDrop } from 'polotno/config';


const CustomImagePallett = ({ store }) => {
    const [page, setPage] = useState(1);
    const [imageList, setImageList] = useState([]);
    const { filteredImages } = useAppSelector(selectCreateFlow);
    const { data: previousAssets, isLoading: previousAssetsLoading, refetch: refetchAssets } = useGetUploadedAssetsQuery(page);
    const [deleteAsset, { isLoading: deleting }] = useDeleteAssetMutation();
    const [deletingIndex, setDeletingIndex] = useState(null);

    // for generated image dynamic height
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [hasNext, setHasNext] = useState(true);

    // refetch data if current page changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetchAssets();
            } catch (error) {
                if (error?.status === 500) {
                    displayErrorToast('Server Busy Please Try Again Later');
                } else {
                    displayErrorToast(error?.data?.message);
                }
            }
        };
        fetchData();
    }, [page, refetchAssets]);

    useEffect(() => {

        if (!previousAssets) return;

        setHasNext(previousAssets.hasNext);

        const combinedImages = Array.from(
            new Map(
                [
                    ...imageList,
                    ...previousAssets.records.map((image: any) => ({
                        ...image,
                        isSelected: false,
                    })),
                ].map((item) => [item.id, item]),
            ).values(),
        );

        setImageList(combinedImages);




    }, [previousAssets]);

    const fetchMoreData = () => {
        if (!hasNext) return ;
        setPage((prev) => prev + 1);
    };

    const handleDeleteAsset = async (id, event) => {
        event.stopPropagation();
        event.preventDefault();
        try {
            await deleteAsset(id);
            const updatedImageList = imageList.filter((image) => image.id !== id);
            setImageList(updatedImageList);
            displaySuccessToast('Deleted Successfully');
            await refetchAssets();
            
        } catch (error) {
            console.error('Error deleting asset:', error);
        } finally {
            setDeletingIndex(null);
        }
    };

    const loadImage = async (imageURL) => {
        const { width, height } = await getImageSize(imageURL);
        const element = store.selectedElements[0];
        if (!element?.type) {
            store.activePage.addElement({
                type: 'image',
                src: imageURL,
                width: 200,
                height: 200,
                x: store.width / 2 - width / 2,
                y: store.height / 2 - height / 2,
            });
        } else {
            try {
                if (element?.type === 'image') {
                    store.selectedElements[0].set({ src: imageURL });
                } else if (element?.type === 'svg') {
                    store.selectedElements[0].set({ maskSrc: imageURL });
                } else {
                    store.activePage.addElement({
                        type: 'image',
                        src: imageURL,
                        width: 200,
                        height: 200,
                        x: store.width / 2 - width / 2,
                        y: store.height / 2 - height / 2,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        const selected = store.selectedElements[0];
        if (selected) {
            console.log('THIS IS THE SELECTED ELEMENT', selected.name);
        }
    }, [store.selectedElements, store]);

    return (
        <div className="addFavourite-content">
            <div>
                <InfiniteScroll
                    dataLength={imageList.length || 1}
                    next={fetchMoreData}
                    hasMore={previousAssets?.hasNext}
                    loader={
                        <div
                            className="w-100 d-flex mb-4 justify-content-center align-item-center"
                            style={{ padding: '24px', height: '180px' }}
                        >
                            <Spinner
                                as="span"
                                className="mr-1 pe-none"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                                style={{
                                    marginTop: '40px',
                                    width: '48px',
                                    height: '48px',
                                    color: '#967AA7',
                                    background: 'transparent',
                                }}
                            />
                        </div>
                    }
                    height={windowHeight}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    className=""
                >
                    <Masonry breakpointCols={3} className="pixii-grid m-1" columnClassName="pixii-grid_column">
                        {imageList &&
                            imageList.map((image, index) => (
                                <div key={index}>
                                    <motion.div
                                        key={index}
                                        initial={{ scale: 0.8, opacity: 0 }}
                                        animate={{ scale: 1, opacity: 1 }}
                                        exit={{ scale: 0.8, opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                        className="m-0"
                                        onClick={() => {
                                            loadImage(image.assets);
                                        }}
                                    >
                                        {deletingIndex === image.id ? (
                                            // Show loader while deleting
                                            <div>Loading...</div>
                                        ) : (
                                            <div className="p-2  image-delete">
                                                <Image
                                                    src={image.assets}
                                                    fluid
                                                    rounded
                                                    className="border border-grey"
                                                    style={{ cursor: 'pointer' }}
                                                    crossOrigin="anonymous"
                                                    onDrag={() => {
                                                        unstable_registerNextDomDrop((pos, element) => {
                                                            if (element && element.type === 'image') {
                                                                element.set({ src: image.assets });
                                                            } else if (element && element.type === 'svg') {
                                                                element.set({ maskSrc: image.assets });
                                                            } else {
                                                                store.activePage.addElement({
                                                                    type: 'image',
                                                                    src: image.assets,
                                                                    x: pos.x,
                                                                    y: pos.y,
                                                                    width: 100,
                                                                    height: 100,
                                                                });
                                                            }
                                                        });
                                                    }}
                                                    onDragEnd={() => {
                                                        unstable_registerNextDomDrop(null);
                                                    }}
                                                />
                                                <button
                                                    className="iconbtn iconbtn-transparent"
                                                    onClick={(event) => handleDeleteAsset(image.id, event)}
                                                >
                                                    <i className="ph-bold ph-prohibit-inset lg"></i>
                                                </button>
                                            </div>
                                        )}
                                    </motion.div>
                                </div>
                            ))}
                    </Masonry>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default CustomImagePallett;
