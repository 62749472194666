import { useEffect } from 'react';

const useApprenticeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
    useEffect(() => {
        const isMobile = window.innerWidth <= 768;

        if (textAreaRef) {
            const minTextAreaHeight = isMobile ? 46 : 56;
            const maxTextAreaHeight = isMobile ? 175 : 200;

            textAreaRef.style.height = `${minTextAreaHeight}px`;
            const scrollHeight = textAreaRef.scrollHeight;

            if (!value) {
                textAreaRef.style.height = `${minTextAreaHeight}px`;
                textAreaRef.style.overflowY = 'hidden';
            } else {
                const newHeight = Math.min(scrollHeight, maxTextAreaHeight);
                textAreaRef.style.height = `${newHeight}px`;
                textAreaRef.style.overflowY = 'auto';
                textAreaRef.style.overflowY = newHeight === maxTextAreaHeight ? 'auto' : 'hidden';
            }
        }
    }, [textAreaRef, value]);
};

export default useApprenticeTextArea;
