import React, { useEffect, useState, useRef, memo } from 'react';
import { Button, Card, Dropdown, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import {
    useCreateProjectMutation,
    useDeleteProjectMutation,
    useDuplicateProjectMutation,
    useGetProjectsQuery,
    useRenameProjectMutation,
    useLazyGetProjectByIdQuery,
} from './EditorRedux/editor.api';
import config from '../../config';
import {
    saveProjectDetails,
    persistEditorData,
    updateProject,
    updateLoadingState,
    callSaveApi,
    updateApprenticeViewState,
    saveProjectTemplate,
    updateIsFreshStart,
    updateProjectCreation,
} from './EditorRedux/editorSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../../components/AppElements/ProjectDelete';
import { toast } from 'react-toastify';
import { allowedExtensionsProject } from '../../constants/createFlow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useCreateAndPersistProject, useFetchProjectById } from '../../utils/projectUtils';
import { displayErrorToast, displaySuccessToast } from '../../utils/ToastUtils';
import Masonry from 'react-masonry-css';
import TemplateModal from '../../components/AppElements/TemplateModal';
import CustomModal from '../../components/AppElements/Modal';
import NewProjectModal from '../../components/AppElements/NewProjectModal';
import { selectEditFlow } from './EditorRedux/selector';
import { setApprenticeTemplate, setExtraTemplateFields } from './EditorRedux/templateSlice';
import { usePresignTemplateMutation } from '../Apprentice/ApprenticeRedux/apprentice.api';
import { calculateJSONHash } from '../../utils/TemplateUtils';

const CustomProject = ({ store }) => {
    // Initialize the current currentPage state
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingSaveProject, setLoadingSaveProject] = useState(false);
    const { project } = useSelector(selectEditFlow);
    const { data, isLoading, refetch } = useGetProjectsQuery(currentPage === 0 ? 1 : currentPage);
    const [projects, setProjectsData] = useState([]);
    // Separate state for newly fetched data
    const [newlyFetchedData, setNewlyFetchedData] = useState([]);
    const { createAndPersistProject } = useCreateAndPersistProject();
    const [templateUpload, showTemplateShow] = useState(false);
    const [deleteProject, { isLoading: deleteLoading }] = useDeleteProjectMutation();
    const [presignTemplate] = usePresignTemplateMutation();
    const [renameProject] = useRenameProjectMutation();
    const [duplicateProject] = useDuplicateProjectMutation();
    const [createProject, { isLoading: waiting }] = useCreateProjectMutation();
    const activeUserId = useSelector((state) => state.editFlow.activeUserId);
    const saveToCloud = useSelector((state) => state.editFlow.saveToCloud);
    const apprenticeViewMode = useSelector((state) => state.editFlow.apprenticeView);
    const currentProjectDetails = useSelector((state) => state.editFlow.project);
    const loadingState = useSelector((state) => state.editFlow.loadingState);

    const [showDelete, setShowDelete] = useState(false);
    const [id, setId] = useState(null);
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingProject, setLoadingProject] = useState(false);
    const [isProjectClicked, setIsprojectClicked] = useState(false);
    const [projectToLoad, setProjectToLoad] = useState();
    // const [items, setItems] = useState(Array.from({ length: 20 }));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);

    const dispatch = useDispatch();
    const [fetchProjectId, setFetchProjectId] = useState(null);

    const projectQuery = useFetchProjectById(fetchProjectId);
    const [getProjectById] = useLazyGetProjectByIdQuery();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openNewProjectModal = () => {
        setIsNewProjectModalOpen(true);
    };

    const closeNewProjectModal = () => {
        setIsNewProjectModalOpen(false);
    };

    function parseProjectId(currentProjectDetails) {
        let projectIdAsInt;
        if (currentProjectDetails.id === 'null') {
            projectIdAsInt = null;
        } else {
            projectIdAsInt = parseInt(currentProjectDetails.id, 10);
        }
        return projectIdAsInt;
    }

    const handleYes = async () => {
        let projectTemplate;
        const projectJSON = store.toJSON();
        let projectName = '';
        projectName = window.prompt('Enter Project name:');

        // If the user clicks "Cancel" or provides an empty string, treat it as a cancellation
        if (projectName === null || projectName.trim() === '') {
            return; // Exit the function without further processing
        }

        setLoadingSaveProject(true);
        const previewImg = await imagePreviewDataURL(projectJSON);

        if (projectName) {
            await imagePreviewDataURL(projectJSON).then(async (previewImg) => {
                projectTemplate = {
                    name: projectName,
                    json: projectJSON,
                    imgDataURL: previewImg,
                };
                // IF ERROR CLOSE MODAL closeModal();

                try {
                    await createProject(projectTemplate).then((res) => {
                        const projectData = res?.data?.data;
                        updateProjectState({
                            userId: projectData?.userId,
                            projectId: projectData?.id,
                            projectName: projectData?.name,
                            projectJson: projectData?.json,
                        });
                        if (projectToLoad) {
                            dispatch(updateLoadingState(true));
                            setFetchProjectId(projectToLoad);
                            setIsprojectClicked((prev) => !prev);
                            dispatch(callSaveApi(false));
                        }
                        dispatch(callSaveApi(false));
                        closeModal();
                    });
                    // displaySuccessToast('Saved project successfully');
                } catch (error) {
                    // dispatch(updateApprenticeViewState(false));
                    displayErrorToast('Failed to save project'); // console.log(error, "Error while saving Project Template");
                } finally {
                    setLoadingSaveProject(false);
                    closeModal();
                }
            });
        }
    };

    const handleNo = (event) => {
        event.stopPropagation();
        if (projectToLoad) {
            dispatch(callSaveApi(false));
            dispatch(updateLoadingState(true));
            setFetchProjectId(projectToLoad);
            setIsprojectClicked((prev) => !prev);
        }
        dispatch(updateLoadingState(true));
        dispatch(callSaveApi(false));
        closeModal();
        // navigate('/');
    };

    const handleNewProjectYes = async () => {
        const projectName = window.prompt('Enter Project name:');
        if (projectName && store) {
            dispatch(updateLoadingState(true));
            const projectJSON = store.toJSON();
            const imgURL = await imagePreviewDataURL(projectJSON);
            const projectTemplate = {
                name: projectName,
                json: projectJSON,
                imgDataURL: imgURL,
                isImportedOrCreated: true, // FOR IMPORTED OR NEWLY CREATED PROJECT
            };
            try {
                imgURL &&
                    (await createProject(projectTemplate)
                        .unwrap()
                        .then(() => {
                            closeNewProjectModal();
                            store.clear(); // Clears all elements from the canvas
                            store.addPage(); //Add a new blank currentPage
                            dispatch(updateIsFreshStart(true));
                            dispatch(updateApprenticeViewState(false));
                            dispatch(updateProject({ id: null, name: 'Untitled' }));
                            dispatch(callSaveApi(false));

                            dispatch(updateLoadingState(false));
                        }));
                // displaySuccessToast('saved changes successfully!');
            } catch (error) {
                dispatch(updateLoadingState(false));
                console.log(error, 'Error while saving Project Template');
            }
        }
    };

    const handleNewProjectNo = (event) => {
        event.stopPropagation();
        dispatch(updateApprenticeViewState(false));
        closeNewProjectModal();
        store.clear(); // Clears all elements from the canvas
        store.addPage();
        dispatch(updateLoadingState(false));
    };
    // refetch data if current page changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {
                console.error('Refetch error:', error);
            } finally {
                setLoadingProject(false);
            }
        };

        // Refetch data if the currentPage changes
        fetchData();
    }, [currentPage, refetch]);
    useEffect(() => {
        // Append the newly fetched data to the existing projects when the currentPage changes
        if (newlyFetchedData !== undefined && (currentPage === 1 || currentPage === 0)) {
            setProjectsData(newlyFetchedData?.records);
        } else if (newlyFetchedData !== undefined && currentPage > 1) {
            setProjectsData((prevProjects) => [...prevProjects, ...newlyFetchedData?.records]);
        }
    }, [newlyFetchedData]);

    useEffect(() => {
        setNewlyFetchedData(data);
    }, [data, currentPage]);

    const updateProjectState = (projectDetails) => {
        const { projectId, projectName, projectJson, userId } = projectDetails;
        dispatch(updateProject({ id: projectId, name: projectName }));
        dispatch(persistEditorData(projectJson));
        const payload = {
            userId: userId || activeUserId,
            projectId: projectId,
            projectName: projectName,
            projectJson: projectJson,
        };
        dispatch(saveProjectDetails(payload));
        store.loadJSON(projectJson);
        // dispatch(callSaveApi(false));
    };
    const handleSaveToPC = async (name, id) => {
        const res = await getProjectById(id);
        const jsonStr = JSON.stringify(res.data.json, null, 2);
        const blob = new Blob([jsonStr], { type: 'application/json' });
        const fileName = name;
        if (fileName) {
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();

            URL.revokeObjectURL(url);
        }
    };
    const onDeleteClick = (projectId) => {
        setId(projectId);
        setShowDelete(true);
    };
    const setPage = () => {
        setCurrentPage((prev) => {
            switch (prev) {
                case 0:
                    return 1;
                case 1:
                    return 0;
                default:
                    return 1;
            }
        });
    };
    const handleDeleteClick = async () => {
        dispatch(updateLoadingState(true));
        try {
            const idToDelete = id;
            await deleteProject(idToDelete).then(async () => {
                setPage();

                setShowDelete(false);
                let openProject = null;
                if (projects?.length) {
                    for (let i = 0; i < projects?.length; i++) {
                        if (projects[i].id === idToDelete) {
                            continue;
                        } else {
                            openProject = projects[i].id;
                            break;
                        }
                    }
                }
                if (openProject) {
                    const res = await getProjectById(openProject);

                    localStorage.setItem('projectID', res.data.id);
                    dispatch(updateProject({ id: res.data.id, name: res.data.name }));
                    store.loadJSON(res.data.json);
                } else {
                    dispatch(persistEditorData(null));
                    store.clear();
                }
            });
            dispatch(updateLoadingState(false));

            // setId(null);
            // displaySuccessToast('Deleted successfully');
        } catch (error) {
            console.log(error, 'Error while deleting project');
            setShowDelete(false);
            dispatch(updateLoadingState(false));
        }
    };
    const handleNewProject = async (e) => {
        e.preventDefault();

        const projectName = window.prompt('Enter Project name:');
        if (projectName && store) {
            // New project Creation
            dispatch(updateProjectCreation(1));
            store.clear(); // Clears all elements from the canvas
            store.addPage(); //Add a new blank currentPage
            await store.waitLoading();
            const new_json = store.toJSON();

            dispatch(updateLoadingState(true));

            try {
                await createProjectAndUpdateShowInEditor({
                    name: projectName,
                    json: new_json,
                    isImportedOrCreated: true, // FOR IMPORTED OR NEWLY CREATED PROJECT
                });
                dispatch(updateProjectCreation(-1));
            } catch (error) {
                dispatch(updateProjectCreation(-1));
                dispatch(updateLoadingState(false));
                console.log(error, 'Error while saving Project Template');
            }
        }
    };
    const imagePreviewDataURL = async (json) => {
        try {
            const req = await fetch(
                `https://api.polotno.com/api/render?KEY=${process.env.REACT_APP_POLOTNO_API_KEY}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        // polotno json from store.toJSON()
                        design: json,
                        // optional output format for export
                        outputFormat: 'url',
                        // optional export options for store.toDataURL() method
                        exportOptions: {},
                    }),
                },
            );

            const { url } = await req.json();
            return url;
        } catch (error) {
            displayErrorToast('Server is busy, please try again later.');
            setLoading(false);
            console.log('error', error);
        }
    };
    const handleDupilcateClick = async (nameTag, projectId) => {
        const payload = {
            id: projectId,
            data: {
                projectName: nameTag + '(copy)',
            },
        };
        try {
            await duplicateProject(payload);
        } catch (error) {
            toast('Error while duplicating the project, Please Try Again', {
                type: 'warning',
            });
        }
    };
    const saveProject = async (previousProjectId, projectJSON) => {
        if (projectJSON) {
            const projectTemplate = {
                id: previousProjectId,
                json: projectJSON,
                updateImage: true,
                jsonUpdate: true,
            };
            try {
                await createProject(projectTemplate).unwrap();
            } catch (error) {
                dispatch(updateLoadingState(false));
                console.log(error, 'Error while saving Project Template');
            }
        }
    };
    const handleProjectLoad = async (id) => {
        dispatch(updateIsFreshStart(false));
        if (apprenticeViewMode) {
            await projectQuery.refetch({ id }).then((res) => {
                const projectData = res?.data;
                localStorage.setItem('projectID', id);
                dispatch(updateProject({ id: projectData?.id, name: projectData?.name }));
                dispatch(setExtraTemplateFields(null));
                dispatch(setApprenticeTemplate(null));
                store.loadJSON(projectData?.json);
                dispatch(updateLoadingState(false));
                dispatch(updateApprenticeViewState(false));
                dispatch(callSaveApi(false));
            });
            return;
        }
        const projectJSON = store.toJSON();
        const projectIdAsInt = parseProjectId(currentProjectDetails);

        if (id !== projectIdAsInt && projectIdAsInt !== null && projectJSON) {
            try {
                if (saveToCloud) {
                    // dispatch(updateApprenticeViewState(false));
                    await saveProject(projectIdAsInt, projectJSON);
                    dispatch(callSaveApi(false));
                    dispatch(updateLoadingState(false));
                    displaySuccessToast('Project Saved');
                }

                await projectQuery.refetch({ id }).then((res) => {
                    const projectData = res?.data;
                    localStorage.setItem('projectID', id);
                    dispatch(updateProject({ id: projectData?.id, name: projectData?.name }));
                    store.loadJSON(projectData?.json);
                    dispatch(setExtraTemplateFields(null));
                    dispatch(setApprenticeTemplate(null));
                    dispatch(callSaveApi(false));
                    dispatch(updateLoadingState(false));
                });
            } catch (error) {
                dispatch(updateLoadingState(false));
                console.error('Error saving or refetching project:', error);
            }
        } else if (id === projectIdAsInt) {
            await projectQuery.refetch({ id }).then((res) => {
                const projectData = res?.data;
                localStorage.setItem('projectID', id);
                dispatch(updateProject({ id: projectData?.id, name: projectData?.name }));
                store.loadJSON(projectData?.json);
                dispatch(setExtraTemplateFields(null));
                dispatch(setApprenticeTemplate(null));
                dispatch(callSaveApi(false));
                dispatch(updateLoadingState(false));
            });
        }
    };
    const handleRenameProject = async (currentName, id) => {
        const newName = window.prompt('New project name', currentName);
        if (newName) {
            const res = await renameProject({
                id: id,
                data: {
                    name: newName,
                },
            });
            if (res.error) {
                displayErrorToast('There was an error saving name');
            } else {
                displaySuccessToast('Successfully changed name');
            }
        }
        // await projectQuery.refetch({ id }).then((res) => {
        //     const projectData = res?.data;
        //     localStorage.setItem('projectID', id);
        //     console.log(id, 'LOAD GARDA SAVE MA XU');
        //     dispatch(updateProject({ id: projectData?.id, name: projectData?.name }));
        //     store.loadJSON(projectData?.json);
        // });
    };

    useEffect(() => {
        if (fetchProjectId) {
            handleProjectLoad(fetchProjectId);
        }
    }, [isProjectClicked, fetchProjectId]);

    let previousFile = null; // Define it outside the function

    const createProjectAndUpdateShowInEditor = async (Json_template) => {
        try {
            const previewImg = await imagePreviewDataURL(Json_template.json);
            Json_template.imgDataURL = previewImg;
            store.loadJSON(Json_template?.json);
            const res = await createProject(Json_template);
            const projectData = res?.data.data;
            dispatch(updateProject({ id: projectData?.id, name: projectData?.name }));
            localStorage.setItem('projectID', projectData?.id);
            setFetchProjectId(projectData.id);
            dispatch(updateApprenticeViewState(false));
            dispatch(callSaveApi(false));
            dispatch(updateLoadingState(false));
        } catch (error) {
            console.log(error);
            dispatch(updateLoadingState(false));
        }
    };

    const handleFileInputChange = async (event) => {
        event.preventDefault();
        dispatch(callSaveApi(true));
        dispatch(updateProjectCreation(1));
        const fileInput = event.target;
        const fileList = fileInput.files;
        let jsonData;
        let fileName;
        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            if (file === previousFile) {
                // The same file has been selected again, reset the input value
                fileInput.value = '';
                return;
            }
            previousFile = file;
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            fileName = file.name.replace(`.${fileExtension}`, '');
            if (!allowedExtensionsProject.includes(fileExtension)) {
                toast('Only JSON files are allowed.', { type: 'warning' });
                // setError("Only JSON files are allowed.");
                return;
            }
            const reader = new FileReader();

            reader.onload = async (e) => {
                try {
                    setLoading(true);
                    dispatch(updateLoadingState(true));
                    const fileContent = e?.target?.result;
                    jsonData = JSON.parse(fileContent);
                    const payload = {
                        template: jsonData,
                    };
                    const response = await presignTemplate(payload);
                    if (response && response.data) {
                        jsonData = response.data;
                    }
                    await createProjectAndUpdateShowInEditor({
                        name: fileName,
                        json: jsonData,
                        isImportedOrCreated: true,
                    });
                    dispatch(updateProjectCreation(-1));
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.error('Error parsing JSON data:', error);
                    // setError('Error parsing JSON file.');
                    dispatch(updateProjectCreation(-1));
                }
            };
            dispatch(updateIsFreshStart(false));
            reader.readAsText(file);
            // setError('');
        }
    };

    const [isIntersecting, setIsIntersecting] = useState(false);
    const handleIntersection = (entries) => {
        const entry = entries[0];
        setIsIntersecting(entry.isIntersecting);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5, // Trigger when 50% of the target is in the viewport
        });

        // Attach the observer to the target element using the ref
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    const fetchMoreData = () => {
        setCurrentPage((prev) => (prev === 0 ? 2 : prev + 1));
    };
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            if (isMobile) {
                setWindowHeight(window.innerHeight * 0.5 - 64);
                // setWindowHeightTemp(window.innerHeight * 0.5 - 64);
            } else {
                setWindowHeight(window.innerHeight - 135);
                // setWindowHeightTemp(window.innerHeight - 135);
            }
        };

        handleResize(); // Set initial height on component mount
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const HandleProjectClick = (data) => {
        if (data.id === fetchProjectId) return;
        dispatch(updateLoadingState(true));
        dispatch(saveProjectTemplate(''));
        setFetchProjectId(data?.id);
        setIsprojectClicked((prev) => !prev);
        setProjectToLoad(data.id);
    };
    return (
        <>
            <div className="project">
                <div className="project-header m-2">
                    <h2 className="text-dark fw-semibold">Projects</h2>
                    <div className="project-header_button">
                        <Form.Group className="form-group">
                            <Button
                                variant="outline-primary"
                                size="lg"
                                onClick={() => {
                                    dispatch(setApprenticeTemplate(null));
                                    dispatch(setExtraTemplateFields(null));
                                    const fileInput = document.getElementById('fileInput');
                                    if (fileInput) {
                                        fileInput.click(); // Trigger the file input click when the button is clicked
                                    }
                                }}
                            >
                                {loading ? (
                                    <Spinner
                                        as="span"
                                        className="mr-1 pe-none"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                ) : (
                                    'Import'
                                )}
                            </Button>
                            <input
                                id="fileInput"
                                type="file"
                                className="file-input"
                                onChange={(event) => {
                                    try {
                                        handleFileInputChange(event);
                                    } catch (error) {
                                        console.log(error.message);
                                    }
                                }}
                            />
                        </Form.Group>

                        <TemplateModal
                            show={templateUpload}
                            onHide={() => showTemplateShow(false)}
                            store={store}
                        />
                        <Button
                            variant="primary"
                            onClick={(event) => {
                                dispatch(setApprenticeTemplate(null));
                                dispatch(setExtraTemplateFields(null));
                                handleNewProject(event);
                            }}
                            size="lg"
                        >
                            <i className="ph ph-plus sm"></i> New project
                        </Button>
                    </div>
                </div>
                <div className="project-container  mt-3">
                    {isLoading || loadingProject ? (
                        <div className="SpinnerDiv">
                            <Spinner
                                animation="border"
                                style={{ marginTop: '40px' }}
                                className="custom-spinner"
                            />
                        </div>
                    ) : projects && projects?.length > 0 ? (
                        <>
                            <InfiniteScroll
                                dataLength={projects?.length}
                                next={fetchMoreData}
                                hasMore={data?.hasNext}
                                loader={
                                    <div
                                        className="w-100 d-flex justify-content-center align-item-center"
                                        style={{ height: '100px', padding: '24px' }}
                                    >
                                        <Spinner
                                            as="span"
                                            className="mr-1 pe-none"
                                            role="status"
                                            aria-hidden="true"
                                            animation="border"
                                            style={{
                                                marginTop: '40px',
                                                width: '48px',
                                                height: '48px',
                                                color: '#967AA7',
                                                background: 'transparent',
                                            }}
                                        />
                                    </div>
                                }
                                height={windowHeight}
                                // style={{ height: windowHeight, marginBottom: '6rem' }}
                                endMessage={
                                    <p
                                        style={{ textAlign: 'center' }}
                                        className="text-primary fw-light"
                                    >
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <Masonry
                                    breakpointCols={2}
                                    className="pixii-grid project-contents m-2"
                                    columnClassName="pixii-grid_column"
                                >
                                    {projects?.map((data, index) => (
                                        <div key={index}>
                                            <Card
                                                className="project-content box-shadow"
                                                // style={{ width: '185.5', height: 218 }}
                                            >
                                                {/* <div style={{ width: '170.5', height: 170 }}> */}
                                                <Card.Img
                                                    onClick={() => HandleProjectClick(data)}
                                                    variant="top"
                                                    src={data?.preview}
                                                    alt={`${data.name}`}
                                                />
                                                {/* </div> */}

                                                <Card.Title className="p-2 mb-0">
                                                    <h3 className="text-ellipsis mb-0">
                                                        {data.name}
                                                    </h3>
                                                </Card.Title>
                                                <div className="project-options">
                                                    <InputGroup className="custom-dropdown">
                                                        <Dropdown autoClose="click">
                                                            <Dropdown.Toggle
                                                                variant="outline-primary"
                                                                className="dropdown__toggle iconbtn"
                                                                id="dropdown-autoclose-inside"
                                                            >
                                                                <i className="ph-fill ph-dots-three-outline "></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown__toggle--menu">
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        if (
                                                                            data.id !==
                                                                            fetchProjectId
                                                                        ) {
                                                                            dispatch(
                                                                                updateLoadingState(
                                                                                    true,
                                                                                ),
                                                                            );
                                                                            setFetchProjectId(
                                                                                data?.id,
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="ph ph-arrow-square-out lg"></i>
                                                                    Open
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        handleSaveToPC(
                                                                            data.name,
                                                                            data.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="ph ph-archive-tray lg"></i>
                                                                    Save to PC
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        handleRenameProject(
                                                                            data.name,
                                                                            data.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="ph  ph-pencil-simple-line lg"></i>
                                                                    Rename
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        handleDupilcateClick(
                                                                            data.name,
                                                                            data.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="ph ph-copy lg"></i>{' '}
                                                                    Duplicate
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        onDeleteClick(data.id)
                                                                    }
                                                                    disabled={isLoading}
                                                                >
                                                                    <i className="pxi-trash lg"></i>
                                                                    Delete
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </InputGroup>
                                                </div>
                                            </Card>
                                        </div>
                                    ))}
                                    {/* </div> */}
                                </Masonry>
                            </InfiniteScroll>
                        </>
                    ) : (
                        <h1>No Project</h1>
                    )}
                    {/* <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMoreData1}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        height={400}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        {items.map((i, index) => (
                            <div style={style} key={index}>
                                div - #{index}
                            </div>
                        ))}
                    </InfiniteScroll> */}
                </div>
            </div>
            <DeleteModal
                onDelete={handleDeleteClick}
                loading={deleteLoading}
                onHide={() => {
                    setShowDelete(false);
                    setId(null);
                }}
                show={showDelete}
            />
            {isModalOpen && (
                <>
                    <CustomModal
                        title="Do you want to save the project?"
                        show={isModalOpen}
                        onHide={closeModal}
                        buttonText="Yes"
                        isLoading={loadingSaveProject}
                        buttonFunction={handleYes}
                        secondButtonText="No"
                        secondButtonFunction={handleNo}
                    />
                </>
            )}
            {isNewProjectModalOpen && (
                <>
                    <NewProjectModal
                        title="Do you want to save the project?"
                        show={isNewProjectModalOpen}
                        onHide={closeNewProjectModal}
                        isLoading={loadingState}
                        buttonText="Yes"
                        buttonFunction={handleNewProjectYes}
                        secondButtonText="No"
                        secondButtonFunction={handleNewProjectNo}
                    />
                </>
            )}
        </>
    );
};

export default memo(CustomProject);
