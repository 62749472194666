import React, { useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import paintBucket from '../../../../assets/images/paint-bucket.svg';
import { IGetBrandElements } from '../../../BrandMagic/BrandMagicRedux/BrandMagicTypes';
import { Button, Spinner } from 'react-bootstrap';
import {
    useAddBrandElementsColorMutation,
    useDeleteBrandElementsColorMutation,
} from '../../../BrandMagic/BrandMagicRedux/brandMagic.api';

interface IProps {
    brandElements: IGetBrandElements | undefined;
    brandElementsLoading: boolean;
}

const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const ColorPicker: React.FC<IProps> = ({ brandElements, brandElementsLoading }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
    const [currentColor, setCurrentColor] = useState<string>(''); // Default color
    const [colorLimitationError, setColorLimitationError] = useState<boolean>(false);
    const [addColor, { isLoading: addColorLoading }] = useAddBrandElementsColorMutation();
    const [deleteColor, { isLoading: deleteColorLoading }] = useDeleteBrandElementsColorMutation();
    const [deleteId, setDeleteId] = useState<string | null>(null);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = async () => {
        setDisplayColorPicker(false);

        if (currentColor.length > 0) {
            await addColor({ color: currentColor });
            setCurrentColor('');
        }
    };

    const handleSaveColor = async () => {
        setDisplayColorPicker(false);

        if (brandElements && brandElements.colors && brandElements.colors.length === 8) {
            setColorLimitationError(true);
            setTimeout(() => {
                setColorLimitationError(false);
            }, 3000);
            return;
        }

        if (currentColor.length > 0) {
            await addColor({ color: currentColor });
            setCurrentColor('');
        }
    };

    const handleColorChange = (color: ColorResult) => {
        const newColor = color.hex;
        const alpha = color.rgb.a !== undefined ? color.rgb.a : 1; // Default alpha to 1 if undefined
        const rgbaColor = hexToRgba(newColor, alpha);
        setCurrentColor(rgbaColor);
    };

    const handleDeleteColor = async (color: string) => {
        if (deleteId === null) {
            setDeleteId(color);
            await deleteColor({ color: color }).then(() => {
                setDeleteId(null);
            });
        }
    };

    return (
        <div className="colorpicker mb-4 p-3 designElements">
            <h1 className="fw-bold text-primary">Colors</h1>
            <span className="h5 text-light text-center m-0 ms-3 me-3 fw-regular color_title">
                Primary
            </span>
            |
            <span className="h5 text-light text-center m-0 ms-3 me-3 fw-regular color_title">
                Secondary
            </span>
            |
            <span className="h5 text-light text-center m-0 ms-3 me-3 fw-regular color_title">
                Tertiary
            </span>
            <div className="colorpicker_picker d-flex align-items-center">
                {!brandElementsLoading &&
                    brandElements &&
                    brandElements.colors &&
                    brandElements.colors.length > 0 &&
                    brandElements.colors
                        .slice()
                        .reverse()
                        .map((color) => {
                            return (
                                <div key={color}>
                                    {deleteColorLoading && deleteId === color ? (
                                        <div className="SpinnerDiv mt-3 me-3" key={color}>
                                            {/* <Spinner
                                                animation="border"
                                                className="custom-spinner"
                                            /> */}
                                        </div>
                                    ) : (
                                        <div
                                            key={color}
                                            className="selected-color border border-grey "
                                            style={{ backgroundColor: color }}
                                        >
                                            <button
                                                className="iconbtn iconbtn-transparent"
                                                onClick={() => handleDeleteColor(color)}
                                            >
                                                <i className="ph-bold ph-prohibit-inset"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                {(addColorLoading || brandElementsLoading) && (
                    <div className="SpinnerDiv mt-3 me-3">
                        {/* <Spinner animation="border" className="custom-spinner" /> */}
                    </div>
                )}
                <div className="colorpicker-div">
                    <button onClick={handleClick} className="picker-selection mt-3">
                        <img src={paintBucket} />
                        <span>Add Color</span>
                    </button>
                    {displayColorPicker ? (
                        <div className="colorpicker-popover mt-2">
                            <div className="colorpicker-cover" onClick={handleClose} />
                            <SketchPicker
                                color={currentColor}
                                onChangeComplete={handleColorChange}
                                disableAlpha={false}
                                presetColors={[
                                    '#6f4e84',
                                    '#B28CB4',
                                    '#D6F6DD',
                                    '#dac4f7',
                                    '#f4989c',
                                    '#ebd2b4',
                                    '#acecf7',
                                    '#090c02',
                                ]}
                            />
                            <div className="mt-2 d-flex justify-content-end">
                                <Button
                                    variant="primary"
                                    onClick={handleSaveColor}
                                    className="pe-2 ps-2 pt-1 pb-1"
                                >
                                    <span className="fs-6">Save</span>
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            {/* ERROR MESSAGE */}
            {colorLimitationError && (
                <div className="d-flex align-item-center h5 text-danger fw-semibold mt-3">
                    Oops! Max 8 colors allowed.
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
