import React, { Dispatch, SetStateAction } from 'react';
import { Image } from 'react-bootstrap';

enum ImageType {
    URL = 'url',
    FILE = 'file',
    NONE = 'none',
}

interface imageData {
    imageType: ImageType;
    data: File | string | null;
}
interface productImageProps {
    imageData: imageData;
    isImageDataPresent: boolean;
}

interface ShowImageProps {
    productImage: productImageProps;
    visionImage: productImageProps;
    handleRemoveImage: Dispatch<SetStateAction<string | null>>;
}

export const ShowImage: React.FC<ShowImageProps> = ({
    handleRemoveImage,
    productImage,
    visionImage,
}) => {
    if (!productImage.isImageDataPresent || !productImage.imageData.data) return null;

    const IsUrl = productImage.imageData.imageType === ImageType.URL;

    const src = IsUrl
        ? (productImage.imageData.data as string)
        : URL.createObjectURL(productImage.imageData.data as File);

    return (
        <div className="productImage">
            <div className="productImage-img">
                <Image src={src} fluid />
                <button className="close" onClick={() => handleRemoveImage(null)}>
                    <i className="ph-bold ph-prohibit-inset"></i>
                </button>
            </div>
        </div>
    );
};
