export const brandVoiceLabels: any = {
    brandName: 'Brand Name',
    keyProductService: 'Key Products or Services',
    keyMessages: 'Key Messages',
    tone: 'Tone',
    weDo: 'We Do',
    weDoNot: 'We Do Not',
    scrapedTextContent: 'Provide some writing that reflects your brand voice',
    anythingElse: 'What else do you want me to know?',
};
export const brandVoicePlaceHolders: any = {
    brandName: `What customers call you `,
    keyProductService: 'List your offerings separated by commas ',
    keyMessages: 'The core values and benefits you communicate to customers ',
    tone: 'The style and mood of your brand (e.g. informed and authoritative)  ',
    weDo: 'What kind of messages or tone should Pixii try to use?',
    weDoNot: 'What should Pixii avoid saying?',
};
