import { baseApiSlice } from '../../../commons/base-api.config';
import {
    API_BRAND_MAGIC_FETCH_COPY_INFO,
    API_BRAND_MAGIC_FORMAT,
    API_BRAND_MAGIC_ONBRAND_POTION,
    API_BRAND_MAGIC_TARGET_CUSTOMER,
    API_BRAND_MAGIC_VOICE,
    API_BRAND_MAGIC_ADD_COLOR,
    API_BRAND_MAGIC_GET_BRAND_ELEMENTS,
    API_BRAND_MAGIC_REMOVE_COLOR,
    API_BRAND_MAGIC_ADD_FONT,
    API_BRAND_MAGIC_REMOVE_FONT,
    API_DEFAULT_POTIONS,
    API_BRAND_IMAGE_GET_USER_FAVOURITES,
    API_BRAND_MAGIC_SCRAPING_URL,
    API_BRAND_MAGIC_ADD_LOGO,
    API_BRAND_MAGIC_REMOVE_LOGO,
    API_AD_PREFERENCE,
} from '../../../constants/api';
import { HTTP_METHODS } from '../../../constants/http';
import {
    IAddBrandElementsFont,
    IAddColor,
    IGetBrandElements,
    IRemoveBrandElementsFont,
    IRemoveColor,
} from './BrandMagicTypes';

export const brandMagicApiSlice = baseApiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            getDefaultPotions: builder.query<any, void>({
                query() {
                    return {
                        url: API_DEFAULT_POTIONS,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: [],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getUserImageFavourites: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_IMAGE_GET_USER_FAVOURITES,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['imagePotion'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteImageFavouritesById: builder.mutation({
                query: (id) => {
                    return {
                        url: `${API_BRAND_IMAGE_GET_USER_FAVOURITES}/${id}`,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                invalidatesTags: ['imagePotion'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            saveBrandVoice: builder.mutation({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_VOICE,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                invalidatesTags: ['brandVoice', 'brandElements'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getBrandVoice: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_MAGIC_VOICE,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['brandVoice'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            saveBrandFormat: builder.mutation({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_FORMAT,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                invalidatesTags: ['brandFormat'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getBrandFormat: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_MAGIC_FORMAT,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['brandFormat'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            saveBrandTargetCustomer: builder.mutation({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_TARGET_CUSTOMER,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                invalidatesTags: ['targetCustomer'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getBrandTargetCustomer: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_MAGIC_TARGET_CUSTOMER,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['targetCustomer'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            uploadAdPreference: builder.mutation<any, FormData>({
                query: (formData) => ({
                    url: API_AD_PREFERENCE,
                    method: HTTP_METHODS.PATCH,
                    body: formData,
                }),
                invalidatesTags: [],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            fetchAdPreference: builder.query<any, void>({
                query() {
                    return {
                        url: API_AD_PREFERENCE,
                        method: 'GET',
                    };
                },
                providesTags: ['fetchAdPreferences'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteAdPreference: builder.mutation<void, number>({
                query: (id) => ({
                    url: `${API_AD_PREFERENCE}/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['fetchAdPreferences'],
            }),
            updateBrandTargetCustomer: builder.mutation({
                query: ({ id, data }) => {
                    return {
                        url: `${API_BRAND_MAGIC_TARGET_CUSTOMER}/${id}`,
                        method: HTTP_METHODS.PUT,
                        body: data,
                    };
                },
                invalidatesTags: ['targetCustomer'],
                // invalidatesTags: ["targetCustomer"],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteBrandTargetCustomerById: builder.mutation({
                query: (id) => {
                    return {
                        url: `${API_BRAND_MAGIC_TARGET_CUSTOMER}/${id}`,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                invalidatesTags: ['targetCustomer'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            saveOnBrandPotion: builder.mutation({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_ONBRAND_POTION,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                invalidatesTags: ['onBrandPotion'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getOnBrandPotion: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_MAGIC_ONBRAND_POTION,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['onBrandPotion'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            updateBrandPotion: builder.mutation({
                query: ({ id, data }) => {
                    return {
                        url: `${API_BRAND_MAGIC_ONBRAND_POTION}/${id}`,
                        method: HTTP_METHODS.PUT,
                        body: data,
                    };
                },
                invalidatesTags: ['onBrandPotion'],
                // invalidatesTags: ["targetCustomer"],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteOnBrandPotionsById: builder.mutation({
                query: (id) => {
                    return {
                        url: `${API_BRAND_MAGIC_ONBRAND_POTION}/${id}`,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                invalidatesTags: ['onBrandPotion'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getBrandMagicCopyInfo: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_MAGIC_FETCH_COPY_INFO,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            // design elements
            getBrandElements: builder.query<IGetBrandElements, void>({
                query() {
                    return {
                        url: API_BRAND_MAGIC_GET_BRAND_ELEMENTS,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['brandElements'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            addBrandElementsColor: builder.mutation<any, IAddColor>({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_ADD_COLOR,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                invalidatesTags: ['brandElements'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteBrandElementsColor: builder.mutation<any, IRemoveColor>({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_REMOVE_COLOR,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                invalidatesTags: ['brandElements'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            addBrandElementsFont: builder.mutation<any, IAddBrandElementsFont>({
                query: (data) => {
                    const formData = new FormData();
                    formData.append('fontFile', data.fontFile as File);
                    formData.append('fontClass', data.fontClass);
                    formData.append('fontFamily', data.fontFamily);

                    return {
                        url: API_BRAND_MAGIC_ADD_FONT,
                        method: HTTP_METHODS.PATCH,
                        body: formData,
                    };
                },
                invalidatesTags: ['brandElements'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            removeBrandElementsFont: builder.mutation<any, IRemoveBrandElementsFont>({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_REMOVE_FONT,
                        method: HTTP_METHODS.PATCH,
                        body: data,
                    };
                },
                invalidatesTags: ['brandElements'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            uploadBrandElementsLogo: builder.mutation({
                query: (data) => {
                    const formData = new FormData();
                    formData.append('logo', data?.image as File);
                    return {
                        url: API_BRAND_MAGIC_ADD_LOGO,
                        method: HTTP_METHODS.POST,
                        body: formData,
                    };
                },
                invalidatesTags: ['brandElements'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            removeBrandElementsLogo: builder.mutation<any, void>({
                query: () => {
                    return {
                        url: API_BRAND_MAGIC_REMOVE_LOGO,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                invalidatesTags: ['brandElements'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            saveScrapingURL: builder.mutation({
                query: (data) => {
                    return {
                        url: API_BRAND_MAGIC_SCRAPING_URL,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                transformResponse: (data) => data,
                transformErrorResponse: (response) => response,
            }),
            getScrapingURL: builder.query<any, void>({
                query() {
                    return {
                        url: API_BRAND_MAGIC_SCRAPING_URL,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response) => response,
            }),
        };
    },
});

export const {
    useGetDefaultPotionsQuery,
    useGetUserImageFavouritesQuery,
    useDeleteImageFavouritesByIdMutation,
    useSaveBrandVoiceMutation,
    useGetBrandVoiceQuery,
    useSaveBrandFormatMutation,
    useGetBrandFormatQuery,
    useSaveBrandTargetCustomerMutation,
    useGetBrandTargetCustomerQuery,
    useUpdateBrandTargetCustomerMutation,
    useDeleteBrandTargetCustomerByIdMutation,
    useSaveOnBrandPotionMutation,
    useGetOnBrandPotionQuery,
    useUpdateBrandPotionMutation,
    useDeleteOnBrandPotionsByIdMutation,
    useGetBrandMagicCopyInfoQuery,
    // brand elements
    useGetBrandElementsQuery,
    useAddBrandElementsColorMutation,
    useDeleteBrandElementsColorMutation,
    useAddBrandElementsFontMutation,
    useRemoveBrandElementsFontMutation,
    useUploadBrandElementsLogoMutation,
    useRemoveBrandElementsLogoMutation,
    useUploadAdPreferenceMutation,
    useFetchAdPreferenceQuery,
    useDeleteAdPreferenceMutation,
    //Scraping url
    useSaveScrapingURLMutation,
    useGetScrapingURLQuery,
} = brandMagicApiSlice;
