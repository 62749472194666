import React, { useState, useRef, useEffect } from 'react';
import { Tab, Nav, Image, Tabs } from 'react-bootstrap';
import ImageContainer from './ImageContainer';
import TextContainer from './TextContainer';
import ProductPhotoContainer from './ProductPhotoContainer';
const Generate = ({ store }) => {
    const [selectedTab, setSelectedTab] = useState('Background Replace');
    const [sendTextData, setSendTextData] = useState(false);

    const handleSendTextData = () => {
        setSendTextData(true);
    };
    return (
        <div className="polotnoEditor">
            <h2 className="text-dark fw-semibold mt-2 me-3 mb-3 ms-2">Generate</h2>
            <Tabs
                activeKey={selectedTab}
                onSelect={(tab) => {
                    if (tab) {
                        setSelectedTab(tab);
                    }
                }}
                transition={false}
                className="mb-3 tabNavigation-alt"
            >
                <Tab eventKey="Background Replace" title="Background Replace">
                    <ProductPhotoContainer store={store} />
                </Tab>
                <Tab eventKey="Image" title="Image">
                    <ImageContainer store={store} />
                </Tab>
                <Tab eventKey="Text" title="Text">
                    <TextContainer />
                </Tab>
            </Tabs>
        </div>
    );
};

export default Generate;
