import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../commons/hooks/redux';
import { selectCreateFlow } from '../CreateFlow/CreateFlowRedux/selector';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPixiiTab } from '../CreateFlow/CreateFlowRedux/createFlowSlice';
import { Button } from 'react-bootstrap';
import { useGetGeneratedTextsQuery } from '../CreateFlow/CreateFlowRedux/createFlow.api';

const CustomVariants = ({ store, createChildren }) => {
    const [showType, setShowType] = useState(false);
    const [typeSelect, setTypeSelect] = useState(false);
    const [inputFields, setInputFields] = useState([{ value: '' }]);
    const [variants, setVariants] = React.useState([]);
    const [selectedVariant, setSelectedVariant] = useState('');
    const [customMode, setCustomMode] = useState(false);
    const [filteredTaglines, setFilteredTaglines] = useState([]);
    const [filteredCaptions, setFilteredCaptions] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const generatePageWithCaption = (store, id, variants) => {
        let page = store.activePage;
        let childrenIds = page.children.map((c) => c.id);
        for (let v of variants) {
            v?.results?.map((data) => {
                createChildren(store, childrenIds, id, data?.outputs[0]);
            });
            store.selectPage(page.id);
        }
    };
    const parseStore = (store) => {
        let json = store.toJSON();
        let children = json?.pages[0]?.children;
        children = children ? children : [];
        return children.filter((c) => c.type === 'text');
    };
    const generatePageWithCustomCaption = (store, id, names) => {
        let page = store.activePage;
        let childrenIds = page.children.map((c) => c.id);
        for (let name of names) {
            createChildren(store, childrenIds, id, name);
            store.selectPage(page.id);
        }
    };

    let childrens = parseStore(store);
    store.on('change', () => {
        childrens = parseStore(store);
    });
    const handleGenerate = () => {
        // first remove all pages
        const pagesIds = store.pages.map((p) => p.id).slice(1, store.pages.lenth);
        store.deletePages(pagesIds);
        //TODO variants clone
        generatePageWithCaption(store, selectedVariant, variants);
    };

    const handleCustomGenerate = () => {
        const pagesIds = store.pages.map((p) => p.id).slice(1, store.pages.lenth);
        store.deletePages(pagesIds);
        const customNames = inputFields.map((name) => name.value);
        // const names = text.split(",").map((s) => s.trim());
        // console.log(customNames);
        generatePageWithCustomCaption(store, selectedVariant, customNames);
    };

    // const { filteredTaglines, filteredCaptions } = useAppSelector(selectCreateFlow);
    const { data, isLoading } = useGetGeneratedTextsQuery({ page: 1, size: 1000 });
    // Once data is fetched, filter the data and update the filteredTaglines and filteredCaptions states.
    useEffect(() => {
        if (data?.records?.length > 0) {
            const filteredHeadline = data?.records?.filter(
                (tagline) => tagline.command === 'Headline',
            );
            const filteredTaglinesData = data?.records?.filter(
                (tagline) => tagline.command === 'Tagline',
            );
            const combinedTaglines = [...filteredHeadline, ...filteredTaglinesData];
            const filteredCaption = data?.records?.filter(
                (tagline) => tagline.command === 'Caption',
            );
            setFilteredTaglines(combinedTaglines);
            setFilteredCaptions(filteredCaption);
        }
    }, [data]); // Run this effect whenever 'data' changes
    const handleFavChange = (e) => {
        const { value } = e.target;
        if (value === 'custom') {
            setCustomMode(true);
        } else if (value === 'tagline') {
            setCustomMode(false);
            setVariants(filteredTaglines || []);
        } else {
            setCustomMode(false);
            setVariants(filteredCaptions || []);
        }
        setTypeSelect(true);
    };

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        values[index].value = event.target.value;
        setInputFields(values);
    };

    const handleAddFields = () => {
        setInputFields([...inputFields, { value: '' }]);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    const handleAddMore = () => {
        dispatch(setPixiiTab('text'));
        store.openSidePanel('Generate');
    };
    return (
        <div className="variants">
            <div className="m-2">
                <h2 className="text-dark fw-semibold mb-3">Variants</h2>
                <p className="h3 fw-normal">Select which object to vary and assign it’s role</p>
            </div>

            <div className="variants-selection m-2 mt-3">
                <p className="mb-0">Vary</p>
                <select
                    onChange={(e) => {
                        store.selectElements([e.target.value]);
                        setSelectedVariant(e.target.value);
                        setShowType(true);
                    }}
                    className=" selection elementSelect"
                >
                    <option value="" hidden>
                        Select Element
                    </option>
                    {childrens.map((c) => (
                        <option value={c.id} key={c.id} className="elementOption">
                            <i className="ph ph-text-t lg"></i> {c.text}
                        </option>
                    ))}
                </select>
                {showType && (
                    <>
                        <p className="mb-0">using</p>
                        <select onChange={handleFavChange} className=" selection typeSelect">
                            <option value="" hidden>
                                type
                            </option>
                            <option value="tagline">
                                <i className="ph ph-rows"></i> Headline
                            </option>
                            <option value="caption">Caption</option>
                            <option value="custom">Custom</option>
                        </select>
                    </>
                )}
            </div>
            {typeSelect && (
                <div className="variants-options">
                    {customMode ? (
                        <>
                            <p className="text-dark h3 mb-2 highlight">
                                Add custom variants below:
                            </p>
                            <div className="input-container">
                                {inputFields.map((field, index) => (
                                    <>
                                        <div key={index} className="input-field">
                                            <label
                                                htmlFor={`input-${index}`}
                                                className="input-label"
                                            >
                                                Variant {index + 1}
                                            </label>
                                            <input
                                                className="input"
                                                type="text"
                                                id={`input-${index}`}
                                                value={field.value}
                                                onChange={(e) => handleInputChange(index, e)}
                                                placeholder="Write/paste your custom text here"
                                            />
                                            {/* <button className="iconbtn iconbtn-transparent"><i class="ph ph-x-circle lg"></i></button> */}
                                            <Button
                                                variant="white"
                                                onClick={() => handleRemoveFields(index)}
                                                className="iconbtn"
                                            >
                                                <i className="ph ph-x-circle lg"></i>
                                            </Button>
                                        </div>
                                    </>
                                ))}
                                <div className="addVariants" onClick={handleAddFields}>
                                    {/* <label className="input-label">
                    Variant {inputFields.length + 1}
                  </label> */}
                                    <p className="input">Add Variants</p>
                                    {/* <button className="iconbtn iconbtn-transparent"><i class="ph ph-x-circle lg"></i></button> */}
                                    <Button variant="white" className="iconbtn">
                                        <i className="ph ph-plus-circle lg"></i>
                                    </Button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="tagCaption">
                            <div className="taglines">
                                <p className="text-dark h3 mb-2 highlight">
                                    Variants from your Palette:
                                </p>
                                {variants.map((favorite, index) => {
                                    return (
                                        <>
                                            {favorite?.results?.map((gText, index) => {
                                                return (
                                                    <div
                                                        className="taglines-text mb-4"
                                                        key={favorite?.id}

                                                        // onDragStart={() => {
                                                        //   unstable_registerNextDomDrop(({ x, y }) => {
                                                        //     onSelect({ x, y }, favorite?.selectedResult);
                                                        //   });
                                                        // }}
                                                        // onDragEnd={() => {
                                                        //   unstable_registerNextDomDrop(null);
                                                        // }}
                                                    >
                                                        <span className="fw-semibold h4 text-primary">{`${
                                                            gText?.segment
                                                                ? `@${gText?.segment}`
                                                                : ''
                                                        }`}</span>
                                                        <p className="text-dark mt-2 fw-normal align-justify h3">
                                                            {' '}
                                                            {gText?.outputs?.[0]}
                                                        </p>
                                                        {/* <Button /> */}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="variants-buttons">
                        <Button variant="outline-primary" onClick={handleAddMore}>
                            Add more
                        </Button>
                        <Button
                            variant="primary"
                            size="lg"
                            onClick={customMode ? handleCustomGenerate : handleGenerate}
                        >
                            <i className="ph ph-circles-three-plus lg"></i>Vary
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomVariants;
