export enum ImageType {
    URL = 'url',
    FILE = 'file',
    NONE = 'none',
}

export interface imageData {
    imageType: ImageType;
    data: File | string | null;
    id: string;
}
export interface productImageProps {
    imageData: imageData;
    isImageDataPresent: boolean;
}
