import { lazy, useEffect, useMemo, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from '../pages/design/header';
import PolotonoEditor from '../pages/Editor/PolotonoEditor';
import { selectEditFlow } from '../pages/Editor/EditorRedux/selector';
import { useAppDispatch, useAppSelector } from '../commons/hooks/redux';
import Starting from '../pages/design/Starting';
import BrandMagic from '../pages/BrandMagic/BrandMagicPages/BrandMagic';
import createStore from 'polotno/model/store';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import {
    useSaveUserSubscriptionMutation,
    useUpdateUserPaymentStatusMutation,
} from '../pages/PaddlePayWall/PaddlePayWallRedux/paddlePayWall.api';
import { setUserSubscriptionStats } from '../pages/PaddlePayWall/PaddlePayWallRedux/paddlePayWallSlice';
import FAQPage from '../pages/FAQ/Faq';
import { ProtectedRoute } from './ProtectedRoute';
import { useSelector } from 'react-redux';
import Apprentice from '../pages/Apprentice/Apprentice';

// import BrandMagic from "../pages/design/BrandMagic";
const CreateFlow = lazy(() => import('../pages/CreateFlow/CreateFlowPages'));
const Login = lazy(() => import('../pages/Authentication/Login'));
const SignUp = lazy(() => import('../pages/Authentication/SignUp'));
const Verification = lazy(() => import('../pages/Authentication/Verification'));
const ForgotPassword = lazy(() => import('../pages/Authentication/ForgotPassword'));

const PixiRoutes = () => {
    const subscriptonStatus = useSelector((state: any) => state.paddleFlow.subscribedUser);
    const activeUserMail = useSelector((state: any) => state.paddleFlow.activeUserMail);
    const [currentEmail, setCurrentEmail] = useState('');
    const [currentPassCode, setCurrentPassCode] = useState('');
    const [paddle, setPaddle] = useState<Paddle>();
    const { project } = useAppSelector(selectEditFlow);
    const [saveUserSubscription] = useSaveUserSubscriptionMutation();
    const [updateUserPaymentStatus] = useUpdateUserPaymentStatusMutation();
    const dispatch = useAppDispatch();
    const store = useMemo(() => {
        return createStore({
            key: process.env.REACT_APP_POLOTNO_API_KEY as string,
            showCredit: false,
        });
    }, []);
    const [editingMode, setEditingMode] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [closeApprentice, setCloseApprentice] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    // Download and initialize Paddle instance from CDN
    useEffect(() => {
        const sellerID = parseInt(process.env.REACT_APP_PADDLE_SELLER_ID as string);
        if (sellerID)
            initializePaddle({
                environment: 'production',
                seller: sellerID,
                pwCustomer: subscriptonStatus ? { email: activeUserMail } : {},
                checkout: {
                    settings: {
                        displayMode: 'inline',
                        theme: 'light',
                        locale: 'en',
                        // successUrl: 'https://stage.pixii.ai/',
                        frameTarget: 'checkout-container',
                        frameInitialHeight: 450,
                        frameStyle:
                            'width: 100%; min-width: 312px; background-color: transparent; border: none;',
                    },
                },
                eventCallback: async function (data) {
                    if (data.name == 'checkout.completed') {
                        console.log('Checkout completed');
                        dispatch(setUserSubscriptionStats(true));
                    }
                },
            }).then((paddleInstance: Paddle | undefined) => {
                if (paddleInstance) {
                    setPaddle(paddleInstance);
                }
            });
    }, []);
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute user={subscriptonStatus}>
                            <Header
                                editingMode={editingMode}
                                setEditingMode={setEditingMode}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                closeApprentice={closeApprentice}
                                setCloseApprentice={setCloseApprentice}
                                showSidebar={showSidebar}
                                setShowSidebar={setShowSidebar}
                            />
                            <Apprentice
                                store={store}
                                paddle={paddle}
                                editingMode={editingMode}
                                setEditingMode={setEditingMode}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                closeApprentice={closeApprentice}
                                setCloseApprentice={setCloseApprentice}
                                showSidebar={showSidebar}
                                setShowSidebar={setShowSidebar}
                            />
                        </ProtectedRoute>
                    }
                />
                {/* <Route
                    path="/editor"
                    element={
                        <ProtectedRoute user={subscriptonStatus}>
                            <Header store={store} />
                            <PolotonoEditor store={store} paddle={paddle} />
                        </ProtectedRoute>
                    }
                /> */}
                <Route
                    path="/faq"
                    element={
                        <>
                            <Header store={store} />
                            <FAQPage />
                        </>
                    }
                />

                <Route path="/login" element={<Login setCurrentEmail={setCurrentEmail} />} />
                <Route
                    path="signup"
                    element={
                        <SignUp
                            setCurrentEmail={setCurrentEmail}
                            setCurrentPassCode={setCurrentPassCode}
                        />
                    }
                />
                <Route
                    path="verification"
                    element={
                        <Verification
                            currentEmail={currentEmail}
                            currentPassCode={currentPassCode}
                        />
                    }
                />
                <Route path="/start" element={<Navigate to="/brandProfile" />} />
                <Route
                    path="brandProfile"
                    element={
                        <>
                            {/* <ProtectedRoute user={subscriptonStatus}> */}
                            <Header />
                            <BrandMagic paddle={paddle} />
                            {/* </ProtectedRoute> */}
                        </>
                    }
                />
                <Route
                    path="forgot_password"
                    element={
                        <ForgotPassword
                            currentEmail={currentEmail}
                            setCurrentEmail={setCurrentEmail}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default PixiRoutes;
