import { useEffect, useState } from 'react';
import { Dropdown, InputGroup, Spinner } from 'react-bootstrap';
import { unstable_registerNextDomDrop } from 'polotno/config';
import {
    useCreateFavouriteMutation,
    useGetBackGroundReplacedImagesQuery,
    useGetGeneratedImagesQuery,
} from '../CreateFlow/CreateFlowRedux/createFlow.api';
import config from '../../config';

import { displayErrorToast, displaySuccessToast } from '../../utils/ToastUtils';
import AddToFavoriteModal from '../CreateFlow/CreateFlowPages/AddToFavouriteModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';
import { PrefillData } from './ImageContainer';

interface ProductPhotoProps {
    store: any;
    sendData: boolean;
    setSendData: React.Dispatch<React.SetStateAction<boolean>>;
    handleUserFavouritesOrReuseSelect: (data: PrefillData, resuse?: boolean) => void;
}

const ProductPhoto = ({
    store,
    sendData,
    setSendData,
    handleUserFavouritesOrReuseSelect,
}: ProductPhotoProps) => {
    const [page, setCurrentPage] = useState(1);
    // const { data, isLoading, refetch } = useGetGeneratedImagesQuery(page === 0 ? 1 : page);
    const { data, isLoading, refetch } = useGetBackGroundReplacedImagesQuery(page === 0 ? 1 : page);
    const [images, setImagesData] = useState<any>([]);

    // Separate state for newly fetched data
    const [newlyFetchedData, setNewlyFetchedData] = useState<any>([]);
    const [addImagePotion, setAddImagePotion] = useState(false);
    const [selectedPotionData, setSelectedPotionData] = useState({
        model: null,
        prompt: null,
        pixiiPotion: null,
    });
    const [addToAsset] = useCreateFavouriteMutation();

    // refetch data if current page changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {
                console.error('Refetch error:', error);
            } finally {
                setSendData(false);
            }
        };

        // Refetch data if the page changes
        fetchData();
    }, [page, refetch]);
    useEffect(() => {
        // Append the newly fetched data to the existing projects when the page changes
        if (newlyFetchedData !== undefined && (page === 1 || page === 0)) {
            setImagesData(newlyFetchedData?.records);
        } else if (newlyFetchedData !== undefined && page > 1) {
            setImagesData((prevProjects: any) => [...prevProjects, ...newlyFetchedData?.records]);
        }
    }, [newlyFetchedData]);

    useEffect(() => {
        setNewlyFetchedData(data);
    }, [data, page]);

    const setPage = () => {
        setCurrentPage((prev) => {
            switch (prev) {
                case 0:
                    return 1;
                case 1:
                    return 0;
                default:
                    return 1;
            }
        });
    };
    useEffect(() => {
        sendData && setPage();
    }, [sendData]);
    const onSelect = (pos: any, src: any) => {
        const x = pos?.x;
        const y = pos?.y;

        store.activePage?.addElement({
            type: 'image',
            x: x,
            y: y,
            rotation: 0,
            locked: false, // deprecated
            blurEnabled: false,
            blurRadius: 10,
            brightnessEnabled: false,
            brightness: 0,
            shadowEnabled: false,
            shadowBlur: 5,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'black',
            shadowOpacity: 1,
            name: '', // name of element, can be used to find element in the store
            // url path to image
            src: src,
            keepRatio: false, // can we change aspect ratio of image

            // url path to svg or image that will be used to clip image
            // cab be used for image framing
            clipSrc: '',
            width: 100,
            height: 100,
            cropX: 0, // 0-1 : % from original image width
            cropY: 0, // 0-1 : % from original image height
            cropWidth: 1, // 0-1 : % from original image width
            cropHeight: 1, // 0-1 : % from original image height
            cornerRadius: 0,
            borderColor: 'black',
            borderSize: 0,
            flipX: false,
            flipY: false,

            // can user select element?
            // if false, element will be "invisible" for user clicks
            selectable: true,
            // use for absolute positing of element
            alwaysOnTop: false,
            // also we can hide some elements from the export
            showInExport: true,
            // can element be moved and rotated
            draggable: true,
            // can we change content of element?
            contentEditable: true,

            // can we remove element from UI with button or keyboard?
            removable: true,
            // can we resize element?
            resizable: true,
        });
    };
    const handleAddToAsset = async (src: string) => {
        try {
            await addToAsset({ selectedResult: src });
            displaySuccessToast('Added successfully.');
        } catch (e) {
            console.log(e);
            displayErrorToast('There was an error adding to asset.');
        }
    };

    const handleDownloadImage = (image: string) => {
        const link = document.createElement('a');
        link.href = image;
        link.download = `GeneratedImage_${image}`;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const fetchMoreData = () => {
        setCurrentPage((prev) => (prev === 0 ? 2 : prev + 1));
    };
    // for generated image dynamic height
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 425);
        };

        handleResize(); // Set initial height on component mount
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //end of generated image height
    // const BackgroundImages = images?.filter(
    //     (item: any) => item.command === 'BACKGROUND REPLACEMENT',
    // );
    return isLoading || images === undefined ? (
        <div className="SpinnerDiv">
            <Spinner animation="border" style={{ marginTop: '40px' }} className="custom-spinner" />
        </div>
    ) : (
        <div className="userPrompt_results">
            <InfiniteScroll
                dataLength={images?.length || 1}
                next={fetchMoreData}
                hasMore={data?.hasNext}
                loader={
                    <div
                        className="w-100 d-flex mb-4 justify-content-center align-item-center"
                        style={{ padding: '24px', height: '180px' }}
                    >
                        <Spinner
                            as="span"
                            className="mr-1 pe-none"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            style={{
                                marginTop: '40px',
                                width: '48px',
                                height: '48px',
                                color: '#967AA7',
                                background: 'transparent',
                            }}
                        />
                    </div>
                }
                height={windowHeight}
                // style={{ height: windowHeight, marginBottom: `${marginBottomForEndMessage}px` }}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
                className=""
            >
                <div className="results border-none">
                    <Masonry
                        breakpointCols={2}
                        className="pixii-grid results-image m-1"
                        columnClassName="pixii-grid_column"
                    >
                        {images?.map((item: any) => {
                            return item?.results.map((image: any, imageIndex: number) => {
                                const imageSrc = image;
                                return (
                                    <div className="result p-1" key={imageIndex}>
                                        <div
                                            draggable
                                            onDragStart={() => {
                                                unstable_registerNextDomDrop(({ x, y }) => {
                                                    onSelect({ x, y }, imageSrc);
                                                });
                                            }}
                                            onDragEnd={() => {
                                                unstable_registerNextDomDrop(null);
                                            }}
                                            onClick={() => {
                                                store.activePage?.addElement({
                                                    type: 'image',
                                                    x: 0,
                                                    y: 0,
                                                    rotation: 0,
                                                    locked: false, // deprecated
                                                    blurEnabled: false,
                                                    blurRadius: 10,
                                                    brightnessEnabled: false,
                                                    brightness: 0,
                                                    shadowEnabled: false,
                                                    shadowBlur: 5,
                                                    shadowOffsetX: 0,
                                                    shadowOffsetY: 0,
                                                    shadowColor: 'black',
                                                    shadowOpacity: 1,

                                                    // url path to image
                                                    src: imageSrc,
                                                    keepRatio: false, // can we change aspect ratio of image

                                                    // url path to svg or image that will be used to clip image
                                                    // cab be used for image framing
                                                    clipSrc: '',
                                                    width: 100,
                                                    height: 100,
                                                    cropX: 0, // 0-1 : % from original image width
                                                    cropY: 0, // 0-1 : % from original image height
                                                    cropWidth: 1, // 0-1 : % from original image width
                                                    cropHeight: 1, // 0-1 : % from original image height
                                                    cornerRadius: 0,
                                                    borderColor: 'black',
                                                    borderSize: 0,
                                                    flipX: false,
                                                    flipY: false,
                                                });
                                            }}
                                        >
                                            <img
                                                src={imageSrc}
                                                loading="lazy"
                                                crossOrigin="anonymous"
                                            />
                                        </div>
                                        {/* OPTION DROPDOWN */}
                                        <div className="result_options">
                                            <InputGroup className="custom-dropdown">
                                                <Dropdown autoClose="outside" align="end">
                                                    <Dropdown.Toggle
                                                        variant="outline-primary"
                                                        className="dropdown__toggle iconbtn"
                                                        id="dropdown-autoclose-outside"
                                                    >
                                                        <i className="ph-fill ph-dots-three-outline "></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        className="dropdown__toggle--menu"
                                                        style={{ width: '12rem' }}
                                                    >
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                handleDownloadImage(imageSrc)
                                                            }
                                                        >
                                                            <i className="ph ph-arrows-out lg"></i>{' '}
                                                            Full Size
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                handleUserFavouritesOrReuseSelect(
                                                                    {
                                                                        potionTitle: null,
                                                                        prompt: item.prompt,
                                                                        modelType: item.model,
                                                                        pixiiPotion:
                                                                            item.pixiipotion,
                                                                    },
                                                                    true,
                                                                );
                                                            }}
                                                        >
                                                            <i className="ph ph-repeat lg"></i>{' '}
                                                            Re-Use Prompt
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => handleAddToAsset(image)}
                                                        >
                                                            <i className="ph ph-floppy-disk lg"></i>{' '}
                                                            Save in Assets
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item
                                                            onClick={() => {
                                                                setSelectedPotionData({
                                                                    model: item.model,
                                                                    pixiiPotion: item.pixiipotion,
                                                                    prompt: item.prompt,
                                                                });
                                                                setAddImagePotion(true);
                                                            }}
                                                        >
                                                            <i className="ph ph-bookmark-simple"></i>{' '}
                                                            Add to Favourites
                                                        </Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </InputGroup>
                                        </div>
                                    </div>
                                );
                            });
                        })}
                    </Masonry>
                </div>
            </InfiniteScroll>

            {addImagePotion && (
                <AddToFavoriteModal
                    show={addImagePotion}
                    onHide={() => {
                        setAddImagePotion(false);
                    }}
                    data={selectedPotionData}
                />
            )}
        </div>
    );
};

export default ProductPhoto;
