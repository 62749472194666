import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../commons/hooks/redux';
import { setCreditExpired } from '../../CreateFlow/CreateFlowRedux/createFlowSlice';
interface OverwhelmedModalProps {
    show: boolean;
    onHide: () => void;
}
const OverwhelmedModal: React.FC<OverwhelmedModalProps> = (props) => {
    const dispatch = useAppDispatch();
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="alert-modal"
        >
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center p-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                >
                    <g clipPath="url(#clip0_1768_125324)">
                        <path
                            opacity="0.2"
                            d="M41.1125 8H22.8875C22.6248 7.99979 22.3646 8.05135 22.1218 8.15173C21.879 8.2521 21.6584 8.39933 21.4725 8.585L8.585 21.4725C8.39933 21.6584 8.2521 21.879 8.15173 22.1218C8.05135 22.3646 7.99979 22.6248 8 22.8875V41.1125C7.99979 41.3752 8.05135 41.6354 8.15173 41.8782C8.2521 42.121 8.39933 42.3416 8.585 42.5275L21.4725 55.415C21.6584 55.6007 21.879 55.7479 22.1218 55.8483C22.3646 55.9487 22.6248 56.0002 22.8875 56H41.1125C41.3752 56.0002 41.6354 55.9487 41.8782 55.8483C42.121 55.7479 42.3416 55.6007 42.5275 55.415L55.415 42.5275C55.6007 42.3416 55.7479 42.121 55.8483 41.8782C55.9487 41.6354 56.0002 41.3752 56 41.1125V22.8875C56.0002 22.6248 55.9487 22.3646 55.8483 22.1218C55.7479 21.879 55.6007 21.6584 55.415 21.4725L42.5275 8.585C42.3416 8.39933 42.121 8.2521 41.8782 8.15173C41.6354 8.05135 41.3752 7.99979 41.1125 8Z"
                            fill="#6F4E84"
                        />
                        <path
                            d="M32 34V20"
                            stroke="#6F4E84"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M41.1125 8H22.8875C22.6248 7.99979 22.3646 8.05135 22.1218 8.15173C21.879 8.2521 21.6584 8.39933 21.4725 8.585L8.585 21.4725C8.39933 21.6584 8.2521 21.879 8.15173 22.1218C8.05135 22.3646 7.99979 22.6248 8 22.8875V41.1125C7.99979 41.3752 8.05135 41.6354 8.15173 41.8782C8.2521 42.121 8.39933 42.3416 8.585 42.5275L21.4725 55.415C21.6584 55.6007 21.879 55.7479 22.1218 55.8483C22.3646 55.9487 22.6248 56.0002 22.8875 56H41.1125C41.3752 56.0002 41.6354 55.9487 41.8782 55.8483C42.121 55.7479 42.3416 55.6007 42.5275 55.415L55.415 42.5275C55.6007 42.3416 55.7479 42.121 55.8483 41.8782C55.9487 41.6354 56.0002 41.3752 56 41.1125V22.8875C56.0002 22.6248 55.9487 22.3646 55.8483 22.1218C55.7479 21.879 55.6007 21.6584 55.415 21.4725L42.5275 8.585C42.3416 8.39933 42.121 8.2521 41.8782 8.15173C41.6354 8.05135 41.3752 7.99979 41.1125 8Z"
                            stroke="#6F4E84"
                            stroke-width="2"
                            stroke-miterlimit="10"
                        />
                        <path
                            d="M32 46C33.6569 46 35 44.6569 35 43C35 41.3431 33.6569 40 32 40C30.3431 40 29 41.3431 29 43C29 44.6569 30.3431 46 32 46Z"
                            fill="#6F4E84"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1768_125324">
                            <rect width="64" height="64" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <h1 className="fw-semibold text-primary mt-2 mb-2">Hey there!</h1>
                <p className="text-center text-muted">
                    A lot of people are checking out Pixii right now. We're doing our best to <br />{' '}
                    make sure everyone has a chance to try it out, so please check back soon!
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-primary"
                    onClick={(e) => {
                        // window.open("mailto:support@pixii.ai", "_blank"); //opens on new tab
                        dispatch(setCreditExpired(false));
                        window.location.href = 'mailto:support@pixii.ai';
                        e.preventDefault();
                    }}
                >
                    Contact Us
                </Button>
                {/* <button className="btn btn-primary">
          Get notified when we're back
        </button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default OverwhelmedModal;
