export default {
    control: {
        backgroundColor: '#fff',
        fontSize: 16,
        fontWeight: 'normal',
    },

    '&multiLine': {
        control: {
            fontFamily: 'Inter',
            minHeight: 55,
            // height: "18vh",
        },
        highlighter: {
            padding: 9,
            border: '1px solid transparent',
            boxSizing: 'border-box',
            overflow: 'hidden',
            height: 110,
        },
        input: {
            padding: 8,
            border: 'none',
            overflowY: 'auto',
            height: 110,
        },
    },

    '&singleLine': {
        display: 'inline-block',
        width: 180,

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },
        input: {
            padding: 1,
            border: '2px inset',
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid #6f4e84',
            // boxShadow: '0px 2px 4px rgba(111, 78, 132, 0.45)',
            borderRadius: '8px',
            textAlign: 'left',
            padding: '8px',
        },
        item: {
            padding: '4px',
            fontWeight: '500',
            textTransform: 'capitalize',
            '&focused': {
                borderRadius: '8px',
                backgroundColor: '#F2E6F2',
                color: '#6f4e84',
            },
        },
    },
};
