import React from 'react';
import { observer } from 'mobx-react-lite';

import { PageControls as DefaultControls } from 'polotno/canvas/page-controls';
import { Button, EditableText } from '@blueprintjs/core';

export const PageControls = observer(({ store, page, xPadding, yPadding }) => {
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    top: yPadding - 35 + 'px',
                    left: xPadding + 'px',
                    right: xPadding + 'px',
                }}
            >
                <div style={{ maxWidth: `calc(100% - 170px)` }}>
                    <EditableText
                        // we can use custom data to store page name into store
                        value={page.custom?.caption || ''}
                        placeholder="Type caption"
                        onChange={(val) => {
                            page.set({
                                custom: {
                                    ...page.custom,
                                    caption: val,
                                },
                            });
                        }}
                    />
                </div>
            </div>
            <DefaultControls store={store} page={page} xPadding={xPadding} yPadding={yPadding} />
        </>
    );
});
