import { baseApiSlice } from '../../../commons/base-api.config';
import { HTTP_METHODS } from '../../../constants/http';
import {
    API_CLIP_DROP,
    API_CREATE_FLOW,
    API_CREATE_FLOW_IMAGE,
    API_CREATE_FLOW_IMAGE_DIMENSION,
    API_CREDIT_SCORE,
    API_FAVORITE,
    API_FLAG_FOR_ONBOARDED_USER,
    API_GENERATED_RESULTS,
    API_PRODUCT_PHOTO,
    API_PRODUCT_PHOTOGRAPHY,
    API_UPDATE_ONBOARDING_USER,
    API_CREATE_IMAGE_POTION,
    API_GET_GENERATED_IMAGES,
    API_GET_GENERATED_TEXTS,
    API_GENERATE_IMAGE,
    API_GET_BACKGROUND_REPLACED_IMAGES,
} from '../../../constants/api';
import { IData, flagRes } from './createFlowTypes';

// function createMultipartFormData(formData, boundary) {
//   const parts = [];

//   formData.forEach((value, key) => {
//     parts.push(`--${boundary}`);
//     parts.push(`Content-Disposition: form-data; name="${key}"`);
//     parts.push("");
//     parts.push(value);
//   });

//   parts.push(`--${boundary}--`);

//   return parts.join("\r\n");
// }

export const createFlowApiSlice = baseApiSlice.injectEndpoints({
    endpoints(builder) {
        return {
            // get generated results
            getGeneratedResults: builder.query<IData[], void>({
                query() {
                    return {
                        url: API_GENERATED_RESULTS,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['generatedResults'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createTextGeneration: builder.mutation({
                query: (data) => {
                    return {
                        url: API_CREATE_FLOW,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                // invalidatesTags: ['generatedResults', 'generatedTexts'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createFlowImage: builder.mutation({
                query: ({ apiType, data }) => {
                    // Add this line
                    return {
                        url: `${API_CREATE_FLOW_IMAGE}/${apiType}`,
                        method: HTTP_METHODS.POST,
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };
                },
                // invalidatesTags: ['generatedResults', 'generatedImages'],
                transformResponse: (data: { result: any }) => {
                    return data?.result;
                },
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createFlowClipdrop: builder.mutation({
                query: (imageData) => {
                    // Add this line
                    return {
                        url: API_CLIP_DROP,
                        method: HTTP_METHODS.POST,
                        body: JSON.stringify(imageData),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };
                },
                // invalidatesTags: ['generatedResults', 'generatedImages'],
                transformResponse: ({ data }) => {
                    return data;
                },
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createGenerateImage: builder.mutation({
                query: (imageData) => {
                    // Add this line
                    return {
                        url: API_GENERATE_IMAGE,
                        method: HTTP_METHODS.POST,
                        body: JSON.stringify(imageData),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };
                },
                invalidatesTags: ['generatedImages'],
                transformResponse: ({ data }) => {
                    return data;
                },
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createFlowImageDimension: builder.query<any, void>({
                query: () => {
                    return {
                        url: API_CREATE_FLOW_IMAGE_DIMENSION,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: (dimension: { data: any }) => {
                    // console.log("Response data For AD SIZE:", dimension);
                    return dimension?.data;
                },
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getFavouritesResults: builder.query<any, void>({
                query() {
                    return {
                        url: API_FAVORITE,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['favourites'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createFavourite: builder.mutation({
                query: (data) => {
                    return {
                        url: API_FAVORITE,
                        method: HTTP_METHODS.POST,
                        body: data,
                    };
                },
                invalidatesTags: ['favourites'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            deleteFavourite: builder.mutation({
                query: (id) => {
                    return {
                        url: `${API_FAVORITE}/${id}`,
                        method: HTTP_METHODS.DELETE,
                    };
                },
                invalidatesTags: ['favourites'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getProductPhotographyImages: builder.query<
                Array<{ id: number; inputImage: string }>,
                void
            >({
                query: () => ({
                    url: API_PRODUCT_PHOTO,
                    method: HTTP_METHODS.GET,
                }),
                providesTags: ['productPhotographyImages'],
                transformResponse: (data: { data: Array<any> }) => data.data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            uploadProductPhotographyImages: builder.mutation({
                query: (data) => {
                    const formData = new FormData();
                    formData.append('productPhoto', data?.image as File);
                    return {
                        url: API_PRODUCT_PHOTO,
                        method: HTTP_METHODS.POST,
                        body: formData,
                    };
                },
                invalidatesTags: ['productPhotographyImages'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            productPhotography: builder.mutation({
                query: (formData) => {
                    const headers = {
                        // "Content-Type": `multipart/form-data`;
                    };
                    return {
                        url: API_PRODUCT_PHOTOGRAPHY,
                        method: HTTP_METHODS.POST,
                        body: formData,
                        headers,
                    };
                },
                // invalidatesTags: ['generatedResults', 'productPhotographyImages'],
                invalidatesTags: ['productPhotographyImages'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            checkCreditStatus: builder.query<any, void>({
                query() {
                    return {
                        url: API_CREDIT_SCORE,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getFlagForOnboardedUser: builder.query<any, void>({
                query() {
                    return {
                        url: API_FLAG_FOR_ONBOARDED_USER,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: number; data?: any }) => {
                    return {
                        status: response.status,
                        error: response.data?.message || 'Server Error',
                    };
                },
            }),
            updateOnboardingUser: builder.mutation({
                query: () => {
                    return {
                        url: API_UPDATE_ONBOARDING_USER,
                        method: HTTP_METHODS.GET,
                    };
                },
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            createImagePotion: builder.mutation({
                query: (formData) => {
                    const headers = {
                        // "Content-Type": `multipart/form-data`;
                    };
                    return {
                        url: API_CREATE_IMAGE_POTION,
                        method: HTTP_METHODS.POST,
                        body: formData,
                        headers,
                    };
                },
                invalidatesTags: ['imagePotion'],
                transformResponse: (data: { result: any }) => data?.result,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getGeneratedImages: builder.query<any, any>({
                query(page) {
                    return {
                        url: `${API_GET_GENERATED_IMAGES}?page=${
                            page !== undefined ? page : 1
                        }&size=7`,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['generatedImages'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getBackGroundReplacedImages: builder.query<any, any>({
                query(page) {
                    return {
                        url: `${API_GET_BACKGROUND_REPLACED_IMAGES}?page=${
                            page !== undefined ? page : 1
                        }&size=7`,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['generatedImages'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
            getGeneratedTexts: builder.query<any, any>({
                query: (data) => {
                    return {
                        url: `${API_GET_GENERATED_TEXTS}?page=${
                            data?.page !== undefined ? data?.page : 1
                        }&size=${data?.size}`,
                        method: HTTP_METHODS.GET,
                    };
                },
                providesTags: ['generatedTexts'],
                transformResponse: ({ data }: any) => data,
                transformErrorResponse: (response: { status: string | number }) => response.status,
            }),
        };
    },
});

export const {
    useGetGeneratedResultsQuery,
    useGetBackGroundReplacedImagesQuery,
    useCreateTextGenerationMutation,
    useCreateFlowImageMutation,
    useCreateFlowClipdropMutation,
    useCreateGenerateImageMutation,
    useCreateFlowImageDimensionQuery,
    // favourite
    useGetFavouritesResultsQuery,
    useCreateFavouriteMutation,
    useDeleteFavouriteMutation,
    useProductPhotographyMutation,
    useGetProductPhotographyImagesQuery,
    useUploadProductPhotographyImagesMutation,
    useCheckCreditStatusQuery,
    //flag for onboarded user
    useLazyGetFlagForOnboardedUserQuery,
    useUpdateOnboardingUserMutation,
    useCreateImagePotionMutation,
    useGetGeneratedImagesQuery,
    useGetGeneratedTextsQuery,
} = createFlowApiSlice;
