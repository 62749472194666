import React, { useState, Dispatch, SetStateAction } from 'react';
import { motion } from 'framer-motion';
import { Image, FormCheck, Button } from 'react-bootstrap';
import { useBackgroundRemovalMutation } from '../../ApprenticeRedux/apprentice.api';
import { LoadingSpinner } from '../../AppereancticeSpinners';
import { displayErrorToast } from '../../../../utils/ToastUtils';
import { productImageProps, ImageType } from '../Interfaces';

interface ShowSelectedImageProps {
    handleNo: Dispatch<SetStateAction<void>>;
    visionPhotographyData: productImageProps;
    setProductImageData: Dispatch<SetStateAction<productImageProps>>;
    handleUploadClick: () => void;
    uploadLoading: boolean;
}

const ShowSelectedImage: React.FC<ShowSelectedImageProps> = ({
    handleNo,
    handleUploadClick,
    visionPhotographyData,
    setProductImageData,
    uploadLoading,
}) => {
    const [isBgReplaceSelected, setIsBgReplaceSelected] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [backgroundRemoval] = useBackgroundRemovalMutation();

    if (!visionPhotographyData.isImageDataPresent || !visionPhotographyData.imageData.data)
        return null;

    const removeBackgroundFunc = async () => {
        setLoading(true);
        const formData = new FormData();

        if (visionPhotographyData.imageData.imageType === ImageType.FILE) {
            formData.append('fileInput', visionPhotographyData.imageData.data as File);
        } else if (visionPhotographyData.imageData.imageType === ImageType.URL) {
            formData.append('previousUpload', visionPhotographyData.imageData.id as string);
        }
        const response: any = await backgroundRemoval(formData);
        if (response?.data?.body?.remove_bg_url) {
            const regexToExractFileName = /\/(-\d+)\?/;

            const match = response.data.body.remove_bg_url.match(regexToExractFileName);

            setProductImageData((prev_data) => ({
                ...prev_data,
                imageData: {
                    imageType: ImageType.URL,
                    id: match ? match[1] : prev_data.imageData.id,
                    data: response?.data?.body?.remove_bg_url,
                },
            }));
            setIsBgReplaceSelected(false);
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } else if (response.error) {
            const message = response.error?.message;
            setLoading(false);
            displayErrorToast(message);
        }
    };
    const handleUploadImage = () => {
        handleUploadClick();
    };

    const getImageSource = () =>
        visionPhotographyData.imageData.imageType === ImageType.URL
            ? visionPhotographyData.imageData.data
            : URL.createObjectURL(visionPhotographyData.imageData.data as File);

    const button_name = loading
        ? 'Processing...'
        : isBgReplaceSelected
        ? 'Remove Background'
        : 'Upload';

    return (
        <>
            <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="m-0 mb-3 d-flex align-items-center justify-content-center mx-auto"
                style={{ width: '55%' }}
            >
                <div className="mt-2 image-delete position-relative">
                    <Image src={getImageSource() as string} className="border border-grey" />
                    {loading && (
                        <motion.div
                            key="overlay"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="overlay position-absolute top-0 right-0 left-0 bottom-0 d-flex flex-column justify-content-center align-items-center w-100 rounded"
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        >
                            <LoadingSpinner show={loading} />
                            <p className="text-white">Processing your image...</p>
                        </motion.div>
                    )}
                    <button
                        className="iconbtn iconbtn-transparent"
                        onClick={(event) => {
                            event.stopPropagation();
                            handleNo();
                        }}
                    >
                        <i className="ph-bold ph-prohibit-inset"></i>
                    </button>
                </div>
            </motion.div>
            <div className="d-flex justify-content-end gap-2">
                <Button variant="outline-primary" onClick={() => handleNo()}>
                    No
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        if (isBgReplaceSelected) removeBackgroundFunc();
                        else handleUploadImage();
                    }}
                >
                    {uploadLoading ? <LoadingSpinner show={uploadLoading} /> : button_name}
                </Button>
            </div>
        </>
    );
};

export default ShowSelectedImage;
