import { useState, useEffect, memo, useRef, useLayoutEffect } from 'react';
import { Button, Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import {
    useGetActiveSessionQuery,
    useCreateNewSessionMutation,
    useRenameSessionMutation,
    useDeleteSessionMutation,
} from '../session/SessionRedux/session.api';
import { IGetChatsRequestPayload } from './apprentice.interface';
import ApprenticeChat from './ApprenticeChat';
import Paywall from '../BrandMagic/BrandMagicPages/Component/Paywall';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetFlagForOnboardedUserQuery } from '../CreateFlow/CreateFlowRedux/createFlow.api';
import { useNavigate } from 'react-router-dom';
import { setUserSubscriptionStats } from '../PaddlePayWall/PaddlePayWallRedux/paddlePayWallSlice';
import { setActiveUserEmailId } from '../Editor/EditorRedux/editorSlice';
import PolotonoEditor from '../Editor/PolotonoEditor';
import { motion, AnimatePresence } from 'framer-motion';
import { displayErrorToast } from '../../utils/ToastUtils';
import { OverlayTrigger, Tooltip, Card, Offcanvas } from 'react-bootstrap';
import plusSign from '../../assets/images/plusSign.svg';
import threeDots from '../../assets/images/threeDots.svg';
import toggleSideBar from '../../assets/images/toggleSideBar.svg';
import sidebarHover from '../../assets/images/sidebarHover.svg';
import sideBarHoverClose from '../../assets/images/sideBarHoverClose.svg';
import editPencil from '../../assets/images/editPencil.svg';
import deleteIcon from '../../assets/images/deleteIcon.svg';

const Apprentice = ({
    store,
    paddle,
    editingMode,
    setEditingMode,
    isOpen,
    setIsOpen,
    closeApprentice,
    setCloseApprentice,
    showSidebar,
    setShowSidebar,
}: any) => {
    const subscriptonStatus = useSelector((state: any) => state.paddleFlow.subscribedUser);
    const {
        data: activeSession,
        isLoading: activeSessionLoading,
        refetch: refetchActiveSession,
    } = useGetActiveSessionQuery();

    const [getFlagForOnboardedUser] = useLazyGetFlagForOnboardedUserQuery();
    const [getChatsQueryParams, setGetChatsQueryParams] = useState<IGetChatsRequestPayload>({
        sessionId: 'initial',
        all_sessions: [],
    });
    const [sessionsMap, setSessionsMap] = useState<any>([]);

    const [session_id, setSessionId] = useState('inital');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openPaywall, setOpenPaywall] = useState(false);

    const [createNewSession, { isLoading: sessionCreationGoingOn }] = useCreateNewSessionMutation();
    const [updateSession] = useRenameSessionMutation();
    const [deleteSession] = useDeleteSessionMutation();
    const [newSessionAsked, setNewSessionAsked] = useState(true);
    const [renderSpinnerNewSession, setRenderSpinnerNewSession] = useState(false);

    const [isCurrentSessionNew, setIsCurrentSessionNew] = useState(false);
    const sidebar_classname = `sidebar ${isOpen ? 'open' : ''}`;
    const toggleSideBarClassname = `toggleButton ${isOpen ? 'open' : ''}`;
    const toolTipMsg = `${isOpen ? 'Hide SideBar' : 'Show SideBar'}`;
    const isMobile = window.innerWidth <= 768;
    const [openInEditor, setOpenInEditor] = useState(false);

    useEffect(() => {
        if (store) {
            store.clear();
        }
    }, []);

    useEffect(() => {
        setEditingMode(false);
        setCloseApprentice(false);
        isMobile ? setIsOpen(false) : setIsOpen(true);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getFlagForOnboardedUser(); // get flag
                dispatch(setUserSubscriptionStats(data?.subscribedUser)); // update state
                dispatch(setActiveUserEmailId(data.email));
                if (!data?.subscribedUser) navigate('/brandProfile');
            } catch (error) {
                displayErrorToast('Server Busy Please Try Again Later');
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        refetchActiveSession();
    }, []);

    useEffect(() => {
        const refetchInitialChats = async () => {
            if (activeSession && activeSession.sessionKey) {
                try {
                    const payload: IGetChatsRequestPayload = {
                        sessionId: activeSession.sessionKey,
                        all_sessions: activeSession.all_sessions,

                        skip: 0,
                        limit: 15,
                    };
                    setGetChatsQueryParams(payload);

                    setSessionsMap(activeSession?.all_sessions);
                    setSessionId(activeSession.sessionKey);
                    setNewSessionAsked(false);
                } catch (error) {}
            }
        };

        // Refetch data if the currentPage changes
        refetchInitialChats();
    }, [activeSession]);
    useEffect(() => {
        const current_session_details = getChatsQueryParams.all_sessions?.find(
            (element: any) => element.sessionKey === session_id,
        );
        if (current_session_details?.new) {
            setShowSidebar(false);
        }
    }, [getChatsQueryParams, session_id]);
    const toggleApprentice = () => setCloseApprentice(!closeApprentice);
    const toggleEditingMode = () => setEditingMode(true);

    const fadeInOutVariants = {
        hidden: {
            opacity: 0,
            x: -10,
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.5,
                ease: 'easeOut',
            },
        },
        exit: {
            opacity: 0,
            x: 0,
            transition: {
                duration: 0,
                ease: 'linear',
            },
        },
    };
    const transitionSettings = {
        duration: 0.5,
        ease: [0.22, 0.88, 0.76, 1],
    };

    const shadowStyle = {
        boxShadow: '-10px 0 15px -3px rgba(0, 0, 0, 0.1)',
    };
    const renderSpinner = () => {
        return (
            <div className="d-flex justify-content-center mt-5">
                <Spinner
                    animation="border"
                    className="active-session-loading-spinner"
                    style={{ color: '#967AA7' }}
                />
            </div>
        );
    };
    const handleUpdateSessionClick = async (sessionKey: string, currentName: string) => {
        await updateSession({
            id: sessionKey,
            data: {
                name: currentName,
            },
        });
    };
    const handleDeleteSession = async (sessionKey: string) => {
        const deletedSession = await deleteSession({
            id: sessionKey,
        });

        return true;
    };

    const handleCreateNewSessionClick = async () => {
        // Start the spinner
        const data = sessionsMap.find((elem: any) => elem.new === true); // If there is an empty session no new session will be created
        if (data) {
            handleSessionClick(data.sessionKey);
            return;
        }
        setRenderSpinnerNewSession(true);
        await createNewSession();
        const newData = await refetchActiveSession();

        if (!newData.data) return;

        const payload: IGetChatsRequestPayload = {
            sessionId: newData.data.sessionKey,
            all_sessions: newData.data.all_sessions,
            skip: 0,
            limit: 15,
        };

        setGetChatsQueryParams(payload);
        setSessionsMap(payload.all_sessions);
        setSessionId(payload.sessionId);
        setRenderSpinnerNewSession(false);
        // End the Spinner
    };
    const handleSessionClick = (sessionKey: string) => {
        store.clear();
        toggleEditor();
        setGetChatsQueryParams((prevParams) => ({
            ...prevParams,
            sessionId: sessionKey,
            skip: 0,
            limit: 15,
        }));

        setSessionId(sessionKey);
        setShowSidebar(false);
    };
    const UpdateSessionObject = (index: number, key: string, value: any) => {
        setSessionsMap((prevData: any) =>
            prevData.map((item: any, i: any) => (i === index ? { ...item, [key]: value } : item)),
        );
    };

    const handleUpdateNameSessions = (index: number, name: string) => {
        UpdateSessionObject(index, 'name', name);
    };
    const deleteUpdateSessions = (index: number) => {
        setSessionsMap((prevData: any) => prevData.filter((_: any, i: any) => i !== index));
    };
    const handleNewSessionChatStart = () => {
        setSessionsMap((prevData: any) =>
            prevData.map((item: any, i: any) =>
                item.new === true ? { ...item, ['new']: false } : item,
            ),
        );
    };
    // const [showSidebar, setShowSidebar] = useState(false);
    const handleHideSidebar = () => {
        setShowSidebar(false); // Hide the sidebar
    };

    const sidebarRef = useRef<HTMLDivElement>(null);

    const [sidebarWidth, setSidebarWidth] = useState(0);
    const toggleLeft = sidebarWidth + 16;
    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            // entries[0].contentRect.width provides the width of the observed element
            if (entries && entries[0]) {
                const { width } = entries[0].contentRect;
                setSidebarWidth(width);
            }
        });

        if (sidebarRef.current) {
            observer.observe(sidebarRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);
    const SideBar = () => {
        const [overlaySidebarEditShow, setOverlaySidebarEditShow] = useState(-1);

        const [sessionIndexEditable, setSessionIndexEditable] = useState(-1);

        const sidebarRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (sidebarRef.current) {
                const width = sidebarRef.current.offsetWidth;
                setSidebarWidth(width);
            }
        }, []);
        const RenderSessions = () => {
            return (
                <>
                    <div
                        className="d-flex justify-content-between align-items-center p-3 position-sticky border-bottom"
                        // onClick={(event) => {
                        //     event.stopPropagation(); // Prevent onClick from bubbling up
                        //     handleCreateNewSessionClick();
                        // }}
                        onClick={handleCreateNewSessionClick}
                        style={{
                            cursor: 'pointer',
                            zIndex: 10000,
                            top: '16px',
                            left: '0',
                            right: '0',
                            width: '100%',
                            height: '48px',
                        }}
                    >
                        <h3
                            className="m-0  flex-grow-1"
                            // onClick={() => setIsOpen(!isOpen)}
                        >
                            New Chat
                        </h3>
                        {renderSpinnerNewSession ? (
                            <Spinner
                                animation="border"
                                className="active-session-loading-spinner lg"
                                style={{ color: '#967AA7' }}
                            />
                        ) : (
                            <i
                                className="ph ph-plus lg"
                                // onClick={handleCreateNewSessionClick}
                                style={{ cursor: 'pointer' }}
                            ></i>
                        )}
                    </div>
                    <div
                        className="overflow-scroll sidebar_content pb-5"
                        style={{ height: 'calc(100vh - 90px)', marginTop: '1.5rem' }}
                    >
                        {sessionsMap.map((session: any, index: number) => (
                            <div key={index}>
                                <div
                                    className={`sidebar-session position-relative mb-3 ${
                                        session.sessionKey === session_id ? 'currentWorking' : ''
                                    } `}
                                >
                                    <div
                                        className="sidebar-session-wrapper d-flex justify-content-between align-items-center m-0 pe-3"
                                        // onClick={() => handleSessionClick(session.sessionKey)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {index !== sessionIndexEditable ? (
                                            <div
                                                onClick={() =>
                                                    handleSessionClick(session.sessionKey)
                                                }
                                                className="w-75 p-3  h-auto"
                                            >
                                                {session.name}
                                            </div>
                                        ) : (
                                            <div className="p-3 sidebar-session-name w-75">
                                                <input
                                                    ref={(input) => {
                                                        if (
                                                            input &&
                                                            index === sessionIndexEditable
                                                        ) {
                                                            setOverlaySidebarEditShow(-1);
                                                            input.focus();
                                                        }
                                                    }}
                                                    style={{ border: '1px solid #6f4e84' }}
                                                    className="w-100 rounded"
                                                    type="text"
                                                    defaultValue={session.name}
                                                    onBlur={(e) => {
                                                        handleUpdateSessionClick(
                                                            session.sessionKey,
                                                            e.target.value,
                                                        );
                                                        handleUpdateNameSessions(
                                                            index,
                                                            e.target.value,
                                                        );
                                                    }}
                                                    onKeyPress={(e: any) => {
                                                        if (e.key === 'Enter') {
                                                            handleUpdateSessionClick(
                                                                session.sessionKey,
                                                                e.target.value,
                                                            );
                                                            handleUpdateNameSessions(
                                                                index,
                                                                e.target.value,
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className="sidebar_options">
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 150, hide: 400 }}
                                                overlay={<Tooltip>Rename</Tooltip>}
                                            >
                                                <i
                                                    className="ph ph-pencil-line"
                                                    onClick={() => setSessionIndexEditable(index)}
                                                ></i>
                                            </OverlayTrigger>

                                            {session_id != session.sessionKey &&
                                                sessionsMap.length !== 1 && (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 150, hide: 400 }}
                                                        overlay={<Tooltip>Delete</Tooltip>}
                                                    >
                                                        <i
                                                            className="ph ph-trash"
                                                            onClick={() => {
                                                                deleteUpdateSessions(index);
                                                                handleDeleteSession(
                                                                    session.sessionKey,
                                                                );
                                                            }}
                                                        ></i>
                                                    </OverlayTrigger>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        };

        return (
            <div className={'position-relative vh-100 '} ref={sidebarRef}>
                {RenderSessions()}
            </div>
        );
    };
    const Toggle = () => {
        return (
            <div
                className="position-absolute toggleButton"
                style={{ left: isOpen ? toggleLeft : '0%' }}
            >
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 150, hide: 400 }}
                    overlay={<Tooltip>{toolTipMsg}</Tooltip>}
                >
                    <div
                        // className={toggleSideBarClassname}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <img src={toggleSideBar} alt="toggleSideBar" className="toggle-image" />
                        <img
                            src={`${isOpen ? sidebarHover : sideBarHoverClose}`}
                            alt="sidebarHover"
                            className="toggle-image-hover"
                        />
                    </div>
                </OverlayTrigger>
            </div>
        );
    };
    const toggleOffcanvas = () => setShowSidebar(!showSidebar);
    const toggleEditor = () => {
        setIsOpen(false);
        setOpenInEditor(false);
        setEditingMode(false);
        setCloseApprentice(false);
    };
    const renderContentNew = () => {
        const toShowLoader = activeSessionLoading || !activeSession || !activeSession.sessionKey;
        const showApprenticeChat =
            getChatsQueryParams && getChatsQueryParams.sessionId !== 'initial';

        if (toShowLoader) return renderSpinner();

        if (showApprenticeChat) {
            const current_session_details = getChatsQueryParams.all_sessions?.find(
                (element: any) => element.sessionKey === session_id,
            );
            return (
                <>
                    {editingMode && isMobile !== true && (
                        <div className="bg-white">
                            {showSidebar ? (
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 150, hide: 400 }}
                                    overlay={<Tooltip>Close Sidebar</Tooltip>}
                                >
                                    <Button
                                        style={{
                                            position: 'fixed',
                                            top: 50,
                                            left: showSidebar
                                                ? '310px'
                                                : closeApprentice
                                                ? '3%'
                                                : '1%',
                                            zIndex: 1050, // Ensure it's above other content
                                        }}
                                        variant="primary"
                                        className="iconbtn rounded-circle"
                                        onClick={toggleOffcanvas}
                                    >
                                        <i className="ph ph-caret-left"></i>
                                    </Button>
                                </OverlayTrigger>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                    {isMobile && showSidebar ? (
                        <Button
                            style={{
                                position: 'fixed',
                                top: 60,
                                left: '78%',
                                zIndex: 1050, // Ensure it's above other content
                            }}
                            variant="primary"
                            className="iconbtn rounded-circle"
                            onClick={toggleOffcanvas}
                        >
                            {showSidebar ? (
                                <i className="ph ph-caret-left"></i>
                            ) : (
                                <i className="ph ph-caret-right"></i>
                            )}
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Row className="justify-content-center p-0 m-0 combinedView">
                        {editingMode || isMobile ? (
                            <>
                                <Offcanvas show={showSidebar} onHide={handleHideSidebar}>
                                    <Offcanvas.Body className="p-0 m-0 px-2">
                                        <SideBar />
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </>
                        ) : (
                            <Col
                                lg={2}
                                className={`sidebar ${
                                    isOpen ? 'open' : 'd-none'
                                } border-end position-relative px-2`}
                                ref={sidebarRef}
                            >
                                <SideBar />
                            </Col>
                        )}

                        {/* {editingMode || isMobile ? '' : <Toggle />} */}
                        {isMobile ? (
                            !editingMode ? (
                                <Col sm={12} className="d-flex flex-column align-items-center">
                                    <ApprenticeChat
                                        key={session_id}
                                        chatQueryParams={getChatsQueryParams}
                                        sessionKey={session_id}
                                        newSession={current_session_details.new}
                                        setGetChatsQueryParams={setGetChatsQueryParams}
                                        store={store}
                                        handleNewSessionChatStart={handleNewSessionChatStart}
                                        sidebarOpen={false}
                                        toggleEditingMode={toggleEditingMode}
                                        editingMode={editingMode}
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        openInEditor={openInEditor}
                                        setOpenInEditor={setOpenInEditor}
                                    />
                                </Col>
                            ) : (
                                <Col style={shadowStyle} className="p-0" sm={12}>
                                    <PolotonoEditor store={store} paddle={paddle} />
                                </Col>
                            )
                        ) : (
                            <>
                                <Col
                                    lg={isOpen ? '10' : editingMode ? '3' : '8'}
                                    className={`${
                                        closeApprentice
                                            ? 'd-none'
                                            : 'd-flex flex-column align-items-center'
                                    }`}
                                >
                                    <ApprenticeChat
                                        key={session_id}
                                        chatQueryParams={getChatsQueryParams}
                                        sessionKey={session_id}
                                        newSession={current_session_details.new}
                                        setGetChatsQueryParams={setGetChatsQueryParams}
                                        store={store}
                                        handleNewSessionChatStart={handleNewSessionChatStart}
                                        sidebarOpen={false}
                                        toggleEditingMode={toggleEditingMode}
                                        editingMode={editingMode}
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        openInEditor={openInEditor}
                                        setOpenInEditor={setOpenInEditor}
                                    />
                                </Col>

                                {editingMode && (
                                    <Col
                                        lg={closeApprentice ? '12' : '9'}
                                        style={shadowStyle}
                                        className="p-0"
                                    >
                                        <div
                                            className="position-relative"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {/* Button group */}
                                            <div
                                                className="position-absolute top-0 start-0 d-flex flex-row gap-1 align-items-center p-1"
                                                style={{ backgroundColor: '#F5F5F5' }}
                                            >
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    delay={{ show: 150, hide: 400 }}
                                                    overlay={<Tooltip>Close</Tooltip>}
                                                >
                                                    <div>
                                                        <Button
                                                            variant="outline-primary"
                                                            className="iconbtn rounded-circle border-0"
                                                            onClick={toggleEditor}
                                                        >
                                                            <i className="ph ph-x"></i>
                                                        </Button>
                                                    </div>
                                                </OverlayTrigger>

                                                {closeApprentice ? (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 150, hide: 400 }}
                                                        overlay={<Tooltip>Split View</Tooltip>}
                                                    >
                                                        <div>
                                                            <Button
                                                                variant="outline-primary"
                                                                className="iconbtn rounded-circle border-0"
                                                                onClick={toggleApprentice}
                                                            >
                                                                <i className="ph ph-corners-in"></i>
                                                            </Button>
                                                        </div>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 150, hide: 400 }}
                                                        overlay={<Tooltip>Full View</Tooltip>}
                                                    >
                                                        <div>
                                                            <Button
                                                                variant="outline-primary"
                                                                className="iconbtn rounded-circle border-0"
                                                                onClick={toggleApprentice}
                                                            >
                                                                <i className="ph ph-corners-out"></i>
                                                            </Button>
                                                        </div>
                                                    </OverlayTrigger>
                                                )}
                                            </div>

                                            {/* Tabs */}
                                            <div style={{ flex: 1 }} className="apprentice_tabs">
                                                <Tabs
                                                    defaultActiveKey="editor"
                                                    id="controlled-tab-example"
                                                    className="tabs"
                                                >
                                                    <Tab
                                                        eventKey="editor"
                                                        title=""
                                                        className="tabs-button"
                                                    >
                                                        <PolotonoEditor
                                                            store={store}
                                                            paddle={paddle}
                                                        />
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </>
                        )}
                    </Row>
                </>
            );
        }
    };
    return (
        <>
            <Paywall
                show={!subscriptonStatus}
                onHide={() => setOpenPaywall(false)}
                paddle={paddle}
            />
            {renderContentNew()}
            {/* {renderContent()} */}

            {/* {editingMode ? renderContentNew() : renderContent()} */}
        </>
    );
};

export default memo(Apprentice);
