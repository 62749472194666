import { createSlice } from '@reduxjs/toolkit';
import { ITemplateFlowState } from './types';

const initialState: ITemplateFlowState = {
    apprenticeTemplate: null,
    extraTemplateFields: null,
};

export const templateFlowSlice = createSlice({
    name: 'templateFlow',
    initialState,
    reducers: {
        setApprenticeTemplate: (state, { payload }) => {
            state.apprenticeTemplate = payload;
        },
        setExtraTemplateFields: (state, { payload }) => {
            state.extraTemplateFields = payload;
        },
    },
});

export const { setApprenticeTemplate, setExtraTemplateFields } = templateFlowSlice.actions;
export default templateFlowSlice.reducer;
