import { Dispatch, SetStateAction } from 'react';

export interface IChat {
    id?: string;
    _id?: string;
    session_id: string;
    type: string;
    value: any;
    system: boolean;
    template?: any;
    created_at: Date;
    updated_at: Date;
}
interface ChatValue {
    creative_concept: string;
    url: string;
}
export interface IChatApprenticeRequestpayload {
    prompt: string;
    sessionId: string;
}

export interface INewTemplateFromEditorRequestpayload {
    template: any;
    sessionId: string;
}

export interface IPresignTemplateRequestpayload {
    template: any;
}

export interface IChatApprenticeResponse {
    success: boolean;
    source: string;
    data: {
        chats: IChat[];
    };
    message: string;
    status: number;
}

export interface IGetChatsRequestPayload {
    sessionId: string;
    skip?: number;
    limit?: number;
    all_sessions?: Array<any>;
}

export interface IGetChatsResponse {
    success: boolean;
    source: string;
    data: {
        chats: IChat[];
    };
    message: string;
    status: number;
}

export enum ChatTypeEnum {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    HISTORY = 'HISTORY',
    POLOTONO_PREVIEW = 'POLOTONO_PREVIEW',
    RESPONSE_TEXT = 'RESPONSE_TEXT',
    FROM_EDITOR = 'FROM_EDITOR',
}

export interface IParams {
    chatQueryParams: IGetChatsRequestPayload;
    sessionKey: string;
    newSession: boolean;
    setGetChatsQueryParams: Dispatch<SetStateAction<IGetChatsRequestPayload>>;
    store: any;
    toggleEditingMode: () => void;
    editingMode: boolean;
    sidebarOpen: boolean;
    isOpen: boolean;
    setIsOpen: any;
    handleNewSessionChatStart: () => void;
    openInEditor: boolean;
    setOpenInEditor: any;
}
export interface SuggestionDataItem {
    id: string | number;
    display: string;
    icon?: string;
    subtitle?: string;
}
