import React, { useEffect, useState } from 'react';
import { setCreditExpired } from '../CreateFlow/CreateFlowRedux/createFlowSlice';
import { useAppDispatch, useAppSelector } from '../../commons/hooks/redux';
import { Button, Dropdown, InputGroup, Spinner } from 'react-bootstrap';
import { selectCreateFlow } from '../CreateFlow/CreateFlowRedux/selector';
import { useCreateTextGenerationMutation } from '../CreateFlow/CreateFlowRedux/createFlow.api';
import { MentionsInput, Mention } from 'react-mentions';
import { TEST_DATA } from '../../constants/createFlow';
import mentionStyles from '../../assets/styles/mentionStyle/mentionStyles';
import mentionsInputStyles from '../../assets/styles/mentionStyle/mentionsInputStyles.';
import { useGetBrandMagicCopyInfoQuery } from '../BrandMagic/BrandMagicRedux/brandMagic.api';

interface ITextPromptArea {
    inputRef?: React.RefObject<HTMLInputElement>;
    handleSendTextData: () => void;
    inputValue: string;
    setInputValue: any;
}
const TextPromptArea = ({
    inputRef,
    handleSendTextData,
    inputValue,
    setInputValue,
}: ITextPromptArea) => {
    //Mentions Text Value
    const { textInputValue } = useAppSelector(selectCreateFlow);
    const [customerSegments, setCustomerSegments] = useState<any[]>([]);
    const [potions, setPotions] = useState<any[]>([]);
    const [brandVoiceStatus, setBrandVoiceStatus] = useState(true);
    const [createTextGeneration, { isLoading }] = useCreateTextGenerationMutation();
    const { data } = useGetBrandMagicCopyInfoQuery();

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (data) {
            const { customerSegments, potions } = data;
            if (Array.isArray(customerSegments)) {
                const transformedCustomerSegments = customerSegments.map((segment) => ({
                    id: segment.target,
                    display: `@${segment.target} `,
                }));
                setCustomerSegments(transformedCustomerSegments);
            }
            if (Array.isArray(potions)) {
                const transformedPotions = potions.map((potion) => ({
                    id: potion.potion,
                    display: `~${potion.potion} `,
                }));
                setPotions(transformedPotions);
            }
        }
    }, [data]);

    const handleInputChange = (event: any) => {
        const value = event.target.value;
        setInputValue(value);
        // dispatch(updateTextInputValue(value));
    };
    const handleDropdownSelect = (value: string): void => {
        setInputValue(inputValue.endsWith(' ') ? inputValue + value : inputValue + ' ' + value);
        // dispatch(updateTextInputValue(textInputValue + value));

        // Set focus on the input field
        if (inputRef?.current) {
            inputRef?.current.focus();
        }
    };
    const toggleBrandVoiceStatus = () => {
        setBrandVoiceStatus(!brandVoiceStatus);
    };
    const handleGenerateText = async () => {
        const cleanedString = inputValue.replace(/@\[(.*?)\]\((.*?)\)/g, '$1');

        const regex = /[@~](\w+)(?:\s|$)/g;
        const commandRegex = /[\/@~](\w+)/g;

        const extractedCommands = [];
        const extractedSegments = [];
        const extractedPotions = [];

        let match;

        while ((match = regex.exec(cleanedString)) !== null) {
            if (match[0].startsWith('@')) {
                extractedSegments.push(match[1]);
            } else if (match[0].startsWith('~')) {
                extractedPotions.push(match[1]);
            }
        }

        while ((match = commandRegex.exec(cleanedString)) !== null) {
            if (match[0].startsWith('/')) {
                extractedCommands.push(match[1]);
            }
        }

        try {
            const res = await createTextGeneration({
                prompt: extractedCommands.length > 0 ? cleanedString : `/Caption ${cleanedString}`,
                command: extractedCommands.length > 0 ? extractedCommands[0] : 'Caption',
                customerSegments: extractedSegments ?? [],
                potions: extractedPotions ?? [],
                brandVoice: brandVoiceStatus,
                noOfOptions: 0,
            }).unwrap();
            handleSendTextData();
            // setInputValue("");
            // dispatch(updateTextInputValue(""));
        } catch (errorStatus) {
            if (errorStatus === 402) {
                dispatch(setCreditExpired(true));
            }
        }
    };

    return (
        <div className="userPrompt_input">
            <MentionsInput
                value={inputValue}
                onChange={(e) => handleInputChange(e)}
                style={mentionsInputStyles}
                className="mention-tag "
            >
                <Mention
                    trigger="@"
                    data={customerSegments}
                    style={mentionStyles}
                    className="mention"
                />
                <Mention trigger="/" data={TEST_DATA} style={mentionStyles} className="mention" />
                <Mention trigger="~" data={potions} style={mentionStyles} className="mention" />
            </MentionsInput>
            <div className=" userPrompt_options d-flex justify-content-between">
                <InputGroup className="custom-dropdown">
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                            variant="white"
                            className="dropdown__toggle iconbtn"
                            id="dropdown-autoclose-outside"
                        >
                            <i className="pxi-comment-2-text lg"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown__toggle--menu">
                            <Dropdown.Item
                                onClick={() => handleDropdownSelect(' @[/Headline ](Headline) ')}
                            >
                                Headline
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleDropdownSelect('@[/Caption ](Caption)')}
                            >
                                Caption
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button
                        variant={`${brandVoiceStatus ? 'primary' : 'white'}`}
                        className={`iconbtn`}
                        onClick={toggleBrandVoiceStatus}
                    >
                        {' '}
                        <i className="ph ph-megaphone lg"></i>
                    </Button>
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                            variant="white"
                            className="dropdown__toggle iconbtn"
                            id="dropdown-autoclose-outside"
                        >
                            <i className="ph ph-target lg"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown__toggle--menu">
                            {customerSegments && customerSegments.length > 0 ? (
                                customerSegments.map((segment, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() =>
                                            handleDropdownSelect(
                                                `@[@${segment.id} ](${segment.id})`,
                                            )
                                        }
                                    >
                                        {segment.id}
                                    </Dropdown.Item>
                                ))
                            ) : (
                                <Dropdown.Item disabled>NO TARGET CUSTOMERS ADDED</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                            variant="white"
                            className="dropdown__toggle iconbtn"
                            id="dropdown-autoclose-outside"
                        >
                            <i className="ph ph-test-tube lg"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown__toggle--menu">
                            {potions && potions.length > 0 ? (
                                potions.map((potion, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() =>
                                            handleDropdownSelect(`@[~${potion.id} ](${potion.id})`)
                                        }
                                    >
                                        {potion.id}
                                    </Dropdown.Item>
                                ))
                            ) : (
                                <Dropdown.Item disabled>NO POTIONS ADDED</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </InputGroup>
                <Button onClick={handleGenerateText} disabled={isLoading} className="iconbtn">
                    {isLoading ? (
                        <Spinner animation="border" style={{ width: '24px', height: '24px' }} />
                    ) : (
                        <i className="pxi-send lg"></i>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default TextPromptArea;
