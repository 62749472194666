import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { useCreateImagePotionMutation } from '../CreateFlowRedux/createFlow.api';
import { displayErrorToast, displaySuccessToast } from '../../../utils/ToastUtils';

interface AddToFavoriteProps {
    show: boolean;
    onHide: () => void;
    data: any;
}
const AddToFavoriteModal: React.FC<AddToFavoriteProps> = ({
    data,
    onHide,
    show,
}: AddToFavoriteProps) => {
    const [name, setName] = useState('');

    const [createPotion] = useCreateImagePotionMutation();
    const [loading, setLoading] = useState(false);
    const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = evt.target?.value;

        setName(val);
    };
    const handleSubmit = async () => {
        if (!name) {
            return displayErrorToast('Favourite title is required');
        }
        setLoading(true);
        try {
            const res: any = await createPotion({
                potionTitle: name,
                potionEffect: data.prompt,
                pixiiPotion: data.pixiiPotion,
                modelType: data.model,
            });
            if (res?.error) {
                throw new Error(res.error);
            } else {
                displaySuccessToast('Favourite created successfully');
                onHide();
            }
        } catch (e) {
            displayErrorToast('There was a problem creating favourite');
            console.log('ran', e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="addPotion"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="fw-semibold h1 ">
                    Add to your favorites
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="addPotion-form">
                <Form>
                    <Form.Group className="content_potion editable">
                        <Form.Label className="potion_name text-primary fw-semibold h4 ">
                            Favorite Name
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="potion_effect"
                            id="potion-name"
                            placeholder="What will you name this potion?"
                            onChange={handleChange}
                            value={name}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className={`content_potion effect_textarea`}>
                        <Form.Label className="text-primary fw-semibold h4 potion_name ">
                            Favorite Effect
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            className="potion_effect "
                            id="potion-effect"
                            rows={1}
                            defaultValue={data.prompt}
                            readOnly={true}
                        />
                    </Form.Group>
                    <Form.Group className="content_potion">
                        <Form.Label className="text-primary fw-semibold h4 potion_name">
                            Pixii Potion
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="potion_effect"
                            id="style-preset"
                            defaultValue={data.pixiiPotion || 'No style'}
                            readOnly={true}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 content_potion">
                        <Form.Label className="text-primary fw-semibold h4 potion_name">
                            Choice of Model
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="potion_effect"
                            id="choice-modal"
                            defaultValue={data.model}
                            readOnly={true}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="white" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddToFavoriteModal;
