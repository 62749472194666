export const LOADING_MESSAGES = [
    'Analyzing your brand ',
    'Brainstorming ideas',
    'Conjuring spells ',
    'Crafting narrative ',
    'Drafting visuals ',
    'Mixing potions ',
    'Drawing design',
    'Polishing those final touches ',
    'Consulting the brand oracle ',
    'Brewing a cauldron of ideas ',
    'Optimizing for ROI ',
    'Penning potions ',
    'Painting the canvas ',
    'Performing digital alchemy ',
    'Weaving the design ',
    'Composing your mosaic ',
    'Crunching on-brand data ',
    'Spinning up ideas ',
    'Dipping feather pen into ink ',
    'Splashing paint ',
    'Positioning pixels ',
    'Merging your masterpiece',
];

export const COMMANDS = [
    {
        id: 'Create',
        display: 'Create',
        icon: 'ph-paint-brush',
        subtitle: 'an ad or social media post',
    },
    {
        id: 'Brainstorm',
        display: 'Brainstorm',
        icon: 'ph-lightbulb',
        subtitle: 'campaign ideas',
    },
    {
        id: 'Greenscreen',
        display: 'Greenscreen',
        icon: 'ph-eraser',
        subtitle: 'put your product on any bg',
    },
    {
        id: 'Variants',
        display: 'Variants',
        icon: 'ph-circles-three-plus',
        subtitle: 'Create varaints of the ad',
    },
    {
        id: 'Resize',
        display: 'Resize',
        icon: 'ph-arrows-out-simple',
        subtitle: 'Change the size of the ad',
    },
    // {
    //     id: 'Upload',
    //     display: 'Upload',
    //     icon: 'ph-file-arrow-up',
    //     subtitle: 'select an image to edits',
    // },
];

export const TIPS = [
    'Create an Instagram story ad targeting families',
    'Resize the ad for Facebook',
    'Revise the headline to be more concise',
    'What can you do?',
    'Add an image layer of futuristic patterns',
];

export const COMPONENT_ARR = ['c', '/', 'V', 'v', 'B', 'b', 'R', 'r', 'G', 'g', 'U', 'u', 'C'];
