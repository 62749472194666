import React, { useRef } from 'react';
import {
    InputGroup,
    Dropdown,
    DropdownMenu,
    OverlayTrigger,
    Tooltip,
    Badge,
} from 'react-bootstrap';

interface FileUploadSelectorProps {
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleImageIconClick: () => void;
    handleVisionIconClick: () => void;
    show: boolean;
    handleAttachedFilesClick: () => void;
}

const FileUploadSelector: React.FC<FileUploadSelectorProps> = ({
    handleFileChange,
    handleVisionIconClick,
    handleImageIconClick,
    show,
    handleAttachedFilesClick,
}) => {
    const docInputRef = useRef<HTMLInputElement>(null);

    const handleFileClick = () => {
        if (docInputRef.current) {
            docInputRef.current.click();
        }
    };
    if (!show) return null;
    return (
        <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="button-tooltip-2">Attach a file</Tooltip>}
            trigger="hover"
        >
            <InputGroup
                className="custom-dropdown"
                style={{ width: '3.4375rem', height: '3.4375rem' }}
                onClick={handleAttachedFilesClick}
            >
                <Dropdown id="dropdown-menu-align-end" className="dropdown__toggle iconbtn">
                    <Dropdown.Toggle
                        variant="white"
                        className="dropdown__toggle iconbtn"
                        id="dropdown-autoclose-outside"
                        style={{ width: '3.4375rem', height: '3.4375rem' }}
                    >
                        <i className="ph ph-plus lg"></i>
                    </Dropdown.Toggle>
                    <DropdownMenu className="dropdown__toggle--menu" style={{ width: '350px' }}>
                        <Dropdown.Item onClick={handleImageIconClick}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    whiteSpace: 'normal',
                                }}
                            >
                                <div className="p-2 me-2 rounded bg-white d-flex align-items-center justify-content-center">
                                    <i className="pxi-image-plus lg"></i>
                                </div>
                                <div>
                                    <div>Product photo</div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Use your image in assets for AI greenscreen effect
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Item>
                        <>
                            <input
                                type="file"
                                ref={docInputRef}
                                style={{ display: 'none' }}
                                accept=".pdf"
                                onChange={handleFileChange}
                            />
                            <Dropdown.Item onClick={handleFileClick}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    <div className="p-2 me-2 rounded bg-white d-flex align-items-center justify-content-center">
                                        <i className="ph ph-file-arrow-up lg"></i>
                                    </div>
                                    <div>
                                        <div>PDF upload</div>
                                        <div style={{ fontSize: '0.85rem' }}>
                                            I'll read your document to make assets
                                        </div>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        </>

                        <Dropdown.Item onClick={handleVisionIconClick}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    whiteSpace: 'normal',
                                }}
                            >
                                <div className="p-2 me-2 rounded bg-white d-flex align-items-center justify-content-center">
                                    <i className="ph ph-scan lg"></i>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        Visual layout{' '}
                                        <Badge bg="light" className="ms-2">
                                            Beta
                                        </Badge>
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Design a rough draft based on your image
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Item>
                    </DropdownMenu>
                </Dropdown>
            </InputGroup>
        </OverlayTrigger>
    );
};

export default FileUploadSelector;
