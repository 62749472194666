import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CreateFlowState, IFavorite } from './createFlowTypes';

const initialState: CreateFlowState = {
    favorites: [],
    filteredTaglines: [],
    filteredCaptions: [],
    filteredHeadlines: [],
    userData: null,
    generatedTagsAndImages: [
        {
            userProfile:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60',

            generatedTags: [
                {
                    prompt: 'Generate tags and captions',
                    command: 'generate',
                    results: [
                        {
                            segment: 'segment1',
                            txt: 'Generated text 1',
                        },
                        {
                            segment: 'segment2',
                            txt: 'Generated text 2',
                        },
                        {
                            segment: 'segment3',
                            txt: 'Generated text 3',
                        },
                    ],
                },
                {
                    prompt: 'Generate more tags and captions',
                    command: 'generate',
                    results: [
                        {
                            segment: 'segment4',
                            txt: 'Generated text 4',
                        },
                        {
                            segment: 'segment5',
                            txt: 'Generated text 5',
                        },
                    ],
                },
            ],
            generatedImages: [
                {
                    prompt: "An image showing a bold, visionary future people and planets where MailMngr's cutting-edge technology enables businesses to connect with their customers in a truly immersive way",
                    images: [
                        'https://pixii-resources-public-dev.s3.us-west-2.amazonaws.com/1003871811-1685957624837',
                        'https://pixii-resources-public-dev.s3.us-west-2.amazonaws.com/1003871811-1685957624837',
                        'https://res.cloudinary.com/dvovwg3k7/image/upload/v1684834407/samples/ecommerce/car-interior-design.jpg',
                        'https://res.cloudinary.com/dvovwg3k7/image/upload/v1684834407/samples/landscapes/beach-boat.jpg',
                    ],
                },
                {
                    prompt: "Generate more An image showing a bold, visionary future people and planets where MailMngr's cutting-edge technology enables businesses to connect with their customers in a truly immersive way",
                    images: [
                        'https://res.cloudinary.com/dvovwg3k7/image/upload/v1684834408/samples/ecommerce/accessories-bag.jpg',
                        'https://res.cloudinary.com/dvovwg3k7/image/upload/v1684834408/samples/ecommerce/accessories-bag.jpg',
                        'https://res.cloudinary.com/dvovwg3k7/image/upload/v1684834407/samples/ecommerce/car-interior-design.jpg',
                        'https://res.cloudinary.com/dvovwg3k7/image/upload/v1684834407/samples/landscapes/beach-boat.jpg',
                    ],
                },
            ],
        },
    ],
    textInputValue: '',
    editCardElements: [],
    filteredImages: [],
    creditExpired: false,
    pixiiTab: 'image',
    onBoardedUser: false,
};

export const createFlowSlice = createSlice({
    name: 'createFlow',
    initialState,
    reducers: {
        updateTextInputValue: (state, action: PayloadAction<string>) => {
            state.textInputValue = action.payload;
        },
        addFavourites: (state, action: PayloadAction<IFavorite[]>) => {
            const filteredHeadline = action.payload?.filter(
                (tagline) => tagline?.resultType === 'Headline',
            );
            const filteredTaglines = action.payload?.filter(
                (tagline) => tagline?.resultType === 'Tagline',
            );
            const filteredImage = action.payload?.filter(
                (tagline) => tagline?.resultType === 'Image',
            );
            state.favorites = action.payload;
            state.filteredTaglines = [...filteredHeadline, ...filteredTaglines];
            state.filteredCaptions = action.payload?.filter(
                (tagline) => tagline?.resultType === 'Caption',
            );
            // state.filteredHeadlines = action.payload?.filter(
            //   (tagline) => tagline?.resultType === "Headline"
            // );
            state.filteredImages = filteredImage;
            //   filteredImage?.map((image, index) => {
            //     console.log(
            //       filteredCaptions?.map((caption) => {
            //         return { caption };
            //       })
            //     );
            //     return { image: image?.selectedResult, elements: [{}] };
            //   }),
            //   "filteredImage"
            // );
            // state.editCardElements = [
            //   {
            //     id: 1,
            //     image: `${APP_AMAZON_URL}/${filteredImage[0]?.selectedResult}`,
            //     segment: "@Enterprise",
            //     elements: [
            //       {
            //         id: 1,
            //         type: "image",
            //         src: brandLogo,
            //         x: 50,
            //         y: 50,
            //         width: 25,
            //         height: 25,
            //         color: "red",
            //       },
            //       {
            //         id: 2,
            //         type: "image",
            //         src: image,
            //         x: 50,
            //         y: 50,
            //         width: 100,
            //         height: 100,
            //         color: "red",
            //       },
            //       {
            //         id: 3,
            //         value: "some text describing the card",
            //         x: 100,
            //         y: 100,
            //         width: 100,
            //         height: 100,
            //         color: "red",
            //       },
            //     ],
            //   },
            // ];
        },
        setCreditExpired: (state, action: PayloadAction<boolean>) => {
            state.creditExpired = action.payload;
        },
        //   const newImageInputValue: string = action.payload;

        //   if (
        //     state.generatedTagsAndImages &&
        //     state.generatedTagsAndImages.length > 0
        //   ) {
        //     const newGeneratedTagsAndImages: IgeneratedTextAndImages = {
        //       ...state.generatedTagsAndImages[0],
        //       generatedImages: [
        //         ...state.generatedTagsAndImages[0].generatedImages,
        //         {
        //           prompt: newImageInputValue,
        //           images: [],
        //         },
        //       ],
        //     };

        //     state.generatedTagsAndImages[0] = newGeneratedTagsAndImages;
        //   }addFavourites+

        // },
        setPixiiTab: (state, action: PayloadAction<string>) => {
            state.pixiiTab = action.payload;
        },
        setOnBoardedUser: (state, action) => {
            state.onBoardedUser = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    updateTextInputValue,
    addFavourites,
    setCreditExpired,
    setPixiiTab,
    setOnBoardedUser,
    setUserData,
} = createFlowSlice.actions;

export default createFlowSlice.reducer;
