import React, { useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Mention, MentionsInput } from 'react-mentions';

import { COMMANDS, COMPONENT_ARR } from './constants';
import style from './styles.module.css';
import { SuggestionDataItem } from './apprentice.interface';

interface ChatInputProps {
    value: string;
    setValue: (value: string) => void;
    handleGetAdFromEditor: () => Promise<void>;
    setTwoWayDoorLoading: (loading: boolean) => void;
    scrollToBottom: () => void;
    setTextAreaFocus: (focus: boolean) => void;
    handleBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
    handleSubmit: (event: any) => void;
    isTextAreaFocused: boolean;
    handleChatInputFocus: () => void;
}

const renderSuggestion = (
    entry: SuggestionDataItem,
    highlightedDisplay: React.ReactNode,
    focused: boolean,
): JSX.Element => {
    return (
        <div className={focused ? 'focused' : ''} style={{ display: 'flex', alignItems: 'center' }}>
            <div className="suggestion-Icon p-2 me-2 rounded bg-white d-flex align-items-center justify-content-center">
                <i className={`ph ${entry.icon} lg`}></i>
            </div>
            <div>
                <div>{entry.id}</div>
                {entry.subtitle && <div className="suggestion-subtitle">{entry.subtitle}</div>}
            </div>
        </div>
    );
};

const ChatInput: React.FC<ChatInputProps> = ({
    value,
    isTextAreaFocused,
    setValue,
    handleGetAdFromEditor,
    setTwoWayDoorLoading,
    scrollToBottom,
    setTextAreaFocus,
    handleBlur,
    handleSubmit,
    handleChatInputFocus,
}) => {
    const mentionsInputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (isTextAreaFocused) {
            mentionsInputRef.current?.focus();
        }
    }, [isTextAreaFocused]);

    const commands: SuggestionDataItem[] = COMMANDS;

    const createMentionComponent = (trigger: any) => (
        <Mention
            trigger={trigger}
            displayTransform={(id, display) => `${display}`}
            className={style.mentions__mention}
            data={commands}
            renderSuggestion={renderSuggestion as any}
        />
    );

    const handleOnchatBarFocus = async () => {
        setTwoWayDoorLoading(true);
        handleChatInputFocus();
        await handleGetAdFromEditor();
        setTwoWayDoorLoading(false);
        scrollToBottom();
        setTextAreaFocus(true);
    };

    const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && e.ctrlKey) {
            e.preventDefault();
        } else if (e.key === 'Enter') {
            const textArea = e.target as HTMLTextAreaElement;
            const currentCursorPosition = textArea.selectionStart;
            const currentValue = textArea.value;

            e.preventDefault();
            if (!e.altKey && currentValue.trim() !== '') {
                handleSubmit(e);
            } else {
                const newValue =
                    currentValue.slice(0, currentCursorPosition) +
                    '\n' +
                    currentValue.slice(currentCursorPosition);
                setValue(newValue);
                setTimeout(() => {
                    textArea.selectionStart = currentCursorPosition + 1;
                    textArea.selectionEnd = currentCursorPosition + 1;
                }, 0);
            }
        }
    };

    return (
        <>
            <div className="textarea-wrapper position-relative w-100">
                <div className="position-absolute bottom-0 w-100">
                    <MentionsInput
                        value={value}
                        classNames={style}
                        onChange={(e) => setValue(e.target?.value)}
                        onFocus={async () => await handleOnchatBarFocus()}
                        onBlur={() => handleBlur}
                        onKeyDown={(e) => onKeyPress(e as React.KeyboardEvent<HTMLTextAreaElement>)}
                        placeholder={'Send a message'}
                        a11ySuggestionsListLabel={'Suggested mentions'}
                        allowSuggestionsAboveCursor={true}
                        inputRef={mentionsInputRef} // Add ref here
                    >
                        {COMPONENT_ARR.map((val) => createMentionComponent(val))}
                    </MentionsInput>
                </div>
            </div>

            <div className="apprentice_button d-flex align-items-center">
                <Button
                    onClick={handleSubmit}
                    variant="primary"
                    className="iconbtn"
                    disabled={value === ''}
                    style={{ width: '3.4375rem', height: '3.4375rem' }}
                >
                    <i className="ph ph-paper-plane-tilt lg"></i>
                </Button>
            </div>
        </>
    );
};

export default ChatInput;
