import React, { useRef } from 'react';
import useAutoResizeTextArea from './useAutosizeTextAres';
import { Form } from 'react-bootstrap';

const AutoResizeTextarea = ({
    value,
    id,
    className,
    onChange,
    onBlur,
    rows,
    readOnly,
    placeholder,
    required,
    formSubmitted,
}: {
    value: string;
    id: string;
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    rows: number;
    readOnly: boolean;
    placeholder?: string;
    required?: boolean;
    formSubmitted?: boolean;
}) => {
    const textAreaRef = useRef(null);
    // The useAutoResizeTextArea hook
    useAutoResizeTextArea(textAreaRef.current, value);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        onBlur(event);
    };

    return (
        <Form.Control
            as="textarea"
            ref={textAreaRef}
            id={id}
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            rows={value.split('\n').length}
            readOnly={readOnly}
            placeholder={placeholder}
            className={`p-3 ${formSubmitted && value.trim() === '' ? 'is-invalid' : ''}`}
            required={required}
        />
    );
};

export default AutoResizeTextarea;
