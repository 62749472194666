import { displayErrorToast } from '../utils/ToastUtils';

export const imagePreviewDataURLFunc = async (json: any, setLoading?: any) => {
    try {
        const req = await fetch(
            `https://api.polotno.com/api/render?KEY=${process.env.REACT_APP_POLOTNO_API_KEY}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    // polotno json from store.toJSON()
                    design: json,
                    // optional output format for export
                    outputFormat: 'url',
                    // optional export options for store.toDataURL() method
                    exportOptions: {},
                }),
            },
        );

        const { url } = await req.json();
        return url;
    } catch (error) {
        displayErrorToast('Server is busy, please try again later.');
        setLoading?.(false);
        console.log('error', error);
    }
};
