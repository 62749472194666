import React, { SetStateAction, Dispatch, useEffect, useState, useRef } from 'react';
import { Form, Image, Spinner } from 'react-bootstrap';
import { motion } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';
import { ImageType, productImageProps } from '../Interfaces';
import { useDeleteAssetMutation } from '../../../Editor/EditorRedux/editor.api';
import { displaySuccessToast } from '../../../../utils/ToastUtils';

interface ImagesFormProps {
    setVisionPhotographyData: Dispatch<SetStateAction<productImageProps>>;
    imageList: any[];
    setImageList: Dispatch<SetStateAction<any>>;
    fetchMoreData: () => void;
    previousAssets: any;
    show: boolean;
}

const ImagesForm: React.FC<ImagesFormProps> = ({
    imageList,
    fetchMoreData,
    setImageList,
    previousAssets,
    setVisionPhotographyData,
    show,
}) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [deletingIndex, setDeletingIndex] = useState(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [deleteAsset] = useDeleteAssetMutation();

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight - 572);
        };

        handleResize(); // Set initial height on component mount
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!show) return null;

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.type.startsWith('image/')) {
                setVisionPhotographyData({
                    isImageDataPresent: true,
                    imageData: {
                        imageType: ImageType.FILE,
                        data: file,
                        id: '',
                    },
                });
            } else {
                if (event.target && event.target.value) {
                    event.target.value = '';
                }
            }
        }
    };
    async function handleDeleteAsset(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();
        try {
            await deleteAsset(id).then(() => {
                const updatedImageList = imageList.filter((image: any) => image.id !== id);
                setImageList(updatedImageList);
                displaySuccessToast('Deleted Successfully');
            });
        } catch (error) {
            console.error('Error deleting asset:', error);
        } finally {
            setDeletingIndex(null);
        }
    }
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (event.dataTransfer.files.length > 0) {
            setVisionPhotographyData({
                imageData: {
                    imageType: ImageType.FILE,
                    data: event.dataTransfer.files[0],
                    id: '',
                },
                isImageDataPresent: true,
            });
        }
    };

    return (
        <>
            <h4 className="text-primary mb-3">Upload your Vision Image</h4>
            <Form.Group
                controlId="formFile"
                className="mb-3 form-group custom-file-inputs"
                style={{ height: 'auto' }}
            >
                <Form.Control
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                    className=""
                />

                <div
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                    className="custom-file-input"
                    draggable="true"
                >
                    <i className="ph ph-cloud-arrow-up text-black" style={{ fontSize: '48px' }}></i>
                    <p>Drag and drop to upload</p>
                    <span>or browse</span>
                    <ul style={{ fontSize: '0.75rem' }}>
                        <li>Max dimensions: 2048 x 2048</li>
                        <li>Supported file formats: JPG, PNG, WEBP</li>
                        <li>Max file size: 20MB</li>
                    </ul>
                </div>
            </Form.Group>

            <div className="position-relative text-center">
                <hr className="my-3" />
                <p className="position-absolute top-50 start-50 translate-middle bg-white px-2 text-muted">
                    or
                </p>
            </div>

            <h4 className="text-primary">Select from Previously Uploaded Images</h4>
            <InfiniteScroll
                dataLength={imageList?.length || 1}
                next={fetchMoreData}
                hasMore={previousAssets?.hasNext}
                loader={
                    <div
                        className="w-100 d-flex mb-4 justify-content-center align-item-center"
                        style={{ padding: '24px', height: '180px' }}
                    >
                        <Spinner
                            as="span"
                            className="mr-1 pe-none"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            style={{
                                marginTop: '40px',
                                width: '48px',
                                height: '48px',
                                color: '#967AA7',
                                background: 'transparent',
                            }}
                        />
                    </div>
                }
                height={windowHeight}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
                className=""
            >
                <Masonry
                    breakpointCols={3}
                    className="pixii-grid m-1"
                    columnClassName="pixii-grid_column"
                >
                    {imageList &&
                        imageList.map((image: any, index: any) => (
                            <div key={index}>
                                <motion.div
                                    key={index}
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    exit={{ scale: 0.8, opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    className="m-0"
                                    onClick={() => {
                                        setVisionPhotographyData({
                                            imageData: {
                                                imageType: ImageType.URL,
                                                data: image.assets,
                                                id: image.name,
                                            },
                                            isImageDataPresent: true,
                                        });
                                    }}
                                >
                                    {deletingIndex === image.id ? (
                                        <div>Loading...</div>
                                    ) : (
                                        <div className="p-2  image-delete">
                                            <Image
                                                src={image.assets}
                                                fluid
                                                rounded
                                                className="border border-grey"
                                                style={{ cursor: 'pointer' }}
                                            />

                                            <button
                                                className="iconbtn iconbtn-transparent"
                                                onClick={(event) =>
                                                    handleDeleteAsset(image.id, event)
                                                }
                                            >
                                                <i className="ph-bold ph-prohibit-inset lg"></i>
                                            </button>
                                        </div>
                                    )}
                                </motion.div>
                            </div>
                        ))}
                </Masonry>
            </InfiniteScroll>
        </>
    );
};

export default ImagesForm;
