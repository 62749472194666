import React, { useEffect, useState } from 'react';
import { Button, Spinner, Image, InputGroup, Dropdown, Form, Alert } from 'react-bootstrap';
import { MentionsInput, Mention } from 'react-mentions';
import PhotographyModal from '../../components/AppElements/PhotographyModal';
import { useProductPhotographyMutation } from '../CreateFlow/CreateFlowRedux/createFlow.api';
import { displayErrorToast } from '../../utils/ToastUtils';
import { setCreditExpired } from '../CreateFlow/CreateFlowRedux/createFlowSlice';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
interface ProductPromptAreaProps {
    imagePrompt: string;
    setImagePrompt: React.Dispatch<React.SetStateAction<string>>;
    handleSendData: () => void;
}

const ProductPromptArea = ({
    imagePrompt,
    setImagePrompt,
    handleSendData,
}: ProductPromptAreaProps) => {
    const [productPhotography] = useProductPhotographyMutation();
    const [photo, setPhoto] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [uploadedImage, setUploadedImage] = useState<boolean>(false);
    const [previousUploadedImage, setPreviousUploadedImage] = useState<string | null>(null);
    const [photographyShow, setPhotographyShow] = useState(false);
    const [isProductPhotographyLoading, setProductPhotographyLoading] = useState(false);

    const handleInputChange = (event: any) => {
        setImagePrompt(event.target.value);
    };

    const handleSubmitPhotoGraphy = async () => {
        const formData = new FormData();
        const cleanedString = imagePrompt.replace(/@\[(.*?)\]\((.*?)\)/g, '$1');
        formData.append('prompt', cleanedString);
        if (uploadedImage) {
            formData.append('image_file', photo as File);
        } else {
            formData.append('imageFile', previousUploadedImage as string);
        }
        try {
            setProductPhotographyLoading(true); // Set loading state to true
            const res: any = await productPhotography(formData);
            handleSendData();
        } catch (errorStatus) {
        } finally {
            setProductPhotographyLoading(false); // Set loading state back to false
        }
    };

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        setPhoto(null);
        setPreviousUploadedImage(null);
    };

    return (
        <div className="customBoxShadow">
            {/* <p style={{ fontSize: '0.85rem' }} className="fst-italic fw-semibold text-light mb-2">
                
            </p> */}
            <Alert variant="primary">
                {/* <Alert.Heading>Let's Get to Know Your Brand's Voice!</Alert.Heading> */}
                <p style={{ fontSize: '0.85rem', textAlign: 'justify' }}>
                    Place your product anywhere. Pixii matches the lighting, angle, and dimensions
                    of the original photo but changes the background.
                </p>
            </Alert>
            <Form className="position-relative">
                <Form.Group controlId="imageDescription">
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={imagePrompt}
                        onChange={handleInputChange}
                        placeholder="describe the background you want to place the product on. e.g. warm beach with blue waves and sand"
                        className="productTextarea"
                    />
                    <div
                        className="d-flex justify-content-between align-items-center position-absolute bottom-0 start-0 ms-2 p-2 pt-0"
                        style={{ backgroundColor: 'white', width: '96%', marginBottom: '0.05rem' }}
                    >
                        <InputGroup className="custom-dropdown">
                            {!previewImage && (
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip-2">
                                            Upload Image for Background replace
                                        </Tooltip>
                                    }
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <Button
                                            variant="white"
                                            onClick={() => setPhotographyShow(true)}
                                            className="iconbtn"
                                            {...triggerHandler}
                                            ref={ref}
                                        >
                                            <i className="pxi-image-plus lg"></i>
                                        </Button>
                                    )}
                                </OverlayTrigger>
                            )}
                            {previewImage && (
                                <>
                                    <div className="productImage">
                                        <div className="productImage-img">
                                            <img
                                                src={
                                                    uploadedImage ? previewImage : `${previewImage}`
                                                }
                                                alt="somephoto"
                                                loading="lazy"
                                            />
                                        </div>
                                        <button
                                            className="close"
                                            onClick={handleRemovePreviewImage}
                                        >
                                            <i className="pxi-cross"></i>
                                        </button>
                                    </div>
                                </>
                            )}
                            <PhotographyModal
                                photo={photo}
                                photoitem={setPhoto}
                                previewimage={previewImage}
                                setpreviewimage={setPreviewImage}
                                previousuploadedimage={previousUploadedImage}
                                setpreviousuploadedimage={setPreviousUploadedImage}
                                setuploadedimage={setUploadedImage}
                                show={photographyShow}
                                onHide={() => setPhotographyShow(false)}
                            />
                        </InputGroup>

                        <Button
                            variant="primary"
                            size="lg"
                            onClick={handleSubmitPhotoGraphy}
                            disabled={isProductPhotographyLoading}
                            className="iconbtn"
                        >
                            {isProductPhotographyLoading ? (
                                <Spinner
                                    animation="border"
                                    style={{ width: '24px', height: '24px' }}
                                />
                            ) : (
                                <i className="pxi-send lg"></i>
                            )}
                        </Button>
                    </div>
                </Form.Group>
            </Form>
        </div>
    );
};

export default ProductPromptArea;
