import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IEditFlowState } from './types';

const initialState: IEditFlowState = {
    editorPersistData: '',
    loadingState: false,
    project: { name: 'Untitled', id: null, json: '', apiCall: false },
    activeUserId: null,
    activeUserMail: '',
    activeUserSessionId: '',
    saveToCloud: false,
    projectDetails: [{ userId: null, projectName: 'Untitled', projectId: null, projectJson: '' }],
    projectJSONTemplate: null,
    apprenticeView: false,
    isNewProject: false,
    isBlankCanvasNewproject: false,
    createNewProject: '',
    projectCreationGoingOn: -1,
};

export const editFlowSlice = createSlice({
    name: 'editFlow',
    initialState,
    reducers: {
        callSaveApi: (state, { payload }) => {
            // console.log(payload, 'CALL SAVE API');
            state.saveToCloud = payload;
        },
        updateIsBlankCanvasNewproject: (state, { payload }) => {
            state.isBlankCanvasNewproject = payload;
        },
        updateProjectCreation: (state, { payload }) => {
            state.projectCreationGoingOn = payload;
        },
        updateLoadingState: (state, { payload }) => {
            state.loadingState = payload;
        },
        persistEditorData: (state, action) => {
            // localStorage.setItem('polotnoJSON', JSON.stringify(action.payload || null));
            // state.editorPersistData = action.payload || null;
            const MAX_LOCAL_STORAGE_SIZE = 10 * 1024 * 1024;
            const serializedData = JSON.stringify(action.payload || null);
            try {
                // Check if the serialized data size is within the available storage space
                if (serializedData.length <= MAX_LOCAL_STORAGE_SIZE) {
                    localStorage.setItem('polotnoJSON', serializedData);
                    state.editorPersistData = action.payload || null;
                } else {
                    localStorage.removeItem('plotnoJSON');
                    localStorage.setItem('polotnoJSON', serializedData);
                    state.editorPersistData = action.payload || null;
                }
            } catch (error) {
                console.error('Error while saving to local storage:', error);
            }
        },
        removePersistData: (state) => {
            localStorage.removeItem('polotnoJSON');
            state.editorPersistData = '';
        },
        persistProjectName: (state, action: PayloadAction<string>) => {
            state.project.name = action.payload;
        },
        persistProjectId: (state, action: PayloadAction<string | null>) => {
            state.project.id = action.payload;
        },
        saveProjectDetails: (
            state,
            action: PayloadAction<{
                userId: string;
                projectName: string;
                projectId: string;
                projectJson: object | string;
            }>,
        ) => {
            const { userId, projectName, projectId, projectJson } = action?.payload;
            // console.log(action?.payload, 'action');
            // Check if the project with the given userId already exists in the array
            const existingProjectIndex = state?.projectDetails?.findIndex(
                (project) => project?.userId === userId,
            );
            if (existingProjectIndex !== undefined && existingProjectIndex !== -1) {
                // If the project already exists, update its details
                state.projectDetails[existingProjectIndex] = {
                    userId,
                    projectName,
                    projectId,
                    projectJson,
                };
            } else {
                // If the project doesn't exist, add it to the array
                state?.projectDetails?.push({
                    userId,
                    projectName,
                    projectId,
                    projectJson,
                });
            }
        },
        saveProjectTemplate: (state, action) => {
            state.projectJSONTemplate = action.payload;
        },
        setActiveUserId: (state, action: PayloadAction<string>) => {
            state.activeUserId = action?.payload;
        },
        setActiveUserEmailId: (state, action: PayloadAction<string>) => {
            state.activeUserMail = action?.payload;
        },
        setActiveUserSessionId: (state, action: PayloadAction<string>) => {
            state.activeUserSessionId = action?.payload;
        },

        // The new reducer to update the project state
        updateProject: (
            state,
            action: PayloadAction<{
                name?: string;
                id?: string;
                json?: object | string;
                apiCall?: boolean;
            }>,
        ) => {
            const { name, id, json, apiCall } = action.payload;
            if (name !== undefined) {
                state.project.name = name;
            }
            if (id !== undefined) {
                state.project.id = id;
            }
            if (json !== undefined) {
                state.project.json = json;
            }
            if (apiCall !== undefined) {
                state.project.apiCall = apiCall;
            }
        },
        updateApprenticeViewState: (state, { payload }) => {
            state.apprenticeView = payload;
        },
        updateIsFreshStart: (state, { payload }) => {
            state.isNewProject = payload;
        },
        createNewProject: (state, { payload }) => {
            state.createNewProject = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    callSaveApi,
    updateLoadingState,
    persistEditorData,
    removePersistData,
    persistProjectName,
    persistProjectId,
    setActiveUserId,
    setActiveUserSessionId,
    setActiveUserEmailId,
    saveProjectDetails,
    updateProject,
    saveProjectTemplate,
    updateApprenticeViewState,
    updateIsFreshStart,
    updateIsBlankCanvasNewproject,
    createNewProject,
    updateProjectCreation,
} = editFlowSlice.actions;

export default editFlowSlice.reducer;
