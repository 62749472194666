import { useEffect, useState } from 'react';

const useClipboard = (): [boolean, (text: string) => void] => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (text: string): void => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setCopied(true);
            })
            .catch((error) => {
                console.error('Error copying text to clipboard:', error);
            });
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (copied) {
            timer = setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [copied]);

    return [copied, copyToClipboard];
};

export default useClipboard;
