import React, { useState, useEffect } from 'react';
import DesignElements from './DesignElements';
import Image from './Component/Image';
import Stepper from '../../../components/AppElements/Stepper';
import BrandVoice from './Component/BrandVoice';
import TargetCustomer from './Component/TargetCustomer';
import OnbrandPotion from './Component/OnbrandPotions';
import Format from './Component/Format';
import { useAppSelector } from '../../../commons/hooks/redux';
import { selectCreateFlow } from '../../CreateFlow/CreateFlowRedux/selector';
import BrandMagicScrapingURL from './Component/BrandMagicScrapingURL';
import { Tooltip, Container, OverlayTrigger } from 'react-bootstrap';
import {
    useGetBrandVoiceQuery,
    useSaveScrapingURLMutation,
} from '../BrandMagicRedux/brandMagic.api';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Memory from './Component/Memory';
const BrandMagic = ({ paddle }: any) => {
    const { onBoardedUser } = useAppSelector(selectCreateFlow);
    const { data, isLoading: isFetchingData, refetch } = useGetBrandVoiceQuery();
    const [saveScrapingURL, { isLoading: isSavingURL }] = useSaveScrapingURLMutation();
    const [clickedStep, setClickedStep] = useState<null | number>(null);
    const [savedURL, setURLSaved] = useState(false);
    const steps = ['Brand Analysis', 'Brand Voice', 'Design Elements', 'Memory'];
    const stepsForOnboardedUser = ['Brand Analysis', 'Brand Voice', 'Design Elements', 'Memory'];
    const stepsToBeUsed = !onBoardedUser ? steps : stepsForOnboardedUser;
    const [currentStep, setCurrentStep] = useState(0);
    const [completeStep, setCompleteState] = useState(false);
    const [error, setError] = useState(''); // State for error message
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const [brandVoice, setBrandVoice] = useState({
        brandName: '',
        keyProductService: '',
        keyMessages: '',
        tone: '',
        scrapedTextContent: '',
        anythingElse: '',
    });
    useEffect(() => {
        if (data) {
            setBrandVoice({
                brandName: data.brandName || '',
                keyProductService: data.keyProductService || '',
                keyMessages: data.keyMessages || '',
                tone: data.tone || '',
                scrapedTextContent: data.scrapedTextContent || '',
                anythingElse: data.anythingElse || '',
            });
        }
    }, [data]);
    // useEffect(() => {
    //     if (!isFetchingData) {
    //         // console.log('BrandVoice data:', data);
    //     }
    // }, [data, isFetchingData]);
    const handleNext = () => {
        setClickedStep(null);
        setCompleteState(true);
        const lenghtOfArray = (): number => {
            return !onBoardedUser ? stepsForm.length - 1 : stepsForOnboardedCustomers.length - 1;
        };
        if (currentStep < lenghtOfArray()) {
            setCurrentStep(currentStep + 1);
        }
    };
    // when back button is clicked
    const handlePrev = () => {
        setClickedStep(null);
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    const stepsForm = [
        <BrandMagicScrapingURL
            handleNext={handleNext}
            setURLSaved={setURLSaved}
            saveScrapingURL={saveScrapingURL}
            refetchBrandvoice={refetch}
            paddle={paddle}
            isSavingURL={isSavingURL}
            error={error}
            setError={setError}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            brandVoice={brandVoice}
        />,
        <BrandVoice
            handleNext={handleNext}
            setURLSaved={setURLSaved}
            handlePrev={handlePrev}
            error={error}
            successMessage={successMessage}
            brandVoice={brandVoice}
            setBrandVoice={setBrandVoice}
        />,
        <DesignElements handleNext={handleNext} handlePrev={handlePrev} paddle={paddle} />,
        <Memory
            handleNext={handleNext}
            handlePrev={handlePrev}
            brandVoice={brandVoice}
            setBrandVoice={setBrandVoice}
            isFetchingData={isFetchingData}
        />,
    ];
    const stepsForOnboardedCustomers = [
        <BrandMagicScrapingURL
            handleNext={handleNext}
            setURLSaved={setURLSaved}
            saveScrapingURL={saveScrapingURL}
            refetchBrandvoice={refetch}
            paddle={paddle}
            isSavingURL={isSavingURL}
            error={error}
            setError={setError}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            brandVoice={data}
        />,
        <BrandVoice
            handleNext={handleNext}
            setURLSaved={setURLSaved}
            handlePrev={handlePrev}
            error={error}
            successMessage={successMessage}
            brandVoice={brandVoice}
            setBrandVoice={setBrandVoice}
        />,
        <DesignElements handleNext={handleNext} handlePrev={handlePrev} paddle={paddle} />,
        <Memory
            handleNext={handleNext}
            handlePrev={handlePrev}
            brandVoice={brandVoice}
            setBrandVoice={setBrandVoice}
            isFetchingData={isFetchingData}
        />,
    ];
    const isStepActive = (index: number) => {
        if ((onBoardedUser && currentStep === index) || (!onBoardedUser && currentStep === index)) {
            return 'active';
        }
        return '';
    };

    const handleClick = (index: number) => {
        setClickedStep(index);
    };
    const isMobile = window.innerWidth <= 768;
    return (
        <Container>
            <div className="brandMagic position-relative">
                {onBoardedUser ? (
                    <div className=" d-flex justify-content-center align-items-end stepper brandMagic_stepper mt-4 mb-4">
                        {stepsToBeUsed?.map((name, index) => (
                            <div
                                key={name}
                                className={`step ${isStepActive(index)}`}
                                onClick={() => handleClick(index)}
                            >
                                <p className="m-0 stepName">{name}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="d-flex justify-content-center align-items-center stepper onboard_stepper mt-4 mb-4">
                        {stepsToBeUsed?.map((name, index) => (
                            <div
                                key={name}
                                className={`d-flex align-items-center me-3 ${
                                    currentStep === index ? 'activeStep' : ''
                                }`}
                                onClick={() => handleClick(index)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="onboard_step d-flex justify-content-center align-items-center fw-bold p-3 rounded-circle me-2">
                                    {index + 1}
                                </div>
                                <p className="stepName">{name}</p>
                                {index !== stepsToBeUsed.length - 1 && (
                                    <div className="stepperLine me-2 ms-2"></div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <Stepper
                    steps={onBoardedUser ? stepsForOnboardedCustomers : stepsForm}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    clickedStep={clickedStep}
                />
                {onBoardedUser ? (
                    <div
                        className="custom_nav-profile position-fixed start-0 m-3"
                        style={{ top: isMobile ? '100px' : '56px', zIndex: 1080 }}
                    >
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Back to Apprentice</Tooltip>}
                        >
                            <motion.button
                                onClick={() => navigate('/')}
                                className="btn btn-primary iconbtn rounded-circle "
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                transition={{ duration: 0.2 }}
                            >
                                <i className="ph ph-arrow-up-left lg"></i>
                            </motion.button>
                        </OverlayTrigger>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </Container>
    );
};

export default BrandMagic;
