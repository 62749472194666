import React from 'react';
import enhance from '../assets/images/stylePreset/enhance.png';
import threeD from '../assets/images/stylePreset/3d-model.png';
import analog from '../assets/images/stylePreset/analog-film.png';
import anime from '../assets/images/stylePreset/anime.png';
import cinematic from '../assets/images/stylePreset/cinematic.png';
import comicBook from '../assets/images/stylePreset/comic-book.png';
import digitalArt from '../assets/images/stylePreset/digital-art.png';
import fantasyArt from '../assets/images/stylePreset/fantasy-art.png';
import isometric from '../assets/images/stylePreset/isometric.png';
import lineArt from '../assets/images/stylePreset/line-art.png';
import lowPoly from '../assets/images/stylePreset/low-poly.png';
import modelingCompound from '../assets/images/stylePreset/modeling-compound.png';
import neonPunk from '../assets/images/stylePreset/neon-punk.png';
import origami from '../assets/images/stylePreset/origami.png';
import photographic from '../assets/images/stylePreset/photographic.png';
import pixelArt from '../assets/images/stylePreset/pixel-art.png';
import noStyle from '../assets/images/prohibit.png';

export const dropdown = [];
export const SKU_DATA: any = [
    { id: 'sku1', display: '#SKU1' },
    { id: 'sku2', display: '#SKU2' },
    { id: 'sku3', display: '#SKU3' },
];
export const SEGMENT_DATA: any = [
    { id: 'Soloprenuer', display: '@Soloprenuer ' },
    { id: 'Small Business', display: '@Small Business ' },
    { id: 'Enterprise', display: '@Enterprise ' },
];
export const TEST_DATA: any = [
    { id: 'Headline', display: '/Headline ' },
    { id: 'Caption', display: '/Caption ' },
];
export enum ImageTone {
    Creative = 'CREATIVE',
    Balanced = 'BALANCED',
    Precise = 'PRECISE',
}

export const DIMENSIONS = [
    'Instagram Post Square Ad',
    'Instagram Post Vertical Ad',
    'Instagram Post Landscape Ad',
    'Instagram Story Ad',
    'Facebook Post Ad',
    'Facebook Story Ad',
    'Facebook Link Ad',
    'Linkedin Image Ad',
    'Linkedin Carousel Ad',
];
export enum DefaultPotions {
    NO_STYLE = 'No-Style',
    '3D_MODEL' = '3d-model',
    ANALOG_FILM = 'analog-film',
    ANIME = 'anime',
    CINEMATIC = 'cinematic',
    COMIC_BOOK = 'comic-book',
    DIGITAL_ART = 'digital-art',
    ENHANCE = 'enhance',
    FANTASY_ART = 'fantasy-art',
    ISOMETRIC = 'isometric',
    LINE_ART = 'line-art',
    LOW_POLY = 'low-poly',
    MODELING_COMPOUND = 'modeling-compound',
    NEON_PUNK = 'neon-punk',
    ORIGAMI = 'origami',
    PHOTOGRAPHIC = 'photographic',
    PIXEL_ART = 'pixel-art',
    TILE_TEXTURE = 'tile-texture',
}

export const imagePaths: any = {
    '3d-model': threeD,
    'analog-film': analog,
    anime: anime,
    cinematic: cinematic,
    'comic-book': comicBook,
    'digital-art': digitalArt,
    enhance: enhance,
    'fantasy-art': fantasyArt,
    isometric: isometric,
    'line-art': lineArt,
    'low-poly': lowPoly,
    'modeling-compound': modelingCompound,
    'neon-punk': neonPunk,
    origami: origami,
    photographic: photographic,
    'pixel-art': pixelArt,
    'tile-texture': enhance,
    NO_STYLE: noStyle,
};

export const allowedExtensionsPhotography = ['jpeg', 'jpg', 'png', 'webp'];
export const allowedExtensionsProject = ['json'];
export const maxFileSize = 20 * 1024 * 1024; // 20MB
export const maxResolution = 2048;

export const allowedExtensionsCustomFonts = ['ttf', 'otf', 'woff', 'woff2'];
