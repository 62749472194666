// FAQPage.tsx

import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { Accordion, Tooltip, Container, OverlayTrigger } from 'react-bootstrap';
const FAQPage: React.FC = () => {
    const isMobile = window.innerWidth <= 768;
    const navigate = useNavigate();
    return (
        <Container className="mt-2">
            <div
                className="custom_nav-profile position-fixed start-0 m-3"
                style={{ top: isMobile ? '100px' : '56px', zIndex: 1080 }}
            >
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Back to Apprentice</Tooltip>}
                >
                    <motion.button
                        onClick={() => navigate('/')}
                        className="btn btn-primary iconbtn rounded-circle "
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ duration: 0.2 }}
                    >
                        <i className="ph ph-arrow-up-left lg"></i>
                    </motion.button>
                </OverlayTrigger>
            </div>
            <div className="faq-container">
                <h1 className="text-center mb-4">Frequently Asked Questions</h1>
                <Accordion defaultActiveKey="0" flush>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="fw-bold">
                                How do i use Pixii?
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex flex-column">
                                    <p>Feel free to watch out video tutorial:</p>
                                    <div className="embed-responsive embed-responsive-16by9 d-flex justify-content-center align-items-center">
                                        <iframe
                                            width="720"
                                            height="420"
                                            src="https://www.youtube.com/embed/Zy8gcS27PMc?controls=0&modestbranding=1&rel=0&autoplay=0&disablekb=1"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            style={{ objectFit: 'contain' }}
                                            className="tutorialVideo"
                                        ></iframe>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Accordion.Item eventKey="1">
                            <Accordion.Header className="fw-bold">
                                Apprentice is giving me blank messages as outputs instead of
                                creatives.
                            </Accordion.Header>
                            <Accordion.Body>
                                Please double check your{' '}
                                <Link to="/brandProfile" className="accordion-link">
                                    Brand Profile
                                </Link>
                                (located on the Navigation bar on top) to make sure that you
                                selected a font and specified colors on the “Design Elements” tab.
                                If the error persists after populating those fields and clicking
                                “Save,” please email{' '}
                                <a href="mailto:monte@pixii.ai" className="accordion-link">
                                    monte@pixii.ai
                                </a>{' '}
                                with a screenshot of the error.
                            </Accordion.Body>
                        </Accordion.Item>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                    >
                        <Accordion.Item eventKey="2">
                            <Accordion.Header className="fw-bold">
                                Apprentice is not showing my latest messages and assets.
                            </Accordion.Header>
                            <Accordion.Body>
                                Please try refreshing your page. If the error persists, feel free to
                                email{' '}
                                <a href="mailto:monte@pixii.ai" className="accordion-link">
                                    monte@pixii.ai
                                </a>
                            </Accordion.Body>
                        </Accordion.Item>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <Accordion.Item eventKey="3">
                            <Accordion.Header className="fw-bold">
                                Where can I upload my own fonts?
                            </Accordion.Header>
                            <Accordion.Body>
                                You can do this in{' '}
                                <Link to="/brandProfile" className="accordion-link">
                                    Brand Profile
                                </Link>{' '}
                                &gt; Design Elements &gt; Fonts. From the dropdown menu, please
                                select{' '}
                                <span className="fw-semibold text-primary">Upload Your Font.</span>
                            </Accordion.Body>
                        </Accordion.Item>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    >
                        <Accordion.Item eventKey="4">
                            <Accordion.Header className="fw-bold">
                                I got an error saying that Apprentice is having trouble showing me
                                the preview of the asset.
                            </Accordion.Header>
                            <Accordion.Body>
                                You can still see your asset in our{' '}
                                <Link to="/editor" className="accordion-link">
                                    Editor.
                                </Link>{' '}
                                Please navigate to the three dots on the top right of Apprentice’s
                                message and click “Open in Editor.”
                            </Accordion.Body>
                        </Accordion.Item>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <Accordion.Item eventKey="5">
                            <Accordion.Header className="fw-bold">
                                Where can I find the “Product Photo” or “Background Replace” feature
                                and how do I use it?
                            </Accordion.Header>
                            <Accordion.Body>
                                You can find this in our{' '}
                                <Link to="/editor" className="accordion-link">
                                    Editor.
                                </Link>{' '}
                                Navigate to the “Generate” tab in the Editor. From there, you can
                                describe your desired background in the text box and attach an image
                                using the icon on the bottom left of the text box.
                            </Accordion.Body>
                        </Accordion.Item>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                    >
                        <Accordion.Item eventKey="6">
                            <Accordion.Header className="fw-bold">
                                Where can I provide feedback on the app?
                            </Accordion.Header>
                            <Accordion.Body>
                                Feel free to email{' '}
                                <a href="mailto:monte@pixii.ai" className="accordion-link">
                                    monte@pixii.ai
                                </a>{' '}
                                with any comments or suggestions about the app.
                            </Accordion.Body>
                        </Accordion.Item>
                    </motion.div>
                </Accordion>
            </div>
        </Container>
    );
};

export default FAQPage;
