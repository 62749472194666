/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Dropdown, Image, InputGroup, Spinner, Tabs, Tab, Button } from 'react-bootstrap';
import {
    useCreateFlowClipdropMutation,
    useCreateFlowImageDimensionQuery,
    useCreateFlowImageMutation,
    useCreateGenerateImageMutation,
    useProductPhotographyMutation,
} from '../CreateFlow/CreateFlowRedux/createFlow.api';
import { ImageTone, DefaultPotions, imagePaths } from '../../constants/createFlow';
import { MentionsInput, Mention } from 'react-mentions';
import PhotographyModal from '../../components/AppElements/PhotographyModal';
import { useAppDispatch } from '../../commons/hooks/redux';
import { setCreditExpired } from '../CreateFlow/CreateFlowRedux/createFlowSlice';
import mentionsInputStyles from '../../assets/styles/mentionStyle/mentionsInputStyles.';
import mentionStyles from '../../assets/styles/mentionStyle/mentionStyles';
import {
    useGetDefaultPotionsQuery,
    useGetUserImageFavouritesQuery,
} from '../BrandMagic/BrandMagicRedux/brandMagic.api';
import { displayErrorToast } from '../../utils/ToastUtils';
import potionIcon from '../../assets/images/potion.svg';
import { Model, PrefillData } from './ImageContainer';
import { APP_AMAZON_URL_PRODUCTION } from '../../constants/app';

import classNames from './mention.module.css';
interface ImagePromptAreaProps {
    handleSendData: () => void;
    setActivePotion: React.Dispatch<React.SetStateAction<string | null | undefined>>;
    imagePrompt: string;
    setImagePrompt: React.Dispatch<React.SetStateAction<string>>;
    setSelectedUserFavourite: React.Dispatch<React.SetStateAction<string | null | undefined>>;
    activePotion: string | null | undefined;
    modelType: Model;
    selectedUserFavourite: string | null | undefined;
    handleUserFavouritesOrReuseSelect: (data: PrefillData, resuse?: boolean) => void;
    setModelType: React.Dispatch<React.SetStateAction<Model>>;
}

const ImagePromptArea = ({
    handleSendData,
    setActivePotion,
    imagePrompt,
    setImagePrompt,
    setSelectedUserFavourite,
    activePotion,
    selectedUserFavourite,
    handleUserFavouritesOrReuseSelect,
    modelType,
    setModelType,
}: ImagePromptAreaProps) => {
    const [productPhotography] = useProductPhotographyMutation();
    const [createImage, { isLoading }] = useCreateGenerateImageMutation();
    const [isProductPhotographyLoading, setProductPhotographyLoading] = useState(false);

    const [photo, setPhoto] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [uploadedImage, setUploadedImage] = useState<boolean>(false);
    const [previousUploadedImage, setPreviousUploadedImage] = useState<string | null>(null);

    // const adSizeArray = data ? Object.values(data) : [];

    const [imageTone, setImageTone] = useState<ImageTone | null>(ImageTone.Creative);
    // const [dimensions, setDimensions] = useState("Instagram Square");
    const [imageStyle, setImageStyle] = useState('NO_STYLE');
    const [photographyShow, setphotographyShow] = useState(false);
    const [potions, setPotions] = useState<any>([]);

    const { data: userFavouritesData } = useGetUserImageFavouritesQuery();
    const { data: potionsData } = useGetDefaultPotionsQuery();

    const dispatch = useAppDispatch();

    const handleInputChange = (event: any, h: any, e: any, selectedValues: any) => {
        setSelectedUserFavourite(null);
        const value = event.target.value;
        if (imageStyle !== 'NO_STYLE' && selectedValues?.length !== 0) {
            return displayErrorToast(
                'Pixii potion is already slected. Only one potion can be selected at a time',
            );
        }
        if (selectedValues.length > 1) {
            return displayErrorToast('Only one potion can be selected at a time');
        }
        if (selectedValues.length === 0) {
            setActivePotion(null);
        } else if (selectedValues.length === 1) {
            const selectedId = selectedValues[0].id;

            const potionName = potions.find((item: any) => {
                return item.id === selectedId;
            })?.title;
            if (potionName) {
                setActivePotion(potionName);
            }
        }
        setImagePrompt(value);
    };

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        setPhoto(null);
        setPreviousUploadedImage(null);
    };

    const handleSubmit = async () => {
        // const regexForSku = /[^(]+(?=\))/g;
        const cleanedString = imagePrompt.replace(/@\[(.*?)\]\((.*?)\)/g, '$1');
        // const extractedPotions = [];
        // const regex = /[@~](\w+)(?:\s|$)/g;
        // let match;

        // while ((match = regex.exec(cleanedString)) !== null) {
        //     if (match[0].startsWith('~')) {
        //         extractedPotions.push(match[1]);
        //     }
        // }

        const payload = {
            text_prompts: cleanedString,
            imageTone: imageTone,
            pixiiPotion: activePotion,
            model: modelType,
        };

        try {
            const res = await createImage(payload).unwrap();
            handleSendData();
        } catch (errorStatus) {
            if (errorStatus === 402) {
                dispatch(setCreditExpired(true));
            }
        }
    };

    const handleSubmitPhotoGraphy = async () => {
        const formData = new FormData();
        const cleanedString = imagePrompt.replace(/@\[(.*?)\]\((.*?)\)/g, '$1');
        formData.append('prompt', cleanedString);
        if (uploadedImage) {
            formData.append('image_file', photo as File);
        } else {
            formData.append('imageFile', previousUploadedImage as string);
        }
        try {
            setProductPhotographyLoading(true); // Set loading state to true
            const res: any = await productPhotography(formData);
            handleSendData();
            if (res.error === 402) {
                dispatch(setCreditExpired(true));
            }
        } catch (errorStatus) {
            if (errorStatus === 402) {
                dispatch(setCreditExpired(true));
            }
        } finally {
            setProductPhotographyLoading(false); // Set loading state back to false
        }
    };

    const handlePotionSelectFromDropdown = (value: string, title: string): void => {
        if (title === activePotion) return;

        if (activePotion) {
            return displayErrorToast('Only one potion can be selected at a time');
        }
        setSelectedUserFavourite(null);
        setActivePotion(title);
        setImagePrompt((prev) => (prev.endsWith(' ') ? prev + value : prev + ' ' + value));
    };

    useEffect(() => {
        if (potionsData) {
            if (Array.isArray(potionsData)) {
                const transformedPotions: any = [];
                potionsData.map((item: any) => {
                    item.potions.forEach((potion: string, index: number) => {
                        transformedPotions.push({
                            id: index + item.category,
                            display: `~${potion} `,
                            title: potion,
                            category: item.category,
                        });
                    });
                });
                setPotions(transformedPotions);
            }
        }
    }, [potionsData]);
    const isMobile = window.innerWidth <= 768;
    const dropdownWidth = isMobile ? 20 : 30;
    return (
        <div className="userPrompt_input customBoxShadow">
            <MentionsInput
                className="mentions"
                classNames={classNames}
                value={imagePrompt}
                onChange={(e, d, h, f) => handleInputChange(e, d, h, f)}
                placeholder="~potrait of a girl"
            >
                <Mention
                    trigger="#"
                    data={[{ id: 'a' }]}
                    className={classNames.mentions__mention}
                />
                <Mention
                    trigger="~"
                    data={potions || []}
                    className={classNames.mentions__mention}
                />
            </MentionsInput>
            <div className=" userPrompt_options d-flex justify-content-between align-item-center">
                <InputGroup className="custom-dropdown">
                    <Dropdown autoClose="outside" className="me-2">
                        <Dropdown.Toggle
                            variant="white"
                            className="dropdown__toggle iconbtn"
                            id="dropdown-autoclose-outside"
                        >
                            <i
                                className={
                                    modelType === 'QUALITY FAST'
                                        ? 'ph ph-shooting-star lg'
                                        : modelType === 'QUALITY SLOW'
                                        ? 'ph ph-sparkle lg'
                                        : 'ph ph-star-four lg '
                                }
                            ></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown__toggle--menu"
                            style={{ width: '180px' }}
                        >
                            {/* CLIPDROP */}
                            <Dropdown.Item
                                onClick={() => {
                                    setModelType('QUALITY FAST');
                                }}
                                className={modelType === 'QUALITY FAST' ? 'active' : ''}
                            >
                                <i className="ph ph-shooting-star lg"></i>Fast (1)
                            </Dropdown.Item>
                            {/* SDXL 1.0 */}
                            <Dropdown.Item
                                onClick={() => {
                                    setModelType('QUALITY SLOW');
                                }}
                                className={modelType === 'QUALITY SLOW' ? 'active' : ''}
                            >
                                <i className="ph ph-sparkle lg"></i>Slow (4)
                            </Dropdown.Item>
                            {/* SDXL 2.1 beta*/}
                            {/* <Dropdown.Item
                                onClick={() => {
                                    setModelType('VARIETY');
                                }}
                                className={modelType === 'VARIETY' ? 'active' : ''}
                            >
                                <i className="ph ph-star-four lg"></i> Variety (fast) 4
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* --------------------- new potions ---------------------------*/}

                    <Dropdown autoClose="outside" className="me-2">
                        <Dropdown.Toggle
                            variant="white"
                            className="dropdown__toggle  iconbtn"
                            id="dropdown-autoclose-outside"
                        >
                            <i className="pxi-brush lg"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown__toggle--menu"
                            style={{ width: `${dropdownWidth}rem` }}
                        >
                            <div className="potions">
                                <div className="defaultPotions">
                                    <div className="defaultPotion mb-3">
                                        <Dropdown.Item
                                            className={activePotion ? '' : 'active'}
                                            onClick={() => setActivePotion(null)}
                                        >
                                            <Image
                                                src={imagePaths.NO_STYLE}
                                                thumbnail
                                                width="30"
                                                height="30"
                                            />{' '}
                                            No style
                                        </Dropdown.Item>
                                    </div>
                                    {potionsData?.map((item: any, index: number) => {
                                        return (
                                            <div className="defaultPotion mb-3">
                                                <h4 className="text-dark fw-semibold" key={index}>
                                                    {item?.category}
                                                </h4>
                                                <div className="default-options">
                                                    {item.potions.map(
                                                        (potion: string, potionIndex: number) => {
                                                            const imagePath: any =
                                                                imagePaths[potion] || potionIcon;
                                                            return (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        handlePotionSelectFromDropdown(
                                                                            `@[~${potion} ](${potionIndex})`,
                                                                            potion,
                                                                        )
                                                                    }
                                                                    key={potionIndex}
                                                                    className={
                                                                        activePotion === potion
                                                                            ? 'active'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <Image
                                                                        src={imagePath}
                                                                        thumbnail
                                                                        width="30"
                                                                        height="30"
                                                                    />{' '}
                                                                    {potion}
                                                                </Dropdown.Item>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle
                            variant="white"
                            className="dropdown__toggle  iconbtn"
                            id="dropdown-autoclose-outside"
                        >
                            <i className="ph ph-bookmark-simple lg"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown__toggle--menu ">
                            <div className="potions">
                                <div className="pixiiPotions">
                                    {userFavouritesData && userFavouritesData.length !== 0 ? (
                                        userFavouritesData.map((item: any, index: number) => {
                                            return (
                                                <div
                                                    className="pixiiPotion"
                                                    key={index}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        const {
                                                            modelType,
                                                            pixiiPotion,
                                                            potionEffect,
                                                            potionTitle,
                                                        } = item;
                                                        handleUserFavouritesOrReuseSelect({
                                                            modelType,
                                                            pixiiPotion,
                                                            prompt: potionEffect,
                                                            potionTitle,
                                                        });
                                                    }}
                                                >
                                                    <Dropdown.Item
                                                        className={
                                                            selectedUserFavourite ===
                                                            item.potionTitle
                                                                ? 'active'
                                                                : ''
                                                        }
                                                    >
                                                        <Image
                                                            src={potionIcon}
                                                            thumbnail
                                                            width="30"
                                                            height="30"
                                                        />
                                                        <p>{item?.potionTitle}</p>
                                                    </Dropdown.Item>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <Dropdown.Item disabled>No Favourites Added</Dropdown.Item>
                                    )}
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                    {previewImage && (
                        <>
                            <div className="productImage">
                                <div className="productImage-img">
                                    <img
                                        src={uploadedImage ? previewImage : `${previewImage}`}
                                        alt="somephoto"
                                        loading="lazy"
                                    />
                                </div>
                                <button className="close" onClick={handleRemovePreviewImage}>
                                    <i className="pxi-cross"></i>
                                </button>
                            </div>
                        </>
                    )}
                    {/* Add Image Button */}
                    {/* {!previewImage && (
                        <Button
                            variant="white"
                            onClick={() => setphotographyShow(true)}
                            className="iconbtn"
                        >
                            <i className="pxi-image-plus lg"></i>
                        </Button>
                    )}

                    <PhotographyModal
                        photo={photo}
                        photoitem={setPhoto}
                        previewimage={previewImage}
                        setpreviewimage={setPreviewImage}
                        previousuploadedimage={previousUploadedImage}
                        setpreviousuploadedimage={setPreviousUploadedImage}
                        setuploadedimage={setUploadedImage}
                        show={photographyShow}
                        onHide={() => setphotographyShow(false)}
                    /> */}
                </InputGroup>

                <Button
                    className="iconbtn"
                    variant="primary"
                    size="lg"
                    onClick={
                        photo || previousUploadedImage ? handleSubmitPhotoGraphy : handleSubmit
                    }
                    disabled={isLoading || isProductPhotographyLoading}
                >
                    {isLoading || isProductPhotographyLoading ? (
                        <Spinner animation="border" style={{ width: '24px', height: '24px' }} />
                    ) : (
                        <i className="pxi-send lg"></i>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default ImagePromptArea;
