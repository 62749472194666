import { Suspense, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import './assets/styles/sass/main.scss';
import { Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import OverwhelmedModal from './pages/design/components/OverwhelmedModal';
import { useAppDispatch, useAppSelector } from './commons/hooks/redux';
import { setCreditExpired } from './pages/CreateFlow/CreateFlowRedux/createFlowSlice';
import PixiRoutes from './Routes/PixiRoutes';
import { selectCreateFlow } from './pages/CreateFlow/CreateFlowRedux/selector';
import { useLazyGetFlagForOnboardedUserQuery } from './pages/CreateFlow/CreateFlowRedux/createFlow.api';
import { setUserSubscriptionStats } from './pages/PaddlePayWall/PaddlePayWallRedux/paddlePayWallSlice';
import { setActiveUserEmailId } from './pages/Editor/EditorRedux/editorSlice';
import { useSelector } from 'react-redux';
import { displayErrorToast } from './utils/ToastUtils';

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const { onBoardedUser } = useAppSelector(selectCreateFlow);
    const createFlowState = useAppSelector((state) => state.createFlow);
    const subscriptonStatus = useSelector((state: any) => state.paddleFlow.subscribedUser);
    const dispatch = useAppDispatch();
    const [getFlagForOnboardedUser] = useLazyGetFlagForOnboardedUserQuery();
    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await getFlagForOnboardedUser();
                dispatch(setUserSubscriptionStats(data?.subscribedUser));
                dispatch(setActiveUserEmailId(data.email));
            } catch (error: any) {
                if (error && error?.status === 500) {
                    //Error 422 Invalid Data
                    displayErrorToast('Server Busy Please Try Again Later');
                } else {
                    displayErrorToast(error?.data?.message);
                }
            }
        }
        if (!subscriptonStatus) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                if (location.pathname === '/login') navigate('/');
            })
            .catch((e) => {
                navigate('/login', { replace: true }); // currently there is an issue with Authenticator.Provider in amplify package. Update it once the package is updated by the team
            });
    }, []);
    const checkUserAuthStatus = async () => {
        try {
            await Auth.currentAuthenticatedUser();
        } catch (error) {
            (location.pathname === '/editor' || location.pathname === '/') &&
                navigate('/login', { replace: true });
        }
    };
    useEffect(() => {
        checkUserAuthStatus();
        !onBoardedUser &&
            (location.pathname === '/' || location.pathname === '/editor') &&
            navigate('start'); //if user is not onboarded navigate to the start
    }, [location.pathname]);

    return (
        <>
            <OverwhelmedModal
                show={createFlowState.creditExpired}
                onHide={() => dispatch(setCreditExpired(false))}
            />
            <ToastContainer autoClose={3000} hideProgressBar />
            <Suspense fallback={<>Loading...</>}>
                <div className="App">
                    <PixiRoutes />
                </div>
            </Suspense>
        </>
    );
}

export default App;
