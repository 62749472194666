import React, { useState, useRef, useEffect } from 'react';
import { Form, Modal, Image, Button, Alert } from 'react-bootstrap';
import { useGetProductPhotographyImagesQuery } from '../../pages/CreateFlow/CreateFlowRedux/createFlow.api';
import {
    allowedExtensionsPhotography,
    maxFileSize,
    maxResolution,
} from '../../constants/createFlow';
import config from '../../config';
import { displayErrorToast } from '../../utils/ToastUtils';
interface PhotographyModalProps {
    photo: File | null;
    photoitem: (file: File | null) => void;
    previewimage: string | null;
    setpreviewimage: React.Dispatch<React.SetStateAction<string | null>>;
    previousuploadedimage: string | null;
    setpreviousuploadedimage: React.Dispatch<React.SetStateAction<string | null>>;
    setuploadedimage: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    onHide: () => void;
}
const PhotographyModal: React.FC<PhotographyModalProps> = (props) => {
    const { data } = useGetProductPhotographyImagesQuery();
    const [productImages, setProductImages] = useState<{ id: number; inputImage: string }[]>([]);
    const [recentImage, setRecentImage] = useState<string | null>(null);
    const [error, setError] = useState('');

    const previewFile = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setRecentImage(reader.result as string);
        };
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const fileList = fileInput.files;
        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const fileSize = file.size;

            const img = document.createElement('img');
            img.src = URL.createObjectURL(file);
            // Check file extension
            if (!allowedExtensionsPhotography.includes(fileExtension as string)) {
                setError('Only JPEG, JPG, PNG, and WebP files are allowed.');
                return;
            }
            // Check file size
            if (fileSize > maxFileSize) {
                setError('File size exceeds the limit of 20MB.');
                return;
            }
            // Check file resolution
            img.onload = function () {
                if (img.width > maxResolution || img.height > maxResolution) {
                    setError('File resolution exceeds the limit of 2048 x 2048.');
                    return;
                }
                // File is valid, set it and clear error
                props.photoitem(file);
                previewFile(file);
                setError('');
            };
        }
    };

    const renderPreview = () => {
        if (props.photo && props.photo.type.startsWith('image/') && recentImage) {
            // return <img src={previewImage} alt="Preview" style={{ maxWidth: "100%" }} />;
            return (
                <div className="uploadedImage">
                    <Image src={recentImage as string} />
                </div>
            );
        } else {
            return null;
        }
    };
    const handleImageClick = (inputImage: string) => {
        props.setuploadedimage(false);
        props.setpreviewimage(inputImage);
        props.setpreviousuploadedimage(inputImage);
    };
    const handleUploadedImageClick = (inputImage: string) => {
        props.setuploadedimage(true);
        props.setpreviewimage(inputImage);
    };

    useEffect(() => {
        if (data) {
            const newData = data.slice(0, 3);
            setProductImages(newData);
        }
    }, [data]);

    return (
        <form
            onKeyDown={(e) => {
                e.preventDefault();
                if (!props.previewimage) displayErrorToast('Image not selected!');
                else props.onHide();
            }}
        >
            <Modal
                {...props}
                // dialogClassName="modal-100w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="fw-semibold fs-6 text-dark"
                    >
                        Select or upload your product image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#fcf6ff' }}>
                    <Alert variant="primary">
                        {/* <Alert.Heading>Let's Get to Know Your Brand's Voice!</Alert.Heading> */}
                        <p style={{ fontSize: '0.85rem' }} className="d-flex align-items-center">
                            <i className="ph ph-info lg me-2"></i> Use hi-res photos for best
                            results.
                        </p>
                    </Alert>
                    <Form.Group className="form-group">
                        <label htmlFor="fileInput" className="custom-file-inputs">
                            <div className="custom-file-input">
                                {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                            >
                                <g clipPath="url(#clip0_1888_37896)">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.23594 13.1442C7.23594 7.9895 11.39 3.82031 16.5026 3.82031C20.8552 3.82031 24.5141 6.84203 25.5049 10.923C25.5821 11.2409 25.8099 11.5009 26.1148 11.6193C28.4857 12.5399 30.1693 14.8557 30.1693 17.5687C30.1693 19.6557 29.1739 21.5069 27.6332 22.6706C27.1925 23.0035 27.1051 23.6306 27.438 24.0713C27.7708 24.512 28.3979 24.5994 28.8386 24.2665C30.8603 22.7395 32.1693 20.3068 32.1693 17.5687C32.1693 14.1934 30.1818 11.2834 27.3169 9.95814C25.9483 5.25876 21.6286 1.82031 16.5026 1.82031C10.4574 1.82031 5.53225 6.60238 5.24882 12.598C2.66627 13.5911 0.835938 16.1037 0.835938 19.0435C0.835938 21.5958 2.21612 23.8254 4.26646 25.018C4.74385 25.2957 5.35597 25.1338 5.63367 24.6565C5.91136 24.1791 5.74948 23.5669 5.27208 23.2892C3.81532 22.4419 2.83594 20.8584 2.83594 19.0435C2.83594 16.7576 4.39062 14.8399 6.48784 14.2978C6.93554 14.182 7.24551 13.7744 7.23741 13.3121C7.23643 13.2563 7.23594 13.2003 7.23594 13.1442ZM21.8764 21.4465L17.2097 16.7799C16.8192 16.3893 16.186 16.3893 15.7955 16.7799L11.1288 21.4465C10.7383 21.8371 10.7383 22.4702 11.1288 22.8608C11.5194 23.2513 12.1525 23.2513 12.543 22.8608L15.5026 19.9012V29.487C15.5026 30.0393 15.9503 30.487 16.5026 30.487C17.0549 30.487 17.5026 30.0393 17.5026 29.487V19.9012L20.4622 22.8608C20.8527 23.2513 21.4859 23.2513 21.8764 22.8608C22.2669 22.4702 22.2669 21.8371 21.8764 21.4465Z"
                                        fill="#353535"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1888_37896">
                                        <rect
                                            width="32"
                                            height="32"
                                            fill="white"
                                            transform="translate(0.5 0.152344)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg> */}
                                <i
                                    className="ph ph-cloud-arrow-up text-black"
                                    style={{ fontSize: '48px' }}
                                ></i>
                                <p>Click to Upload</p>
                                <ul style={{ fontSize: '0.75rem' }}>
                                    <li>Max dimensions: 2048 x 2048</li>
                                    <li>Supported file formats: JPG, PNG, WEBP</li>
                                    <li>Max file size: 20MB</li>
                                </ul>
                                {/* <span>or browse</span> */}
                            </div>
                        </label>
                        <input
                            id="fileInput"
                            type="file"
                            className="file-input"
                            onChange={handleFileInputChange}
                        />
                    </Form.Group>
                    <div className="uploadedFiles">
                        <p className="mt-3 fw-normal text-light mb-3">Previously uploaded</p>
                        <div className="uploadedFiles-contents">
                            {' '}
                            {/* selectedPhoto for when the user selects an image */}
                            {props.photo && (
                                <div
                                    className={`uploadedFiles-content  ${
                                        props.previewimage === recentImage ? 'selectedPhoto' : ''
                                    }`}
                                    onClick={() => handleUploadedImageClick(recentImage as string)}
                                >
                                    {renderPreview()}{' '}
                                    <p className="uploadedImage-name">{props.photo.name}</p>
                                </div>
                            )}
                            {productImages &&
                                productImages.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`uploadedFiles-content  ${
                                            props.previewimage === item.inputImage
                                                ? 'selectedPhoto'
                                                : ''
                                        }`}
                                    >
                                        <div
                                            className="uploadedImage"
                                            onClick={() => handleImageClick(item.inputImage)}
                                        >
                                            <Image key={item.id} src={item.inputImage} />
                                        </div>
                                        <p className="uploadedImage-name">{item.inputImage}</p>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {error && (
                        <div className="error-message">
                            <i className="ph ph-warning-circle sm"></i>
                            <p>{error}</p>
                        </div>
                    )}
                </Modal.Body>
                {/* replace the bth-disabled to btn-primary if any image has been selected */}
                <Modal.Footer>
                    <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        onClick={() => props.onHide()}
                        disabled={!props.previewimage}
                    >
                        Select
                    </Button>
                </Modal.Footer>
            </Modal>
        </form>
    );
};

export default PhotographyModal;
