import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Image, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap';

interface GeneratedAdProps {
    message: any;
    handleOpenInEditor: (templateId: any, sessionId: string) => void;
    index: number;
}

const GeneratedAd: React.FC<GeneratedAdProps> = ({ message, handleOpenInEditor, index }) => {
    const [showAdPreview, setShowAdPreview] = useState(false);
    const [loading, setLoading] = useState<boolean[]>([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
    useEffect(() => {
        // Initialize loading state for each image
        setLoading(new Array(message.preview_image.length).fill(true));
    }, [message.preview_image.length]);

    const handleImageLoad = (idx: number) => {
        setLoading((prev) => prev.map((load, i) => (i === idx ? false : load)));
    };

    const handleImageClick = (idx: number) => {
        setSelectedImageIndex(idx);
        handleOpenInEditor(message?.template[idx], message.session_id);
    };

    const openAdPreview = () => setShowAdPreview(true);
    const closeAdPreview = () => setShowAdPreview(false);
    function downloadImage(source: string) {
        const imageSrc = source;
        const link = document.createElement('a');
        link.href = imageSrc;
        link.download = 'pixii.png';
        link.click();
    }

    return (
        <motion.div
            key={index}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="d-flex generatedAds mb-3"
        >
            {/* GENERATED AD IMAGE */}
            <div
                className="generatedAd position-relative"
                style={message.preview_image.length > 1 ? { width: '90%' } : {}}
            >
                <div className="d-flex justify-content-center gap-3 align-items-center">
                    {message.preview_image.map((ad: any, idx: any) => (
                        <OverlayTrigger
                            key={idx}
                            placement="bottom"
                            delay={{ show: 150, hide: 400 }}
                            overlay={
                                <Tooltip>
                                    {message?.template.length === 3
                                        ? 'Select one to continue iterating'
                                        : 'Click to Open in Editor'}
                                </Tooltip>
                            }
                        >
                            <div>
                                {/* {loading[idx] ? (
                                    <div className="image-loader"></div>
                                ) : (
                                    <Image
                                        src={ad.url}
                                        loading="lazy"
                                        onClick={() =>
                                            handleOpenInEditor(
                                                message?.template[idx],
                                                message.session_id,
                                            )
                                        }
                                        onLoad={() => handleImageLoad(idx)}
                                        style={{ cursor: 'pointer' }}
                                        className="rounded"
                                    />
                                )} */}
                                <Image
                                    src={ad.url}
                                    loading="lazy"
                                    onClick={() => handleImageClick(idx)}
                                    onLoad={() => handleImageLoad(idx)}
                                    style={{
                                        cursor: 'pointer',
                                        border:
                                            selectedImageIndex === idx
                                                ? '3px solid #7e5b94'
                                                : 'none',
                                        borderRadius: '1px',
                                    }}
                                    className="rounded"
                                />
                                {message.variant_count && message.variant_count >= 1 ? (
                                    <div
                                        className="overlay d-flex justify-content-between align-items-start p-2"
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background:
                                                'linear-gradient(to bottom, rgba(0, 0, 0, 0.24), rgba(0,0,0,0.5))',
                                            fontSize: '1rem',
                                            color: '#ffffff',
                                        }}
                                        onClick={() =>
                                            handleOpenInEditor(
                                                message?.template[idx],
                                                message.session_id,
                                            )
                                        }
                                    >
                                        <span className="position-absolute top-0 end-0 p-2 fw-bold">
                                            +{message.variant_count}
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </OverlayTrigger>
                    ))}
                    {/* <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 150, hide: 400 }}
                        overlay={<Tooltip>Click to Open in Editor</Tooltip>}
                    >
                        <div>
                            <Image
                                src={message.value}
                                loading="lazy"
                                onClick={() => handleOpenInEditor(message)}
                                style={{ cursor: 'pointer' }}
                                className="rounded"
                            />
                            {message.variant_count && message.variant_count >= 1 ? (
                                <div
                                    className="overlay d-flex justify-content-between align-items-start p-2"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background:
                                            'linear-gradient(to bottom, rgba(0, 0, 0, 0.24), rgba(0,0,0,0.5))',
                                        fontSize: '1rem',
                                        color: '#ffffff',
                                    }}
                                    onClick={() => handleOpenInEditor(message)}
                                >
                                    <span className="position-absolute top-0 end-0 p-2 fw-bold">
                                        +{message.variant_count}
                                    </span>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </OverlayTrigger> */}
                    {/* <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 150, hide: 400 }}
                        overlay={<Tooltip>Click to Open in Editor</Tooltip>}
                    >
                        <div>
                            <Image
                                src={message.value}
                                loading="lazy"
                                onClick={() => handleOpenInEditor(message)}
                                style={{ cursor: 'pointer' }}
                                className="rounded"
                            />
                            {message.variant_count && message.variant_count >= 1 ? (
                                <div
                                    className="overlay d-flex justify-content-between align-items-start p-2"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background:
                                            'linear-gradient(to bottom, rgba(0, 0, 0, 0.24), rgba(0,0,0,0.5))',
                                        fontSize: '1rem',
                                        color: '#ffffff',
                                    }}
                                    onClick={() => handleOpenInEditor(message)}
                                >
                                    <span className="position-absolute top-0 end-0 p-2 fw-bold">
                                        +{message.variant_count}
                                    </span>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </OverlayTrigger> */}
                </div>
                <Modal show={showAdPreview} onHide={closeAdPreview} centered>
                    <Modal.Header closeButton className="border-none pb-0"></Modal.Header>
                    <Modal.Body>
                        <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            className="m-0 mb-3 d-flex align-items-center justify-content-center mx-auto"
                            style={{ width: '72%' }}
                        >
                            <Image
                                src={message.value}
                                fluid
                                className="border border-grey"
                                rounded
                            />
                        </motion.div>
                    </Modal.Body>
                    <Modal.Footer className="border-none justify-content-start p-0 m-0">
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Download</Tooltip>}
                        >
                            <Button
                                onClick={() => downloadImage(message.value)}
                                className="d-flex"
                                variant="white"
                            >
                                <i className="ph ph-download-simple lg"></i>
                            </Button>
                        </OverlayTrigger>
                    </Modal.Footer>
                </Modal>
            </div>
            {/* <div className="response_options">
                OPEN IN EDITOR BUTTON
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Open in Editor</Tooltip>}
                >
                    <Button
                        variant="white"
                        onClick={() => handleOpenInEditor(message)}
                        className="iconbtn"
                    >
                        <i className="ph ph-arrow-square-out lg" style={{ cursor: 'pointer' }}></i>
                    </Button>
                </OverlayTrigger>
            </div> */}
        </motion.div>
    );
};

export default GeneratedAd;
