import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const AUTH_SLICE_KEY = 'auth';

export interface AuthState {
    isAuthReady: boolean;
    token: string | null;
    refreshToken?: string | null;
    timeout: string | null;
}

const initialState: AuthState = {
    isAuthReady: false,
    token: null,
    refreshToken: null,
    timeout: null,
};

const authSlice = createSlice({
    name: AUTH_SLICE_KEY,
    initialState,
    reducers: {
        userLoggedIn(state, action: PayloadAction<AuthState>) {
            state.isAuthReady = action.payload.isAuthReady;
            state.token = action.payload.token;
            state.refreshToken = action.payload?.refreshToken;
            state.timeout = action.payload?.timeout;
        },
        userLoggedOut(state) {
            state.token = null;
            state.refreshToken = null;
            state.timeout = null;
            state.isAuthReady = false;
        },
    },
});

export const { userLoggedIn, userLoggedOut } = authSlice.actions;

export const authReducer = authSlice.reducer;
