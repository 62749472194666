import React, { ReactNode } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
interface ModalProps {
    children?: ReactNode;
    title?: string;
    show?: boolean; // Add the 'show' prop to the interface
    onHide?: () => void;
    isLoading?: boolean;
    buttonText?: string;
    buttonFunction?: () => void;
    secondButtonText?: string;
    secondButtonFunction?: () => void;
}
const CustomModal: React.FC<ModalProps> = ({
    children,
    title,
    show,
    isLoading,
    onHide,
    buttonText,
    buttonFunction,
    secondButtonText,
    secondButtonFunction,
    ...props
}) => {
    const handleHide = () => {
        // Conditionally execute onHide only if disableCloseButton is not true
        if (!isLoading) {
            onHide?.();
        }
    };
    return (
        <Modal
            {...props}
            show={show} // Pass the 'show' prop to the Modal
            onHide={handleHide} // Use the custom handleHide function
            // dialogClassName="modal-100w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            size="sm"
        >
            <Modal.Header closeButton={!isLoading} className="border-none pb-0">
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="text-dark mt-2 mb-2 fw-normal"
                >
                    {title}
                </Modal.Title>
            </Modal.Header>
            {children ? (
                <Modal.Body style={{ backgroundColor: '#fcf6ff' }}>{children}</Modal.Body>
            ) : null}
            <Modal.Footer style={{ border: 'none' }}>
                {/* Button for no */}
                {secondButtonText && secondButtonFunction && (
                    <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={secondButtonFunction}
                        disabled={isLoading}
                    >
                        {secondButtonText}
                    </Button>
                )}
                {buttonText && buttonFunction && (
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={buttonFunction}
                        disabled={isLoading}
                    >
                        {buttonText}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default CustomModal;
